(function () {
    let latestSat = window.getPreviousMonday(2);

    window.searchFieldSpecs = {
        'FiscalYear': {
            control: 'MultiSelect',
            title: 'Fiscal Year',
            id: 'fiscal_years',
            placeholder: 'Active Projects',
            urlKeys: ['reporter_intramural_url'],
            defaultValues: null,
            helpText: null,
            tooltipText: `<b>Fiscal Year (FY) Active Projects:</b> Unless specified, RePORTER searches for the most recent record of projects that have not yet reached their budget end date (in the case of extramural grants), contracts from the most recent fiscal year, or intramural projects from the most recent <a  href="reporter_intramural_url" target="_blank" class="no-break" rel="noopener">annual reports from the NIH intramural programs</a> (information on contracts and intramural projects is updated in RePORTER at the close of each fiscal year). This means that the results of "Active Projects" searches may include projects awarded in an earlier fiscal year that are still active (e.g., extramural awards in a no-cost extension period) or not yet updated by the end-of-year intramural project report. RePORTER contains records beginning with FY 1985.
            <p>
            <b>Prior Years:</b> Searches conducted on fiscal years prior to the current year will produce lists of only those awards funded in the chosen fiscal year.</p>`,
            buildHelpText: function () {
                let highestValue = window.getHighestValueInData(this.data);

                if (highestValue) {
                    return 'Current FY is ' + highestValue;
                }
            },
            change: function (selEle) {
                let currYear = this.$store.state.searchCriteria[this.id],
                    allYearObj = _.filter(currYear, yr => yr.value === 'all'),
                    isAllYearsSel = false;

                if (allYearObj.length > 0) {
                    isAllYearsSel = true;
                }

                if (isAllYearsSel) {
                    let otherSelected = selEle.querySelectorAll('li.selected:not([data-value="all"])');
                    for (let j = 0; j < otherSelected.length; j++) {
                        otherSelected[j].classList.remove('selected');
                        otherSelected[j].querySelector("button").click();
                    }
                    let options = selEle.querySelectorAll('li:not(.selected)');
                    for (let i = 0; i < options.length; ++i) {
                        options[i].querySelector("button").setAttribute("disabled", 'disabled');
                        options[i].classList.add('disabled');
                    }
                } else {
                    let options = selEle.querySelectorAll('li');
                    for (let i = 0; i < options.length; ++i) {
                        options[i].querySelector("button").removeAttribute("disabled");
                        options[i].classList.remove('disabled');
                    }
                }
            }
        },
        'AgencyIC': {
            control: 'MultiSelect',
            title: 'Agency/Institute/Center',
            id: 'agencies',
            ui: {
                dropdownWidth: 'wide'
            },
            defaultValues: null,
            helpText: null,
            tooltipText: `<b>Agency/Institute or Center (IC):</b> The agency or agency component responsible for administering the research grant or contract (Admin IC) or funding (Funding IC) the research. Admin is selected by default. Please note that funding data is only available for NIH, CDC, FDA, AHRQ, and ACF projects. Funding IC data is not available for NIH sub-projects. This may represent a federal department, agency, or sub-agency (institute or center).
            `,
            additionalControl: {
                controlType: 'checkbox',
                label: '',
                options: [
                    {
                        name: 'is_agency_admin',
                        id: 'is_agency_admin',
                        value: true,
                        label: 'Admin'
                    },
                    {
                        name: 'is_agency_funding',
                        id: 'is_agency_funding',
                        value: false,
                        label: 'Funding'
                    }
                ]
            }
        },
        'DepartmentTypes': {
            control: 'MultiSelect',
            title: 'Department Type',
            id: 'dept_types',
            defaultValues: null,
            helpText: null,
            urlKeys: ['report_department_list_url', 'reporter_grant_reassign_guidance_url', 'reporter_era_service_desk_url'],
            tooltipText: '<b>Department Type:</b> Standardized version of the academic department within an institution of higher education. To aid reporting on academic departments, NIH uses a <a href="https://report.nih.gov/report_department_list_url" class="no-break" rel="noopener">standardized list</a>, mapped from the department name provided on the application. Institutional signing officials may initiate a correction using the <a href="reporter_grant_reassign_guidance_url" target="_blank" class="no-break" rel="noopener">Grant Re-assign guidance</a> in the eRA Commons or contact the  <a target="_blank" href="reporter_era_service_desk_url" class="no-break" rel="noopener">eRA Service Desk</a>.'
        },
        'PI': {
            control: 'InputText',
            title: 'Principal Investigator (PI)',
            id: 'pi__field_text',
            fields: {
                'pi_names': {
                    controlType: 'string'
                },
                'pi_profile_ids': {
                    controlType: 'number'
                }
            },
            defaultValues: null,
            maxSelection: 1000,
            // helpText: 'PI Names or Profile IDs, semicolon separated' + nbsp + '(;)'
            helpText: 'PI Names or Profile IDs, semicolon ";" separated',
            tooltipText: `<b>Principal Investigator/Project Leader:</b> The individual designated by the grantee to direct the project supported by the grant. Searches for projects conducted by a particular individual can either be performed by entering their last name or first name or both. Recommended formats are <i>lastname, firstname</i> or <i>firstname lastname</i> or <i>lastname, firstname middlename/initial</i>.  <p>An implicit wild card search is performed on any name entered.</p>
            <p>A maximum of 1000 PIs separated by semicolon can be searched  at a time. The Project Search Results will show the contact PI at the top and all the other PIs in an alphabetical order below the contact PI.</p>`
        },
        'Organization': {
            control: 'MultiSelect',
            title: 'Organization',
            id: 'org_names_exact_match',
            idLookup: 'org_names',
            idFreeText: 'org_names',
            allowFreeText: true,
            dynamicLookup: true,
            defaultValues: null,
            helpText: 'Enter at least 3 characters to search',
            tooltipText: '<b>Organization:</b> Use to search for an institution that receives a grant, contract or cooperative agreement. <b>The LOOKUP feature will offer selections that meet the 3-character minimum user entry that occurs at the beginning of any word within the full organization name.</b>'
        },
        'ProjectNumber': {
            control: 'InputText',
            title: 'Project Number/Application ID',
            id: 'project_nums__field_text',
            // id: 'project_nums',
            fields: {
                'project_nums': {
                    controlType: 'string'
                },
                'appl_ids': {
                    controlType: 'number'
                }
            },
            // controlType: 'number',
            defaultValues: null,
            maxSelection: 1000,
            matchingStr: '[^a-zA-Z0-9;\\-\\s]',
            helpText: 'Format: 5R01CA012345-04/ 8515397, semicolon ";" separated',
            tooltipText: `<b>Project Number:</b> Commonly referred to as a grant number or contract number. For grants, this unique identification number is composed of the type code, activity code, Institute/Center code, serial number, support year, and/or suffix code. You may search for a full or partial project number or using the split project number fields.
                            <p>
                            <b>Application IDs:</b> A unique 7 or 8 digit number assigned to each project number.</p>
                            <p><b>Multiple entries:</b> 1000 Project Numbers / 1000 Application IDs maximum. Entries must be separated by a semi-colon or comma. Project number queries  greater than 100 entries must be in full 5R01CA012345-04 format and any use of implicit wildcard feature will be ignored`,
            alternetFields: ['appl_type_code', 'activity_code', 'ic_code', 'serial_num', 'support_year', 'suffix_code']
        },
        'City': {
            control: 'InputText',
            title: 'City',
            id: 'org_cities',
            controlType: 'string',
            defaultValues: null,
            maxSelection: 1000,
            helpText: null,
            tooltipText: '<b>City:</b> Geographical location (City) of awarded projects.'
        },
        'State': {
            control: 'MultiSelect',
            title: 'State',
            id: 'org_states',
            defaultValues: null,
            helpText: null,
            tooltipText: '<b>State:</b> Geographical location (State) of awarded projects.',

            dependentField: 'Country',
            // do NOT use arrow function here, or the value
            // of `this` will not be the component
            change: function () {
                let countryId = window.searchFieldSpecs.Country.id;

                if (this.$store.state.searchCriteria[this.id].length !== 0 && (!window.searchFieldCache[countryId] || (window.searchFieldCache[countryId] && window.searchFieldCache[countryId].length === 0))) {
                    let firstCountry = copyData(this.$store.state.lookup[countryId][0]);
                    // cache previous Country criteria so that it can be
                    // restored if the user clears all State selections
                    window.searchFieldCache[countryId] = copyData(this.$store.state.searchCriteria[countryId]);

                    this.$store.state.searchCriteria[countryId] = [firstCountry];
                } else if (this.$store.state.searchCriteria[this.id].length === 0) {
                    // restore Country selections if the user clears all State selections
                    if (window.searchFieldCache[countryId]) {
                        this.$store.state.searchCriteria[countryId] = window.searchFieldCache[countryId];
                        window.searchFieldCache[countryId] = null;
                    }
                }

                let conDistId = window.searchFieldSpecs.CongressionalDistrict.id;

                var stateObj,
                    districtObj,
                    tempDistrictList = [],
                    controlContainer = document.querySelector('#field-'+conDistId).closest('.search-field.multiselect');

                if (controlContainer.querySelector('.multiselect__dropdown').classList.contains('open')) {
                    document.querySelector('#field-'+conDistId).blur();
                }
                if (this.$store.state.searchCriteria[this.id].length > 0 && this.$store.state.searchCriteria[conDistId] && this.$store.state.searchCriteria[conDistId].length > 0) {
                    for (let i = 0; i < this.$store.state.searchCriteria[this.id].length; i++) {
                        stateObj = this.$store.state.searchCriteria[this.id][i];
                        for (let j = 0; j < this.$store.state.searchCriteria[conDistId].length; j++) {
                            districtObj = this.$store.state.searchCriteria[conDistId][j];
                            if (districtObj && districtObj.value.indexOf(stateObj.value) !== -1) {
                                tempDistrictList.push(districtObj);
                            }
                        }
                    }
                    //document.getElementById('field-' + conDistId).setAttribute('disabled', false);
                    this.$store.state.searchCriteria[conDistId] = tempDistrictList.length > 0 ? tempDistrictList : null;
                } else {
                    this.$store.state.searchCriteria[conDistId] = [];
                    //document.getElementById('field-' + conDistId).setAttribute('disabled', true);
                }
            }
        },
        'Country': {
            control: 'MultiSelect',
            title: 'Country',
            id: 'org_countries',
            defaultValues: null,
            helpText: null,
            tooltipText: '<b>Country:</b> Geographical location (Country) of awarded projects.',

            dependentField: 'State',

            // do NOT use arrow function here, or the value
            // of `this` will not be the component
            change: function () {
                let stateId = window.searchFieldSpecs.State.id,
                    cong_dists = window.searchFieldSpecs.CongressionalDistrict.id,
                    firstCountry = copyData(this.$store.state.lookup[this.id][0]);

                // check if selection is not just UNITED STATES
                // and remove State selections
                if (!(this.$store.state.searchCriteria[this.id].length === 1 && this.$store.state.searchCriteria[this.id][0] && this.$store.state.searchCriteria[this.id][0].value === firstCountry.value)) {
                    // cache previous State criteria so that it can be
                    // restored if the user resets Country selection
                    window.searchFieldCache[stateId] = copyData(this.$store.state.searchCriteria[stateId]);
                    window.searchFieldCache[cong_dists] = copyData(this.$store.state.searchCriteria[cong_dists]);

                    // clear State selections
                    this.$store.state.searchCriteria[stateId] = [];
                    this.$store.state.searchCriteria[cong_dists] = [];
                    if (this.$store.state.editSearchCriteria && this.$store.state.editSearchCriteria[stateId]) {
                        this.$store.state.editSearchCriteria[stateId] = [];
                    }
                    if (this.$store.state.editSearchCriteria && this.$store.state.editSearchCriteria[cong_dists]) {
                        this.$store.state.editSearchCriteria[cong_dists] = [];
                    }
                    window.searchFieldCache[this.id] = null;
                } else {
                    // restored States if the user sets Country back to UNITED STATES
                    if (window.searchFieldCache[stateId]) {
                        this.$store.state.searchCriteria[stateId] = window.searchFieldCache[stateId];
                    }
                    if (window.searchFieldCache[cong_dists]) {
                        this.$store.state.searchCriteria[cong_dists] = window.searchFieldCache[cong_dists];
                    }
                }
            }
        },
        'CongressionalDistrict': {
            control: 'MultiSelect',
            title: 'Congressional District',
            id: 'cong_dists',
            defaultValues: null,
            helpText: 'Please select a state first',
            tooltipText: '<b>Congressional District:</b> A territorial division of a state from which a member of the United States House of Representatives is elected. The congressional district to which each grant is assigned is based on the business address of the grantee organization or contractor, not the actual performance site of the research being performed. The search selector is periodically updated to reflect the current listings of congressional districts. Information on historical awards is not updated to reflect changing district information.',
            allowFreeText: false,
            dynamicLookup: true,
            dependentField: 'State',
        },
        'appl_type_code': {
            control: 'InputText',
            title: 'Application Type Code',
            titleHidden: true,
            id: 'appl_type_code',
            controlType: 'number',
            defaultValues: null,
            maxLength: 1,
            helpText: '1',
            matchingStr: '\\D',
            alternetFields: ['project_nums__field_text'],
            groupFields: ['activity_code', 'ic_code', 'serial_num', 'support_year', 'suffix_code']
        },
        'activity_code': {
            control: 'InputText',
            title: 'Activity Code',
            titleHidden: true,
            id: 'activity_code',
            controlType: 'string',
            defaultValues: null,
            maxLength: 3,
            helpText: 'R01',
            matchingStr: '[^a-z0-9\s]',
            alternetFields: ['project_nums__field_text'],
            groupFields: ['appl_type_code', 'ic_code', 'serial_num', 'support_year', 'suffix_code']
        },
        'ic_code': {
            control: 'InputText',
            title: 'IC Code',
            titleHidden: true,
            id: 'ic_code',
            controlType: 'string',
            defaultValues: null,
            maxLength: 2,
            helpText: 'CA',
            matchingStr: '[^a-z]',
            alternetFields: ['project_nums__field_text'],
            groupFields: ['appl_type_code', 'activity_code', 'serial_num', 'support_year', 'suffix_code']
        },
        'serial_num': {
            control: 'InputText',
            title: 'Serial Number',
            titleHidden: true,
            id: 'serial_num',
            controlType: 'number',
            defaultValues: null,
            maxLength: 6,
            helpText: '811099',
            matchingStr: '\\D',
            alternetFields: ['project_nums__field_text'],
            groupFields: ['appl_type_code', 'activity_code', 'ic_code', 'support_year', 'suffix_code']
        },
        'support_year': {
            control: 'InputText',
            title: 'Support Year',
            titleHidden: true,
            id: 'support_year',
            controlType: 'number',
            defaultValues: null,
            maxLength: 2,
            helpText: '01',
            matchingStr: '\\D',
            alternetFields: ['project_nums__field_text'],
            groupFields: ['appl_type_code', 'activity_code', 'ic_code', 'serial_num', 'suffix_code']
        },
        'suffix_code': {
            control: 'InputText',
            title: 'Suffix Code',
            titleHidden: true,
            id: 'suffix_code',
            controlType: 'number',
            defaultValues: null,
            maxLength: 4,
            helpText: 'A1S1',
            matchingStr: '[^a-z0-9\s]',
            alternetFields: ['project_nums__field_text'],
            groupFields: ['appl_type_code', 'activity_code', 'ic_code', 'serial_num', 'support_year']
        },
        'PO': {
            control: 'InputText',
            title: 'Program Officer (PO)',
            id: 'po_names',
            controlType: 'string',
            defaultValues: null,
            maxSelection: 1000,
            // helpText: 'PI Names or Profile IDs, semicolon separated' + nbsp + '(;)'
            helpText: 'PO Names, semicolon ";" separated',
            tooltipText: `<b>Program Officer:</b> The NIH official responsible for the programmatic, scientific, and/or technical aspects of a grant. Searches for projects managed by a particular individual can either be performed by entering their last name or first name or both. Recommended formats are <i>lastname, firstname</i> or <i>firstname lastname</i> or <i>lastname, firstname middlename/initial</i>.  <p>An implicit wild card search is performed on any name entered.</p>
            <p>A maximum of 1000 POs separated by semicolon can be searched  at a time.</p>`
        },
        'SpendingCategories': {
            control: 'MultiSelect',
            title: 'NIH Spending Category',
            id: 'spending_categories',
            ui: {
                dropdownWidth: 'wide'
            },
            defaultValues: null,
            // helpText: 'Available for FY from 2008 to 2018',
            urlKeys: ['report_nih_categorical_spending_url'],
            tooltipText: `<b>NIH Spending Category:</b> One of several historically-reported categories of diseases, conditions, or research areas. Available for fiscal years 2008 and onward, based on a computerized process the NIH uses at the end of each fiscal year to categorize research projects. Projects by Spending Category for each fiscal year are made available the following fiscal year as part of the next President’s Budget request. Only projects administered by NIH are organized into spending categories. Projects of other agencies are not included. RePORTER provides up-to-date award information, including modifications made after the close of each fiscal year. As a result, project listings and award amounts may not match exactly the official year-end NIH Categorical Spending reports at
            <a href="https://report.nih.gov/report_nih_categorical_spending_url" target="_blanl" rel="noopener">https://report.nih.gov/report_nih_categorical_spending_url</a>`,
            // matchingStr: '^(200[8-9]|201[0-8])$',
            // dependentField: 'FiscalYear',
            additionalControl: {
                controlType: 'radio',
                label: 'Combine multiple categories with',
                defaultValues: 'false',
                options: [
                    {
                        name: 'match_all_spending_categories',
                        id: 'match_all_spending_categories',
                        value: 'true',
                        label: 'AND'
                    },
                    {
                        name: 'match_all_spending_categories',
                        id: 'match_all_spending_categories',
                        value: 'false',
                        label: 'OR'
                    }
                ]
            }
        },
        'AwardTypes': {
            control: 'MultiSelect',
            title: 'Award Type',
            id: 'award_types',
            defaultValues: null,
            helpText: null,
            tooltipText: `<b>Award Type:</b> From the standard award types, RePORTER has created four groupings: New (type 1s), Competing Renewal (types 2, 4, and 9), Noncompeting (types 4, 5, 6, 7 and 8), and Revision or Supplement (type 3).
`
        },
        'ProjectStartDate': {
            control: 'InputDate',
            title: 'Project Start Date',
            id: 'project_start_date',
            defaultValues: null,
            helpText: 'Format: MM/DD/YYYY',
            tooltipText: `<b>Project Start Date:</b> Date the project began. You can manually enter the date or click the calendar icon to bring up the calendar date selector.
            <p>
            By default, the calendar tool has the  "Before" option selected, which allows projects with start date before the entered date to be searched. You may also chose "Range" or "After" options.</p>`,
            dependentField: 'ProjectEndDate'
        },
        'ProjectEndDate': {
            control: 'InputDate',
            title: 'Project End Date',
            id: 'project_end_date',
            defaultValues: null,
            helpText: 'Format: MM/DD/YYYY',
            tooltipText: `<b>Project End Date:</b> Date the project ended. You can manually enter the date or click the calendar icon to bring up the calendar date selector.
            <p>By default, the calendar tool has the  "Before" option selected, which allows projects with end date before the entered date to be searched. You may also chose "Range" or  "After" options.</p>`,
            dependentField: 'ProjectStartDate'
        },
        'AwardNoticeDate': {
            control: 'InputDate',
            title: 'Award Notice Date',
            id: 'award_notice_date',
            defaultValues: null,
            helpText: 'Format: MM/DD/YYYY',
            tooltipText: `<b>Award Notice Date:</b> Date on which the award notice was issued. The award notice is the legally binding document that notifies the grantee that an award has been made, contains all terms and conditions of the award, and documents the obligation of Federal funds.
            <p>By default, the calendar tool has the  "Before" option selected, which allows projects with award notice date before the entered date to be searched. You may also chose "Range" or  "After" options.</p>`
        },
        'ActivityCodes': {
            control: 'MultiSelect',
            title: 'Activity Code',
            id: 'activity_codes',
            defaultValues: null,
            ui: {
                collapseAll: true
            },
            helpText: null,
            urlKeys: ['reporter_grant_glosarry_url'],
            tooltipText: `<p><b>Activity Code:</b> A 3-character code, applied to various funding mechanisms to identify a specific category of extramural research activity. A comprehensive list of activity codes currently in use may be found on the Types of Grant Programs Web page.
            </p>
            Several major groupings of activities and some commonly-used activity codes may be selected in RePORTER. The major groupings are:
            <ul>
                <li><a target="_blank" class="no-break" href="reporter_grant_glosarry_url#R" rel="noopener">Research Grants</a></li>
                <li><a target="_blank" class="no-break" href="reporter_grant_glosarry_url#R" rel="noopener">Research Project Grants</a>
                    <ul>
                        <li>R01 Equivalents: R01, R23, R29, R37, DP2, and RF1 activity codes. (The R23 and R29 are no longer used by NIH and apply to historical searches only).</li>
                        <li><a target="_blank" class="no-break" href="reporter_grant_glosarry_url#R" rel="noopener">Research Career Awards</a></li>
                        <li><a target="_blank" class="no-break" href="reporter_grant_glosarry_url#S" rel="noopener">SBIR/STTR</a></li>
                    </ul>
                </li>
                <li><a target="_blank" class="no-break" href="reporter_grant_glosarry_url#S" rel="noopener">Research Center Grants</a>
                    <ul>
                        <li>Construction Grants</li>
                    </ul>
                </li>
                <li><a target="_blank" class="no-break" href="reporter_grant_glosarry_url#C" rel="noopener">Cooperative Agreements</a></li>
                <li><a target="_blank" class="no-break" href="reporter_grant_glosarry_url#F" rel="noopener">Fellowships</a></li>
                <li><a target="_blank" class="no-break" href="reporter_grant_glosarry_url#T" rel="noopener">Training Grants</a></li>
                <li><a target="_blank" class="no-break" href="reporter_grant_glosarry_url#C" rel="noopener">Contracts (R&D)</a></li>
                <li><a target="_blank" class="no-break" href="reporter_grant_glosarry_url#I" rel="noopener">Intramural</a></li>
                <li>Resources: Grants for institutional resources such as modernization of existing research facilities.
                    <ul>
                        <li>Other Research</li>
                        <li>All Other</li>
                    </ul>
                </li>
            </ul>
            </p>`,
        },
        'CooperativeAgreement': {
            control: 'MultiSelect',
            title: 'Cooperative Agreement',
            id: 'cooperative_agreement_codes',
            defaultValues: null,
            ui: {
                collapseAll: true
            },
            helpText: null,
            urlKeys: ['reporter_grant_glosarry_url'],
            tooltipText: `<p><b>Cooperative Agreement:</b> A legal instrument of financial assistance between a Federal awarding agency or pass-through entity and a non-Federal entity. Cooperative agreements are indicated by a “U” in the activity code or mechanism (e.g. U01, U54).
            </p><p>See also definitions for <a target="_blank" class="no-break" href="reporter_grant_glosarry_url#CooperativeAgreement" rel="noopener">Cooperative agreements</a>
            </p>`,
        },
        'OrganizationTypes': {
            control: 'MultiSelect',
            title: 'Organization Type',
            id: 'organization_types',
            defaultValues: null,
            helpText: null,
            urlKeys: ['report_organization_type_list_url', 'reporter_grant_reassign_guidance_url', 'reporter_era_service_desk_url'],
            tooltipText: '<b>Organization Type:</b> Standardized version of the type of organization, classified according to the function, mission, or service of the organization receiving a grant, contract, or cooperative agreement. To aid reporting on organizations, NIH uses a <a href="https://report.nih.gov/report_organization_type_list_url" class="no-break" rel="noopener">standardized list</a>, mapped from information provided on the application. Institutional signing officials may initiate a correction using the <a href="reporter_grant_reassign_guidance_url" target="_blank" class="no-break" rel="noopener">Grant Re-assign guidance</a> in the eRA Commons or contact the <a target="_blank" href="reporter_era_service_desk_url" class="no-break" rel="noopener">eRA Service Desk</a>.'
        },
        'FOA': {
            control: 'InputText',
            title: 'Opportunity Number',
            id: 'foa',
            type: 'string',
            defaultValues: null,
            maxSelection: 1000,
            urlKeys: ['reporter_nih_guide_for_grants_contracts_url'],
            helpText: 'Format: RFA-IC-09-003 or PA-09-003, semicolon ";" separated<br><a href="reporter_nih_guide_for_grants_contracts_url" target="_blank" rel="noopener">Funding Opportunities and Notices</a>',
            tooltipText: `<b>Opportunity Number (Funding Opportunity Announcement):</b> RePORTER provides the ability to search for projects originally solicited under a request for applications (RFA) or program announcement (PA) issued in the <a href="reporter_nih_guide_for_grants_contracts_url" target="_blank" class="no-break" rel="noopener">NIH Guide for Grants and Contracts</a>.  RFA numbers take the format RFA-IC-fy-nnn, where IC is the Agency/Institute/Center issuing the RFA, fy is the fiscal year of the solicitation, and nnn is a serial number. PA numbers are formatted as PA-fy-nnn.
            <p>
            You may search for a full or partial Opportunity Number. </p>
            <p>
            When searching for multiple Opportunity Number numbers at a single time, recommended limit is 20 numbers, separated by semi-colon or comma.</p>`,
        },
        'StudySections': {
            control: 'MultiSelect',
            title: 'Study Section',
            id: 'study_sections',
            defaultValues: null,
            ui: {
                collapseAll: true
            },
            helpText: 'Standing CSR study sections only',
            urlKeys: ['reporter_study_section_code_url'],
            tooltipText: `<b>Study Section:</b> A group responsible for the review of grant applications in an area of science. Study sections are grouped into larger Integrated Review Groups (IRGs),  and into Fellowship and SBIR/STTR Study Sections. Study section codes and rosters of current study sections can be found at <a href="reporter_study_section_code_url" target="_blank" rel="noopener">reporter_study_section_code_url</a>.`,
        },
        'covid19': {
            control: 'MultiSelect',
            title: 'NIH COVID-19 Response',
            id: 'covid19',
            defaultValues: null,
            urlKeys: ['reporter_public_info_corona_url', 'reporter_nih_research_corona_url', 'reporter_hss_grant_funding_corona_url'],
            tooltipText: `<b>NIH COVID-19 Response:</b> These special selectors will return projects awarded to study COVID-19 and related topics, as funded under:
                <ul><li>RegCV - NIH regular appropriations funding</li>
                <li>CV - Coronavirus Preparedness and Response Supplemental Appropriations Act, 2020</li>
                <li>C3 - CARES Act (Coronavirus Aid, Relief, and Economic Security Act)</li>
                <li>C4 - Paycheck Protection Program and Health Care Enhancement Act</li>
                <li>C5 - Coronavirus Response and Relief Supplemental Appropriations Act, 2021</li>
                <li>C6 - American Rescue Plan Act of 2021</li></ul>
                <p>These special selectors can be used in any combination and also can be combined with other search criteria on this page.</p>
                <p>Currently, this filtered search item applies only to parent grants, grant revisions, and grant supplements. As of Spring 2021, aligned with release of the President’s budget for FY2022, these grant records and the records for COVID-19 contracts, intramurals, and subprojects all will be reported through the Coronaviruses NIH spending category.</p>
                <p><b>Administrative Supplement:</b> A request for (or the award of) additional funds during a current project period to provide for an increase in costs due to unforeseen circumstances. All additional costs <b><u><i>must be within the scope of the peer reviewed and approved project</i></u></b>.
                </p>
                <p><b>Competitive Revision:</b> A request for (or the award of) additional funds during a current project period to support new or additional activities, which are not identified in the current award and <b><u><i>reflect an expansion of the scope of the grant-approved activities</i></u></b>.
                </p>
                <p>Get the latest public health information from CDC: <a href="reporter_public_info_corona_url" target="_blank" rel="noopener">reporter_public_info_corona_url</a></p>
                <p>Get the latest NIH research information: <a href="reporter_nih_research_corona_url" target="_blank" rel="noopener">reporter_nih_research_corona_url</a></p>
                <p>Get HHS grant funding under these special appropriations: <a href="reporter_hss_grant_funding_corona_url" target="_blank" rel="noopener">reporter_hss_grant_funding_corona_url</a></p>`
        },
        'ARRASearch': {
            control: 'MultiSelect',
            title: 'NIH ARRA Selection',
            id: 'arra_type',
            defaultValues: null,
            tooltipText: `<b>NIH ARRA Selection:</b> NIH ARRA Selection: Research projects funded, entirely or in part, with funds appropriated under the American Recovery and Reinvestment Act of 2009.`
        },
        'AwardAmountRange': {
            control: 'InputRange',
            title: 'Award Size',
            id: 'award_amount_range',
            defaultValues: null,
            helpText: 'Only for NIH, CDC, FDA, AHRQ, and ACF',
            tooltipText: `<b>Award Size:</b> Only awards greater or less than the amount entered will be shown on the hit list. Awarded amounts are total costs (direct + indirect costs) awarded in the fiscal year indicated on the project record.
            <p>You may manually enter the award range or use the slider to pick the minimum or maximum awards.</p>`,
            matchingStr: '\\D',
        },
        'ExcludeSubprojects': {
            control: 'ControlList',
            title: 'Exclude Subprojects',
            controlType: 'checkbox',
            id: 'exclude_subprojects',
            defaultValues: false,
            helpText: null,
            tooltipText: `<b>Exclude Subprojects:</b> On selection, Subprojects will not be shown on hit list.`
        },
        'multiplePIOnly': {
            control: 'ControlList',
            title: 'Multi-PI Only',
            controlType: 'checkbox',
            id: 'multi_pi_only',
            defaultValues: false,
            helpText: null,
            tooltipText: `<b>Multi-PI Only:</b> Along with any other filter criteria entered, only projects with more than one PI assigned to that project will be selected. The Project Search Results will show the contact PI at the top and all the other PIs in an alphabetical order below the contact PI.`
        },
        'newlyAddedProjectsOnly': {
            control: 'ControlList',
            title: 'Newly Added Projects Only',
            controlType: 'checkbox',
            id: 'newly_added_projects_only',
            defaultValues: false,
            helpText: `Project added since ` + latestSat,
            tooltipText: ``
        },
        'fundingMechanisms': {
            control: 'MultiSelect',
            title: 'Funding Mechanism',
            id: 'funding_mechanism',
            // placeholder: 'All Years',
            defaultValues: null,
            helpText: null,
            tooltipText: `<b>Funding Mechanism:</b> The options in RePORTER include the major mechanism categories used in NIH budget mechanism tables for the President’s budget.`
        },
        'outcomesOnly': {
            control: 'ControlList',
            title: 'Projects with Outcomes Only',
            controlType: 'checkbox',
            id: 'outcomes_only',
            defaultValues: false,
            tooltipText: `<b>Projects with Outcomes Only:</b> Along with any other filter criteria entered, only projects with Outcomes will be selected. These projects will either have a Final or Interim Research Performance Progress Report (RPPR) submitted after October 1, 2017.`
        },
        'SearchText': {
            control: 'InputText',
            title: '',
            id: 'search_text',
            ui: {
                rows: '3',
                showCount: true,
                checkXSS: true
            },
            defaultValues: null,
            dependentField: 'search_field',
            urlKeys: ['report_rcdc_thesaurus_url'],
            tooltipText: `<b>Text Search:</b> Text search of project titles, abstracts, and scientific terms. If the "And" logic is selected (the default), RePORTER finds projects in which all of the search terms are found within the title, abstract, or scientific terms. If the "Or" logic is used, RePORTER finds projects that contain at least one of the terms entered. For example, using the default "And" search, a search on brain disorder will retrieve projects with both "brain" and "disorder" in their title, abstract, or scientific terms. Changing the logic to "Or" will retrieve all projects with either "brain" or "disorder" in these fields. Use quotes around the entered text to search for exact phrases. Please reference <a class="no-break" href="https://report.nih.gov/report_rcdc_thesaurus_url" rel="noopener">RCDC Thesaurus</a> for scientific terms.
            <p>The "Advanced" logic option provides additional capability to narrow selection criteria more precisely and evaluate complex entries such as chemical references.</p>
            <p>Text search automatically disregards certains common stopwords like a, an, and, are, as, at, be, of, on, or, into, is, it, etc. Wildcard is not supported in current text search.</p>`,
            maxLength: 2500
        },
        'Operator': {
            control: 'ControlList',
            title: 'Text Search (Logic)',
            id: 'operator',
            defaultValues: 'and',
            controlType: 'radio',
            tooltipText: '',
            maxLength: null,
            options: [
                {
                    name: 'text_search_operator',
                    id: 'operator',
                    value: 'and',
                    label: 'AND',
                    tooltipText: '<b>And:</b> Find projects in which all of the search terms are found. For example, a search on brain disorder will retrieve projects containing both "brain" and "disorder". Maximum of 2500 characters including spaces are allowed at a time.'
                },
                {
                    name: 'text_search_operator',
                    id: 'operator',
                    value: 'or',
                    label: 'OR',
                    tooltipText: '<b>Or:</b> Find projects that contain at least one of the search terms entered. For example, a search on brain disorder will retrieve all projects containing either "brain" or "disorder". Maximum of 2500 characters including spaces are allowed at a time.'
                },
                {
                    name: 'text_search_operator',
                    id: 'operator',
                    value: 'advanced',
                    label: 'Advanced',
                    tooltipText: `<b>Advanced:</b> Boolean search with following operands:
                    <p></p><p>
                    <p><b>and</b> - to search for all terms, ex: breast and cancer</p>
                    <p><b>or</b> – to search for any term, ex: cancer or tumor</p>
                    <p><b>not</b> – to exclude a term from search, ex: cancer not tumor</p>
                    <p><b>double-quoted (" ")</b> – to search for exact phrase, ex: "lung cancer"</p>
                    <p><b>parenthesis</b> - to group the search terms, ex: (breast and cancer) or tumor</p>
                    <p>You can mix and match multiple operands, for ex:(breast and cancer or "lung cancer") not tumor</p>`
                }
            ]
        },
        'TextSearchField': {
            control: 'ControlList',
            title: 'Limit Project search to',
            id: 'search_field',
            controlType: 'checkbox',
            tooltipText: '',
            maxLength: null,
            defaultValues: ["projecttitle", "terms", "abstracttext"],
            dbValues: ["all"],
            dependentField: 'search_text',
            options: [
                {
                    name: 'search_field_title',
                    id: 'search_field',
                    value: 'projecttitle',
                    label: 'Project Title',
                    tooltipText: ''
                },
                {
                    name: 'search_field_terms',
                    id: 'search_field',
                    value: 'terms',
                    label: 'Project Terms',
                    tooltipText: ''
                },
                {
                    name: 'search_field_abstract',
                    id: 'search_field',
                    value: 'abstracttext',
                    label: 'Project Abstracts',
                    tooltipText: ''
                }
            ],
            tooltipText: `Text can be searched from up to three sources: Project Title, Project Terms, and Project Abstract. Selecting these sources individually or in combination includes them in the search. Selecting none of the sources has the same effect as selecting all of them.`
        },
        'MatchmakerText': {
            control: 'InputText',
            title: 'Enter abstracts or other scientific text to find potential Program Officials, ICs, and review panels for your research.',
            id: 'matchmaker_text',
            ui: {
                rows: '3',
                showCount: true,
                hideFieldCount: true,
                checkXSS: true
            },
            defaultValues: null,
            tooltipText: `<b>Matchmaker:</b> Enter abstracts or other scientific text and Matchmaker will return lists of similar projects from RePORTER or program officials associated with those projects. These matches are based on the terms and concepts used in the submitted text. Up to 15,000 characters are permitted. Matchmaker summarizes the projects by the program official, institute or center, review panel, and activity code.
                <p>Terms will be weighted by the frequency of appearance in the text submitted. The process is automated and confidential. The Matchmaker system does not track and store submitted text.</p>`,
            maxLength: 15000
        },
        'MatchmakerField': {
            control: 'ControlList',
            title: '',
            id: 'matchmaker_field',
            defaultValues: 'project',
            controlType: 'radio',
            tooltipText: '',
            maxLength: null,
            options: [
                {
                    name: 'matchmaker_field',
                    id: 'matchmaker_field',
                    value: 'project',
                    label: 'Similar Projects',
                    tooltipText: ''
                },
                {
                    name: 'matchmaker_field',
                    id: 'matchmaker_field',
                    value: 'po',
                    label: 'Similar Program Officials',
                    tooltipText: ''
                }
            ]
        },
        'PublicationsText': {
            control: 'InputText',
            title: 'Enter PubMed IDs (PMID) or PubMed Central IDs (PMC ID) to find publications associated with extramural or intramural funded projects.',
            id: 'publications_text',
            ui: {
                rows: '3',
                showCount: true,
                hideFieldCount: false,
                checkXSS: true
            },
            defaultValues: null,
            maxSelection: 1000,
            maxLength: 15000
        },
        'PublicationsField': {
            control: 'ControlList',
            title: '',
            id: 'publications_field',
            defaultValues: 'pm_id',
            controlType: 'radio',
            tooltipText: '',
            maxLength: null,
            options: [
                {
                    name: 'publications_field',
                    id: 'publications_field',
                    value: 'pm_id',
                    label: 'PMID',
                    tooltipText: ''
                },
                {
                    name: 'publications_field',
                    id: 'publications_field',
                    value: 'pmc_id',
                    label: 'PMC ID',
                    tooltipText: ''
                }
            ]
        },
    };
})();
