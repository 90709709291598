<template>
    <div :class="loading ? 'loading' : ''">
        <div class="search-form">
            <div class="row">
                <div class="col-md-9 col-sm-12">
                    <field name="MatchmakerText" ref="matchmakerText"/>
                </div>
                <div class="col-md-3 col-sm-12">
                    <field name="MatchmakerField"/>
                </div>
                <div
                    class="col-md-4 search-buttons offset-md-8"
                >
                    <button
                        type="button"
                        class="btn btn-lg btn-secondary btn-clear"
                        @click.stop="reset()"
                        v-if="!refpage"
                    >
                        <!-- @keydown.stop.prevent="reset()" -->
                        Reset
                    </button>
                    <button
                        type="button"
                        class="btn btn-lg btn-secondary btn-clear"
                        @click.stop="cancel()"
                        v-if="refpage"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        class="btn btn-lg btn-primary btn-search"
                        @click.stop="search()"
                        :disabled="loading"
                    >
                        Search
                        <div class="loader" v-if="loading"></div>
                    </button>
                    <field-error
                        v-if="serviceError"
                        :message="serviceErrorMessage"
                        :errorCode="serviceError"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Field from '@/components/searchFields/Field.vue';
import FieldError from '@/components/searchFields/FieldError.vue';
import globalMixins from '@/globalMixins';
import { HttpStatusCode } from 'axios';

export default {
    name: 'Matchmaker',
    mixins: [globalMixins],
    components: {
        Field,
        FieldError
    },
    props: {
        refpage: {
            required: false
        }
    },
    data: function () {
        return {
            loading: false,
            serviceErrorMessage: null,
            serviceErrorMessageText: 'No concepts found.',
            serviceErrorMessageDetails: 'Please try again using text containing scientific concepts from the NIH RCDC Thesaurus.',
            serviceError: null
        };
    },
    computed: {
        ...mapGetters([
            'searchCriteria',
            'searchFieldErrors',
            'matchmakerFacetCriteria',
            'externalURLs'
        ])
    },
    watch: {
        'searchCriteria.matchmaker_field': function (val, oldVal) {
            this.serviceError = null;
        }
    },
    mounted () {

    },
    methods: {
        ...mapActions([
            'setMatchmakerResults',
            'setMatchmakerFacetCriteria',
            'setSearchResults',
            'setSearchMode',
       //     'setSearchType',
            'setMatchmakerPOResults'
        ]),
        formHasErrors () {
            for (let field in this.searchFieldErrors) {
                if (this.searchFieldErrors[field].preventSearch) {
                    return true;
                }
            }

            return false;
        },
        handleSearchResults (data) {
            this.loading = false;

          if (!data || (data && data.status && data.status !== HttpStatusCode.Ok)) {
                if (data && data.status === HttpStatusCode.NoContent) { 
                    this.serviceErrorMessage = this.serviceErrorMessageText;
                    this.serviceError = this.serviceErrorMessageDetails;
                }
                else {
                    this.serviceErrorMessage = "";
                    this.serviceError = this.externalURLs.reporter_project_search_error_message;
                }

                return false;
            } else {
                if (data && data.meta && data.results) {
                    var url = '/matchmaker/';
                    this.setSearchMeta(data.meta);
                    this.setMatchmakerResults(data);

                    // default to Project results
                    url += data.meta.search_id + '/projects';

                    localStorage.setItem('matchmakerSearchText' + data.meta.search_id, this.searchCriteria.matchmaker_text);

                    // console.log(this.searchCriteria.matchmaker_text);
                    // console.log(localStorage.getItem('matchmakerSearchText' + data.meta.search_id));

                    if (this.$parent.sortField) {
                        url += '&sort_field=' + this.$parent.sortField + '&sort_order=' + this.$parent.sortOrder;
                    }
                    this.$router.push(url);
                }
                // var url = '/matchmaker/results';

                // this.setMatchmakerResults(data.results);

                // if (this.$parent.sortField) {
                //     url += '&sort_field=' + this.$parent.sortField + '&sort_order=' + this.$parent.sortOrder;
                // }
                // this.$router.push(url);
            }
        },
        handlePOResults (data) {
            this.loading = false;

            if (data && data.status && data.status !== HttpStatusCode.Ok) {
                if (data && data.status === HttpStatusCode.NoContent) { 
                    this.serviceErrorMessage = this.serviceErrorMessageText;
                    this.serviceError = this.serviceErrorMessageDetails;
                }
                else {
                    this.serviceErrorMessage = "";
                    this.serviceError = this.externalURLs.reporter_project_search_error_message;
                }
                return false;
            } else {
                if (data && data.meta && data.results) {
                    var url = '/matchmaker/';
                    this.setSearchMeta(data.meta);
                    this.setMatchmakerPOResults(data);

                    // default to Project results
                    url += data.meta.search_id + '/POs';

                    localStorage.setItem('matchmakerSearchText' + data.meta.search_id, this.searchCriteria.matchmaker_text);

                    // console.log(this.searchCriteria.matchmaker_text);
                    // console.log(localStorage.getItem('matchmakerSearchText' + data.meta.search_id));

                    if (this.$parent.sortField) {
                        url += '&sort_field=' + this.$parent.sortField + '&sort_order=' + this.$parent.sortOrder;
                    }
                    this.$router.push(url);
                }
                // var url = '/matchmaker/results';

                // this.setMatchmakerResults(data.results);

                // if (this.$parent.sortField) {
                //     url += '&sort_field=' + this.$parent.sortField + '&sort_order=' + this.$parent.sortOrder;
                // }
                // this.$router.push(url);
            }
        },
        search () {
            if (this.searchCriteria['smart_search_text']) {
                this.searchCriteria['smart_search_text'] = '';
            }
            if (this.searchCriteria['publications_text']) {
                this.searchCriteria['publications_text'] = '';
            }
            // this.matchmakerFacetCriteria['is_active_projects'] = true;
            // this.setMatchmakerFacetCriteria();

            // console.log(this.matchmakerFacetCriteria['is_active_projects']);

            // window.firstLoadFacets = {
            //     is_active_projects: true,
            //     fiscal_years: ['ap']
            // };

            var parsedCriteria = window.parseCriteria(this.searchCriteria, {isEditCriteria: false, isSubmitForm: true}),
                criteria = window.stringifySearchCriteriaFields(parsedCriteria, {});

            if (!this.formHasErrors()) {
				gtag('event', 'Click', {
					'event_category': this.refpage ? 'Search Results' : 'Home',
					'event_label': 'Matchmaker Search - Submit',
					'value': 'Matchmaker Search - Submit'
				});	

                this.loading = true;

                this.setEditSearchResultsFlag(false);
           //     this.setSearchType('Advanced');

                // this.setSearchType('Matchmaker');
                this.clearSearchResults({resetFacets: true});
                if (this.searchCriteria.matchmaker_field === 'po') {
                    this.getMatchmakerPOResults(false, true, this.handlePOResults);
                } else {
                    this.getMatchmakerSearchResults(false, true, this.handleSearchResults);
                }
                // this.getProjectSearchResults(false, this.searchResults);
            } else {
                console.error('form validation error');
            }
        },
        reset () {
            this.serviceError = null;
            this.$refs.matchmakerText.$refs.field.reset();
            this.$refs.matchmakerText.$refs.field.$refs.input.focus();
			gtag('event', 'Click', {
				'event_category': 'Matchmaker Home',
				'event_label': 'Matchmaker search - Reset',
				'value': 'Matchmaker search - Reset'
			});	
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import '@/scss/variables.scss';
@import 'node_modules/bootstrap/scss/_mixins';

.loader {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 4em;
    height: 4em;
    top: -2px;
    left: 0;
    margin-left: 0.5em;
    border-width: 2px;
    border-top-color: rgba(255, 255, 255, 0.25);
    border-right-color: rgba(255, 255, 255, 0.25);
    border-bottom-color: rgba(255, 255, 255, 0.25);
    border-left-color: rgba(255, 255, 255, 1);
}

.btn-primary {
    // background-color: $green-dark;

    @include button-variant($orange-dark, $orange-dark);
}
</style>