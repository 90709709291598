<template>
    <div class="feature-area-wrapper">
        <div class="feature-area">
            <div class="col-10 offset-1">
                <div class="row">

                    <div class="col-lg-8 col-md-12 col-sm-12">

                        <div class="mx-auto mb-3">
                            <SmartSearch :showTitle="true" :showHelpText="true" fromPage="home" />
                        </div>
                    </div>

                    <div class="preview-note col-lg-4 col-md-12 col-sm-12 mb-3">

                        <div class="p-3">
                            <div class="title">Welcome to the NIH RePORTER</div>
                            Each award supported by NIH promotes efforts to seek fundamental knowledge about the nature and behavior of living systems and/or the application of that knowledge to enhance health, lengthen life, and reduce illness and disability.

                            <div class="btn-container row mt-4">
                                <div class="col-12">
                                    <span class="text-start">
                                        <a href  @click.prevent="startTour()" class="feedback" rel="noopener">Guided Tour</a>
                                    </span>
                                    <span class="float-end"
                                        v-show="externalURLs && feedbackURL">
                                        <a :href="feedbackURL" @click.prevent="openFeedback()"
                                            class="feedback" rel="noopener">Feedback</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vue3-tutorial name="myTour" :tutorial="tutorialSteps" :open="tutorialIsRunning"></vue3-tutorial>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SmartSearch from '@/components/searchFields/SmartSearch.vue';
import breakpoints from '@/shared/breakpoints.utils.js';
import vue3Tutorial from 'vue3-tutorial';
import bus from '@/shared/event-bus.js';

export default {
    name: 'FeatureArea',
    components: {
        SmartSearch,
        vue3Tutorial
    },
    data: function () {
        return {
            displayName: 'Quick Search',
            feedbackURL: '',
            tutorialIsRunning: false,
            myOptions: {
				highlight: true,
                arrowAnimation : false,
				texts: {
					finishButton: 'End Tour',
					previousButton: 'Back',
					nextButton: 'Next',
					buttonStop: 'End Tour'
				}
			},
            tutorialSteps: {
                name: 'Reporter',
                steps: []
            },
            animationTime: 501,
            steps: [],
			allStepList: [
                {
					id: 'smartSearchStep',
					target: '.smart-search',
					title: 'Welcome to NIH RePORTER Quick Search',
					content: `Designed around the most frequently searched items, Quick Search automatically queries across multiple fields, returning relevant results for common queries.`
				},
				{
					id: 'advancedSearchStep',
					target: '#advanced_search',
                    title: 'Advanced Projects Search',
					content: `To search precisely using defined data fields, scroll down to search using the most common data fields on the Advanced Projects Search form.`,
                    before: type => new Promise((resolve, reject) => {
						if (this.$route.path === "/advanced-search" && this.$tours['myTour'].steps.length === 6) {
                            resolve(document.getElementById('btnMiniSearch').click());
						} else {
							resolve(true);
						}
					}),
				},
				{
					id: 'allSearchFieldsStep',
					target: '#advanced_search',
                    title: 'All Search Fields',
					content: `Expand the search form to show the full list of available search fields, where you can set up any search possible with RePORTER.`,
					before: type => new Promise((resolve, reject) => {
						if (this.$route.path !== "/advanced-search") {
                            resolve(document.getElementById('btnShowHideForm').click());
						} else {
							resolve(true);
						}
					}),
                    params: {
                        placement: 'top'
                    }
				},
				{
					id: 'mapStep',
					target: '#mapChart',
                    title: 'Active Funding by State',
					content: `Hover over the map to see the project counts and funding data by state. Click on a state to view the detailed listing of projects.`,
                    params: {
                        placement: 'top'
                    }
				},
				{
					id: 'barStep',
					target: '#barChart',
                    title: 'Active Projects by Institute / Center',
					content: `Hover over the bar chart to see project counts for the Institute or Center listed. Click on a bar to view the detailed listing of projects for that IC.`,
                    params: {
                        placement: 'top'
                    }
				},
				{
					id: 'myreporterStep',
					target: '#user-menu-dd',
                    title: 'MyRePORTER',
					content: `Sign In to MyRePORTER to save searches and set email alerts for newly funded projects, new publications, or related news.`,
                    before: type => new Promise((resolve, reject) => {
                        if (this.breakpoints.lt.lg && !this.breakpoints.lg && document.querySelectorAll(".navbar-toggler.collapsed").length > 0) {
                            resolve(document.querySelector(".navbar-toggler").click());
                        } else {
							resolve(true);
						}
					})
				}
            ],
			advancedSteps: [
				{
					id: 'smartSearchStep',
					target: '.smart-search',
					header: {
						title: 'Welcome to NIH RePORTER Quick Search',
					},
					content: `Designed around the most frequently searched items, Quick Search automatically queries across multiple fields, returning relevant results for common queries.`
				},
				{
					id: 'advancedSearchStep',
					target: '#advanced_search',
					header: {
						title: 'Advanced Projects Search'
					},
					content: `To search precisely using defined data fields, scroll down to search using the most common data fields on the Advanced Projects Search form.`
				},
				{
					id: 'mapStep',
					target: '#mapChart',
					header: {
						title: 'Active Funding by State'
					},
					content: `Hover over the map to see the project counts and funding data by state. Click on a state to view the detailed listing of projects.`,
                    params: {
                        placement: 'top'
                    }
				},
				{
					id: 'barStep',
					target: '#barChart',
					header: {
						title: 'Active Projects by Institute / Center'
					},
					content: `Hover over the bar chart to see project counts for the Institute or Center listed. Click on a bar to view the detailed listing of projects for that IC.`,
                    params: {
                        placement: 'top'
                    }
				},
				{
					id: 'myreporterStep',
					target: '.btnMyReporter',
					header: {
						title: 'MyRePORTER'
					},
					content: `Sign In to MyRePORTER to save searches and set email alerts for newly funded projects, new publications, or related news.`,
                    before: type => new Promise((resolve, reject) => {
                        if (this.breakpoints.lt.lg && !this.breakpoints.lg && document.querySelectorAll(".navbar-toggler.collapsed").length > 0) {
                            document.querySelector(".navbar-toggler").click();
                            setTimeout(function () {
                                resolve(true);
                            });
                        } else {
							resolve(true);
						}
					})
				}
			],
            myCallbacks: {
                onPreviousStep: this.myPrevStepCallback,
                onNextStep: this.myNextStepCallback,
                onFinish: this.myFinishCallback,
                onSkip: this.myFinishCallback
            }
        };
    },
    computed: {
        ...mapGetters([
            'externalURLs'
        ]),
        breakpoints: () => breakpoints.screen
    },
    mounted: async function () {
        bus.$on('get:appsettings', () => {
            this.feedbackURL = this.externalURLs['reporter_feedback_url'];
        });

        this.tutorialIsRunning = false;
    },
    methods: {
        async openFeedback () {
            var id = (new Date()).getTime();

            window.open(this.feedbackURL, id, "toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=900,height=800,left = 250,top =150");
        },
        startTour () {
			if (this.$route.path === "/advanced-search") {
                this.tutorialSteps.steps = this.advancedSteps;
            } else {
                this.tutorialSteps.steps = this.allStepList;
            }
			//this.$refs['myTour'].start();
            this.tutorialIsRunning = true;
		},
        stepCallback (forwardDirection) {
            var homeTour = this.$tours['myTour'],
                nextPrevStep = forwardDirection ? homeTour.currentStep + 1 : homeTour.currentStep - 1,
                currentTar  = homeTour.steps[nextPrevStep].target,
                tarId = homeTour.steps[nextPrevStep].id,
                sysAppMsgnodes = document.querySelectorAll('.collapse.sysAppMessage'),
                sysAppMsgnodesHt = 0;

            for (var i = 0; i < sysAppMsgnodes.length; i++) {
                sysAppMsgnodesHt = sysAppMsgnodesHt + sysAppMsgnodes[i].clientHeight
            }

            if (!forwardDirection && document.querySelector(".navbar-toggler.not-collapsed")) {
                document.querySelector(".navbar-toggler.not-collapsed").click();
            }
            switch (tarId) {
                case 'advancedSearchStep':
                case 'allSearchFieldsStep':
                        setTimeout(() => {
                            window.scrollTo(0, document.querySelectorAll('.search-tiles')[0].parentElement.offsetTop +  document.querySelector('.feature-area-wrapper').offsetHeight - document.querySelector(".v-step").clientHeight + sysAppMsgnodesHt);
                        }, this.animationTime);
                        break;
                case 'mapStep':
                case 'barStep':
                        setTimeout(() => {
                            let sectionHeight = this.breakpoints.lt.lg && !this.breakpoints.lg ?  document.querySelector(currentTar).offsetTop : 0;

                            window.scrollTo(0, document.querySelector('.home-charts').parentElement.offsetTop + sectionHeight);
                        });
                        break;
                case 'myreporterStep':
                case 'smartSearchStep':
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                        }, this.animationTime);
                        break;
            }
        },
        myPrevStepCallback () {
            this.stepCallback(false);
        },
        myNextStepCallback () {
            this.stepCallback(true);
        },
        myFinishCallback () {
            if (this.breakpoints.lt.lg && !this.breakpoints.lg && document.querySelector(".navbar-toggler.not-collapsed")) {
                document.querySelector(".navbar-toggler.not-collapsed").click();
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/scss/variables.scss';

$quickSearchMaxWidth: 700px;

.vue3-tutorial__window-container{
    --vue3-tutorial-step-bg-color: #454d5d;
    --vue3-tutorial-mask-color: rgba(0,0,0,.5)
}
.feature-area {

    > .container-fluid > .row {
        padding: 0 20px;
    }
}

.smart-search {


    max-width: $quickSearchMaxWidth;

}

.preview-note {
    border-radius: 5px;
    background-color: rgba(215, 230, 219, 0.55);
    color: #212529;
    font-weight: 500;
    position: relative;
    overflow: hidden;

    .title {
        font-size: 19px;
        font-weight: bold;
    }


    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #c0dfc3;
    }

    .btn-container {
        font-weight: bold;
        .feedback {
            background-color: #E6FCE8;
            text-decoration: none;
            border-radius: 25px;
            color: #2A5D34;
            padding: 5px 15px;
            &:hover,
            &:focus {
                background-color: #2A5D34;
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

@media screen and (max-width: $screen-md-max) {
    .feature-area {
        padding: 2em 5px;
        padding-top: calc(2em + #{$header-height});
        .field-help-text {
            position: relative;
        }
    }

    .smart-search {
        width: 100%;
    }
}


.v-tour--active {
	&:before {
		position: fixed;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0,0,0,0.5);
		z-index: 10;
	}

	.navigation {
		&:before {
			position: fixed;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0,0,0,0.5);
		z-index: 10;
		}
	}

	.preview-note, .home, .preview-note, .loading {
		z-index: unset;
	}

	.v-tour__target--highlighted {
		box-shadow: 0 0 20px #81bbfb;
		outline: 2px solid #9ccbff;
	}

    .v-step__content {
        text-align: left;
    }
}
</style>