import globalMixins from '@/globalMixins';
import { mapActions, mapGetters } from 'vuex';


export default {
    mixins: [globalMixins], //this is not actually a Vue Global Mixin!

    computed: {
        ...mapGetters(['currentQueryDetails', 'searchCriteria'])
    },
    methods: {

        ...mapActions(['setCurrentQueryDetailsAction', 'setSearchType', 'setSearchCriteria', 'setEditSearchCriteria', 'setExportCriteria']),

        //called from QueryBridge
        refreshSearchCriteriaAndRoute(searchId) { //reusing legacy logic from ProjectsSearch.search()

            delete this.searchCriteria.smart_search_text;
            delete this.searchCriteria.matchmaker_text;
            delete this.searchCriteria.publications_text;

            this.setExportCriteria(null);
            this.setEditSearchCriteria(null);
            this.setSearchCriteria(null);

            this.clearSearchResults({resetFacets: true});
            this.setSearchType("Advanced"); // <<<<< may be "Smart" if using this bridge for smart too! - may need to accept a param from Bridge
            this.fetchSearchCriteria(searchId, this.goToSearchResults); //async..
        },
        fetchSearchCriteria (search_id, callback) { //reusing legacy logic from globalMixin, but accepts search_id as a param...

            if (search_id && search_id.indexOf('CID-') !== 0) {
                window.getProjectSearchCriteria(search_id, this.updateProjectSearchCriteria, callback);
            }
        },
        goToSearchResults() {

            //get currentQD and go...
            //if there is no currentQueryDetails set, this wont work (cant pass a param in callback due to legacy chain)
            let searchId = this.currentQueryDetails.search_id; //TODO: null checks here
            let url = '/search/'+searchId+'/projects';
            this.$router.push(url).catch(()=>{});
        }
    }

}