import bus from '@/shared/event-bus.js';

(function () {
    var isIE = false,
        isMobile = false,
        ieVer,
        isAtLeastIE11,
        xhrList = [];

    window.searchFieldCache = {};
    window.serverless = location.port === '8080';
    window.default = {
        resultsLimit: 50
    }

    window.copyData = (data) => {
        return data ? JSON.parse(JSON.stringify(data)) : data;
    };

    window.addCommas = (value) => {
        if (typeof value === 'number') {
            return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        } else {
            return value;
        }
    };

    window.consoleStyle = {
        'warn': 'color: orange; font-weight: bold; background-color: #000; border-radius: 2px; padding: 0 0.25em',
        'error': 'color: red; font-weight: bold; background-color: #000; border-radius: 2px; padding: 0 0.25em',
        'reset': 'color: inherit'
    };

    // Returns the version of Internet Explorer or a -1
    // (indicating the use of another browser).
    function getInternetExplorerVersion () {
        var rv = -1, // Return value assumes failure.
            ua,
            re;

        if (navigator.appName === 'Microsoft Internet Explorer') {
            ua = navigator.userAgent;
            re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
            if (re.exec(ua) !== null) {
                rv = parseFloat(RegExp.$1);
            }
        }

        return rv;
    }

    ieVer = getInternetExplorerVersion();
    isAtLeastIE11 = !!(navigator.userAgent.match(/Trident/) && !navigator.userAgent.match(/MSIE/));

    if (ieVer > 0 || isAtLeastIE11) {
        isIE = true;
    }

    if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
        isMobile = true;
    }

    window.isIE = isIE;
    window.ieVer = ieVer;
    window.isAtLeastIE11 = isAtLeastIE11;

    window.isMobile = isMobile;


    window.snakeToCamel = (s) => {
        return s.replace(/(\_\w)/g, function (m) {
            return m[1].toUpperCase();
        });
    };

    window.pxToNumber = (prop) => {
        return Number(prop.substring(0, prop.length - 2));
    };

    window.getScrollbarWidth = () => {
        var inner = document.createElement('div'),
            outer = document.createElement('div'),
            width1,
            width2;

        inner.style.width = '100%';
        inner.style.height = '200px';
        inner.style.overflow = 'hidden';
        inner.innerText = 'test';

        outer.style.width = '200px';
        outer.style.height = '150px';
        outer.style.position = 'absolute';
        outer.style.top = '0';
        outer.style.left = '0';
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll';

        outer.appendChild(inner);
        document.body.appendChild(outer);

        width1 = outer.offsetWidth;
        width2 = inner.offsetWidth;

        if (!isIE) {
            outer.remove();
        } else {
            outer.parentNode.removeChild(outer);
        }

        return (width1 - width2);
    };

    window.getNthIndex = (str, pat, n) => {
        var L = str.length,
            i = -1;

        while (n-- && i++ < L) {
            i = str.indexOf(pat, i);

            if (i < 0) {
                break;
            }
        }

        return i;
    };

    window.scrollbarWidth = window.getScrollbarWidth();

    window.searchResultsXHR = null;

    window.getSearchResults = (params, callback) => {
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.searchResultsXHR) {
            window.searchResultsXHR.abort();
        }

        window.searchResultsXHR = new XMLHttpRequest();

        // remove criteria if Search ID exists
        if (params.search_id) {
            params.criteria = {};
        }

        if (params.criteria && ((params.criteria.fiscal_years && params.criteria.fiscal_years.length === 0) || !params.criteria.fiscal_years)) {
            params.criteria.fiscal_years = "ap";
        }

        // if (!params.facet_filters || Object.keys(window.parseFacetsFromURL()).length !== 0) {
        //     params.facet_filters = {
        //         // fiscal_years: [],
        //         // org_names: [],
        //         // agencies: [],
        //         // states: [],
        //         // countries: [],
        //         // principal_investigators: []
        //     };
        // }

        // if (params.facet_filters && Object.keys(window.parseFacetsFromURL()).length !== 0) {
        //     Object.assign(params.facet_filters, window.parseFacetsFromURL());
        // }

        serializedParams = JSON.stringify(params);

        //if (window.serverless || this.serverless) {
        // /   url = '/data/projects-search.json';
        //    requestType = 'GET';
        //} else {
        // convert id to camelCase for lookup service
        url = '/services/Projects/search/';
        //}
        // }
        // {
        //     "criteria": {
        //       "fiscalYears": "string",
        //       "piNames": "string",
        //       "orgNames": "string",
        //       "piProfileIds": "string"
        //     },
        //     "offset": 0,
        //     "limit": 0,
        //     "sortField": "string",
        //     "sortOrder": "string"
        // }

        window.searchResultsXHR.open(requestType, url, true);
        window.searchResultsXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.searchResultsXHR.onload = () => {
        
		gtag('event','timing_complete', {
			'name' : url,
			'value' :  'search',
			'event_category' : 'Search Service'
		});
		
            if (window.searchResultsXHR.status === 200) {
                data = JSON.parse(window.searchResultsXHR.response);

                if (callback) {
                    callback(data);
                }
                // } else {
                // console.warn('%cSearch Results %cservice: ' + window.searchResultsXHR.status + ' error\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);

                // this.serverlessFallback(callback);
            } else {
                if (callback) {
                    callback(window.searchResultsXHR);
                }
            }
        };

        window.searchResultsXHR.onerror = () => {
            if (callback) {
                callback(window.searchResultsXHR);
            }
        };

        window.searchResultsXHR.send(serializedParams);
    };

    window.publicationSearchResultsXHR = null;
    window.fetchPublicationSearchResults = (params, callback) => {
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.publicationSearchResultsXHR) {
            window.publicationSearchResultsXHR.abort();
        }

        window.publicationSearchResultsXHR = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/Publication/PubSearch';

        window.publicationSearchResultsXHR.open(requestType, url, true);
        window.publicationSearchResultsXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.publicationSearchResultsXHR.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Service'
			});

            if (window.publicationSearchResultsXHR.status === 200) {
                data = JSON.parse(window.publicationSearchResultsXHR.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(window.publicationSearchResultsXHR);
                }
            }
        };

        window.publicationSearchResultsXHR.onerror = () => {
            if (callback) {
                callback(window.publicationSearchResultsXHR);
            }
        };        

        window.publicationSearchResultsXHR.send(serializedParams);
    };

    window.publicationCoreProjCountXHR = null;
    window.fetchPublicationCoreProjCount = (params, callback) => {
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.publicationCoreProjCountXHR) {
            window.publicationCoreProjCountXHR.abort();
        }

        window.publicationCoreProjCountXHR = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/Publication/CoreProjectsCount';

        window.publicationCoreProjCountXHR.open(requestType, url, true);
        window.publicationCoreProjCountXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.publicationCoreProjCountXHR.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Service'
			});	           

            if (window.publicationCoreProjCountXHR.status === 200) {
                data = JSON.parse(window.publicationCoreProjCountXHR.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(window.publicationCoreProjCountXHR);
                }
            }
        };

        window.publicationCoreProjCountXHR.onerror = () => {
            if (callback) {
                callback(window.publicationCoreProjCountXHR);
            }
        };
		
        window.publicationCoreProjCountXHR.send(serializedParams);
    };

    window.NonNIHPublCountXHR = null;
    window.fetchNonNIHPublCount = (params, callback) => {
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.NonNIHPublCountXHR) {
            window.NonNIHPublCountXHR.abort();
        }

        window.NonNIHPublCountXHR = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/Publication/NonNIHPubCount';

        window.NonNIHPublCountXHR.open(requestType, url, true);
        window.NonNIHPublCountXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.NonNIHPublCountXHR.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Service'
			});
			
            if (window.NonNIHPublCountXHR.status === 200) {
                data = JSON.parse(window.NonNIHPublCountXHR.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(window.NonNIHPublCountXHR);
                }
            }
        };

        window.NonNIHPublCountXHR.onerror = () => {
            if (callback) {
                callback(window.NonNIHPublCountXHR);
            }
        };
		
        window.NonNIHPublCountXHR.send(serializedParams);
    };

    window.matchmakerSearchResultsXHR = null;
    window.getMatchmakerResults = (params, callback) => {
        // var xhr = new XMLHttpRequest(),
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.matchmakerSearchResultsXHR) {
            window.matchmakerSearchResultsXHR.abort();
        }

        window.matchmakerSearchResultsXHR = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/MatchMaker/Projects';

        window.matchmakerSearchResultsXHR.open(requestType, url, true);
        window.matchmakerSearchResultsXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.matchmakerSearchResultsXHR.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Service'
			});
		
            if (window.matchmakerSearchResultsXHR.status === 200) {
                data = JSON.parse(window.matchmakerSearchResultsXHR.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(window.matchmakerSearchResultsXHR);
                }
            }
        };

        window.matchmakerSearchResultsXHR.onerror = () => {
            if (callback) {
                callback(window.matchmakerSearchResultsXHR);
            }
        };
		
        window.matchmakerSearchResultsXHR.send(serializedParams);
    };

    window.matchmakerPOResultsXHR = null;
    window.getMatchmakerPOResults = (params, callback) => {
        // var xhr = new XMLHttpRequest(),
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.matchmakerPOResultsXHR) {
            window.matchmakerPOResultsXHR.abort();
        }

        window.matchmakerPOResultsXHR = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/MatchMaker/ProjectPO';

        window.matchmakerPOResultsXHR.open(requestType, url, true);
        window.matchmakerPOResultsXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.matchmakerPOResultsXHR.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Service'
			});	
           
            if (window.matchmakerPOResultsXHR.status === 200) {
                data = JSON.parse(window.matchmakerPOResultsXHR.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(window.matchmakerPOResultsXHR);
                }
            }
        };

        window.matchmakerPOResultsXHR.onerror = () => {
            if (callback) {
                callback(window.matchmakerPOResultsXHR);
            }
        };

        window.matchmakerPOResultsXHR.send(serializedParams);
    };

    window.patentsSearchResultsXHR = null;
    window.getPatentResults = (params, callback) => {
        // var xhr = new XMLHttpRequest(),
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.patentsSearchResultsXHR) {
            window.patentsSearchResultsXHR.abort();
        }

        window.patentsSearchResultsXHR = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/Patents/Search';

        window.patentsSearchResultsXHR.open(requestType, url, true);
        window.patentsSearchResultsXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.patentsSearchResultsXHR.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Service'
			});	           

            if (window.patentsSearchResultsXHR.status === 200) {
                data = JSON.parse(window.patentsSearchResultsXHR.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    // callback(window.patentsSearchResultsXHR);
                    callback();
                }
            }
        };

        window.patentsSearchResultsXHR.onerror = () => {
            if (callback) {
                // callback(window.patentsSearchResultsXHR);
                callback();
            }
        };

        window.patentsSearchResultsXHR.send(serializedParams);
    };

    window.pubSearchResultsXHR = null;
    window.getPublicationsResults = (params, callback) => {
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.pubSearchResultsXHR) {
            window.pubSearchResultsXHR.abort();
        }

        window.pubSearchResultsXHR = new XMLHttpRequest();
        serializedParams = JSON.stringify(params);

        url = '/services/Publication/Search';

        window.pubSearchResultsXHR.open(requestType, url, true);
        window.pubSearchResultsXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.pubSearchResultsXHR.onload = () => {
		gtag('event','timing_complete', {
			'name' : url,
			'value' :  'search',
			'event_category' : 'Publications Service'
		});           

            if (window.pubSearchResultsXHR.status === 200) {
                data = JSON.parse(window.pubSearchResultsXHR.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    // callback(window.pubSearchResultsXHR);
                    callback();
                }
            }
        };

        window.pubSearchResultsXHR.onerror = () => {
            if (callback) {
                // callback(window.pubSearchResultsXHR);
                callback();
            }
        };

        window.pubSearchResultsXHR.send(serializedParams);
    };

    window.patentsCountXHR = null;
    window.fetchPatentsCount = (params, callback) => {
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.patentsCountXHR) {
            window.patentsCountXHR.abort();
        }

        window.patentsCountXHR = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/Patents/PatentsCount';

        window.patentsCountXHR.open(requestType, url, true);
        window.patentsCountXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.patentsCountXHR.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Service'
			});	           

            if (window.patentsCountXHR.status === 200) {
                data = JSON.parse(window.patentsCountXHR.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(window.patentsCountXHR);
                }
            }
        };

        window.patentsCountXHR.onerror = () => {
            if (callback) {
                callback(window.patentsCountXHR);
            }
        };
		
        window.patentsCountXHR.send(serializedParams);
    };

    window.clinicalStudyResultsXHR = null;
    window.getClinicalStudyResults = (params, callback) => {
        // var xhr = new XMLHttpRequest(),
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.clinicalStudyResultsXHR) {
            window.clinicalStudyResultsXHR.abort();
        }

        window.clinicalStudyResultsXHR = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/ClinicalStudies/Search';

        window.clinicalStudyResultsXHR.open(requestType, url, true);
        window.clinicalStudyResultsXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.clinicalStudyResultsXHR.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Service'
			});	           

            if (window.clinicalStudyResultsXHR.status === 200) {
                data = JSON.parse(window.clinicalStudyResultsXHR.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    // callback(window.clinicalStudyResultsXHR);
                    callback();
                }
            }
        };

        window.clinicalStudyResultsXHR.onerror = () => {
            if (callback) {
                // callback(window.clinicalStudyResultsXHR);
                callback();
            }
        };

        window.clinicalStudyResultsXHR.send(serializedParams);
    };

    window.newsDataXHR = {};
    window.getNewsResultsByType = (params, newsType, callback) => {
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.newsDataXHR[newsType]) {
            window.newsDataXHR[newsType].abort();
        }

        window.newsDataXHR[newsType] = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/News/newsResults';

        window.newsDataXHR[newsType].open(requestType, url, true);
        window.newsDataXHR[newsType].setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.newsDataXHR[newsType].onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Service'
			});	           

            if (window.newsDataXHR[newsType].status === 200) {
                data = JSON.parse(window.newsDataXHR[newsType].response);

                if (callback) {
                    callback(data, newsType);
                }
            } else {
                if (callback) {
                    callback(window.newsDataXHR, newsType);
                }
            }
        };

        window.newsDataXHR[newsType].onerror = () => {
            if (callback) {
                callback(window.newsDataXHR, newsType);
            }
        };

        window.newsDataXHR[newsType].send(serializedParams);
    };

    window.newsCountXHR = {};
    window.getNewsCountByType = (params, newsType, callback) => {
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.newsCountXHR[newsType]) {
            window.newsCountXHR[newsType].abort();
        }

        window.newsCountXHR[newsType] = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/News/newsCount';

        window.newsCountXHR[newsType].open(requestType, url, true);
        window.newsCountXHR[newsType].setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.newsCountXHR[newsType].onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Service'
			});	           

            if (window.newsCountXHR[newsType].status === 200) {
                data = JSON.parse(window.newsCountXHR[newsType].response);

                if (callback) {
                    callback(data, newsType);
                }
            } else {
                if (callback) {
                    callback();
                }
            }
        };

        window.newsCountXHR[newsType].onerror = () => {
            if (callback) {
                callback();
            }
        };

        window.newsCountXHR[newsType].send(serializedParams);
    };

    window.getProjectSearchCriteria = (searchId, callback, callback2) => {
        var xhr = new XMLHttpRequest(),
            url,
            data,
            requestType = 'GET';

        url = '/services/Projects/SearchCriteria?searchId=' + searchId;

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search-criteria',
				'event_category' : 'Search Criteria Service'
			});	      

            if (xhr.status === 200) {
                data = JSON.parse(xhr.responseText);

                if (callback) {
                    callback(data, callback2);
                }
                // } else {
                // console.warn('%cSearch Results %cservice: ' + xhr.status + ' error\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);

                // this.serverlessFallback(callback);
            } else {
                if (callback) {
                    // callback(xhr);
                    callback();
                }
            }
        };

        xhr.onerror = () => {
            if (callback) {
                // callback(xhr);
                callback();
            }
        };

        xhr.send();
    };

    window.getExportFields = (callback) => {
        var xhr = new XMLHttpRequest(),
            url,
            data,
            requestType = 'GET';

        url = '/services/Lookup/exportfields';
        // url = '/data/ProjectExportFields.json';

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'Project Export  Fields',
				'event_category' : 'Export'
			});	  

            if (xhr.status === 200) {
                data = JSON.parse(xhr.responseText);

                if (callback) {
                    callback(data);
                }
                // } else {
                // console.warn('%cSearch Results %cservice: ' + xhr.status + ' error\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);

                // this.serverlessFallback(callback);
            } else {
                if (callback) {
                    callback(xhr);
                }
            }
        };

        xhr.onerror = () => {
            if (callback) {
                callback(xhr);
            }
        };
		
        xhr.send();
    };

    window.getExportFileName = (hitListType, params, callback) => {
        var xhr = new XMLHttpRequest(),
            serializedParams,
            url,
            data,
            requestType = 'POST';

        serializedParams = JSON.stringify(params);

        switch (hitListType) {
            case 'matchmaker-projects':
                url = '/services/ProjectsExport/Matchmakerprojects';
                break;
            case 'patents':
                url = '/services/PatentsExport';
                break;
            case 'publications':
                url = '/services/PublicationsExport';
                break;
            case 'publication-search-results':
                url = '/services/PublicationsExport/PublicationsSearchExport';
                break;
            case 'subProjects':
                url = '/services/ProjectsExport/Subprojects';
                break;
            case 'projectHistory':
                url = '/services/ProjectsExport/History';
                break;
            case 'clinicalStudies':
                url = '/services/ClinicalStudiesExport';
                break;
            case 'similarProjects':
                url = '/services/ProjectsExport/History';
                break;
            default:
                url = '/services/ProjectsExport/SearchResults';
        }

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'Project Export CSV',
				'event_category' : 'Export'
			});	 

            if (xhr.status === 200) {
                data = JSON.parse(xhr.responseText);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(xhr);
                }
            }
        };

        xhr.onerror = () => {
            if (callback) {
                callback(xhr);
            }
        };

        xhr.send(serializedParams);
    };

    window.getExportStatus = (hitListType, exportId, callback) => {
        var xhr = new XMLHttpRequest(),
            url,
            data,
            requestType = 'GET',
            timeStamp = '&t=' + new Date().getTime();

        switch (hitListType) {
            case 'patents':
                url = '/services/PatentsExport/status?exportId=' + exportId + timeStamp;
                break;
            case 'publications':
            case 'publication-search-results':
                url = '/services/PublicationsExport/status?pubExportId=' + exportId + timeStamp;
                break;
            case 'clinicalStudies':
                url = '/services/ClinicalStudiesExport/status?exportId=' + exportId + timeStamp;
                break;
            default:
                url = '/services/ProjectsExport/status?exportId=' + exportId + timeStamp;
        }

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'Project Export Status',
				'event_category' : 'Export'
			});	 

            if (xhr.status === 200) {
                data = JSON.parse(xhr.responseText);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(xhr);
                }
            }
        };

        xhr.onerror = () => {
            if (callback) {
                callback(xhr);
            }
        };

        xhr.send();
    };

    window.parseFacetsFromURL = () => {
        function getParams () {
            var vars = {},
                parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
                    vars[key] = value.split(';');
                });

            return vars;
        }

        return getParams();
    };

    window.getSearchFacets = (api, params, callback, routeName) => {
        var xhr = new XMLHttpRequest(),
            serializedParams,
            url,
            data,
            requestType = 'POST';

        // remove criteria if Search ID exists
        if (params.search_id && routeName !== 'map') {
            params.criteria = {};
        } else if (routeName === 'map') {
            // force exclude subprojects to match for facet values, since map always excludes
            params.criteria.exclude_subprojects = true;
            if (!params.criteria.fiscal_years || params.criteria.fiscal_years.length === 0) {
                params.criteria.fiscal_years = 'ap';
            }
        }

        // if (!params.facet_filters || Object.keys(window.parseFacetsFromURL()).length !== 0) {
        //     params.facet_filters = {
        //         // fiscal_years: [],
        //         // org_names: [],
        //         // agencies: [],
        //         // states: [],
        //         // countries: [],
        //         // principal_investigators: []
        //     };
        // }

        // if (params.facet_filters && Object.keys(window.parseFacetsFromURL()).length !== 0) {
        //     Object.assign(params.facet_filters, window.parseFacetsFromURL());
        // }

        serializedParams = JSON.stringify(params);

        url = '/services/' + api + '/facets/';

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search-facets',
				'event_category' : 'Search Facet Service'
			});	 

            if (xhr.status === 200) {
                data = JSON.parse(xhr.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(xhr);
                }
            }
        };

        xhr.onerror = () => {
            if (callback) {
                callback(xhr);
            }
        };        

        xhr.send(serializedParams);
    };

    window.getJSON = ({ url: url, callback: callback }) => {
        var xhr = new XMLHttpRequest(),
            // serializedParams,
            data,
            requestType = 'GET';

        // serializedParams = JSON.stringify(params);

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {           

            if (xhr.status === 200) {
                data = JSON.parse(xhr.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(xhr);
                }
            }
        };

        xhr.onerror = () => {
            if (callback) {
                callback(xhr);
            }
        };        

        xhr.send();
    };

    window.getPublicationsFacets = (params, callback) => {
        var xhr = new XMLHttpRequest(),
            serializedParams,
            url,
            data,
            requestType = 'POST';

        serializedParams = JSON.stringify(params);

        url = '/services/Publication/PubYear/Facets';

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'publications-facets',
				'event_category' : 'Publications Facet Service'
			});	 

            if (xhr.status === 200) {
                data = JSON.parse(xhr.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(xhr);
                }
            }
        };

        xhr.onerror = () => {
            if (callback) {
                callback(xhr);
            }
        };

        xhr.send(serializedParams);
    };

    window.parseCriteria = (tempCriteria, options) => {
        var criteria = copyData(tempCriteria);
        // parse search criteria to not include children
        // if parent is selected
        let newCriteria = {};
        if (options.isSubmitForm) {
            for (let field in window.searchFieldSpecs) {
                if (window.searchFieldSpecs[field].additionalControl && (!criteria[window.searchFieldSpecs[field].id] || criteria[window.searchFieldSpecs[field].id].length === 0)) {
                    for (let i = 0; i < window.searchFieldSpecs[field].additionalControl.options.length; i++) {
                        criteria[window.searchFieldSpecs[field].additionalControl.options[i].id] = '';
                    }
                }
            }
        }

        for (let field in criteria) {
            if (typeof criteria[field] !== 'string' && typeof criteria[field] !== 'boolean') {
                // setup each multiselect field
                if ((criteria[field] && criteria[field].length !== 0)) {
                    newCriteria[field] = [];
                }

                if (criteria[field]) {
                    if (typeof criteria[field][0] === 'string') {
                        newCriteria[field] = copyData(criteria[field]);
                    } else if (typeof criteria[field][0] === 'object') {
                        for (let i = 0; i < criteria[field].length; i++) {
                            let isParentSelected = false;

                            if (criteria[field][i].parent_value) {
                                isParentSelected = criteria[field].some((item) => {
                                    return item.value === criteria[field][i].parent_value;
                                });
                            }

                            // only add value to parsed criteria if
                            // its parent value is not selected
                            if (!isParentSelected) {
                                newCriteria[field].push(criteria[field][i]);
                            }
                        }

                        // TODO: use field specific delimiter in future
                        // newCriteria[field] = newCriteria[field].join(';');
                    } else if (!Array.isArray(criteria[field])) {
                        newCriteria[field] = {};
                        newCriteria[field] = copyData(criteria[field]);
                    }
                }
            } else if (field.toLowerCase().indexOf('__field_text') === -1 || options.isEditCriteria) {
                // if name contains 'field_text' do not pass in search
                if (criteria[field] !== null && criteria[field] !== '' && criteria[field] !== undefined) {
                    // only pass field if it has content
                    if (field === 'foa') {
                        criteria[field] = criteria[field].replace(/,/ig, ';');
                    }
                    newCriteria[field] = copyData(criteria[field]);
                }
            }
        }

        return newCriteria;
    };

    window.stringifySearchCriteriaFields = (criteria, options) => {
        var stringifiedCriteria = {},
            fieldObj, depObj,
            srcFld;

        for (let field in criteria) {
            if (typeof criteria[field] !== 'string' && typeof criteria[field] !== 'boolean') {
                if (field === 'search_field' && (!options || !options.isEditCriteria)) {
                    for (srcFld in window.searchFieldSpecs) {
                        if (window.searchFieldSpecs[srcFld] && window.searchFieldSpecs[srcFld].id === field) {
                            fieldObj = window.searchFieldSpecs[srcFld];
                            break;
                        }
                    }

                    if (criteria[fieldObj.dependentField]) {
                        if (!stringifiedCriteria['advanced_text_search']) {
                            stringifiedCriteria['advanced_text_search'] = {}
                        }
                        if (JSON.stringify(fieldObj.defaultValues.sort()).toLowerCase() === JSON.stringify(criteria[field].sort()).toLowerCase() || !criteria[field]) {
                            stringifiedCriteria['advanced_text_search'][field] = copyData(fieldObj.dbValues).join(',');
                        } else {
                            stringifiedCriteria['advanced_text_search'][field] = copyData(criteria[field]).join(',');
                        }
                    }
                    fieldObj = null;
                } else {
                    if (!criteria[field] || (criteria[field] && criteria[field].length !== 0)) {
                        stringifiedCriteria[field] = [];
                    }

                    if (typeof criteria[field][0] === 'string') {
                        stringifiedCriteria[field] = criteria[field].join(';');
                        stringifiedCriteria[field] = stringifiedCriteria[field]  ? stringifiedCriteria[field].replace(/(\x{0080}-\x{0099})/ig, '').replace(/(\x00-\x09\x0B-\x0C\x0E-\x1F)/ig, '') : stringifiedCriteria[field];
                    } else if (typeof criteria[field][0] === 'object') {

                        for (let i = 0; i < criteria[field].length; i++) {
                            if (criteria[field][i].value) {
                                stringifiedCriteria[field].push(criteria[field][i].value);
                            }
                        }

                        // TODO: use field specific delimiter in future
                        stringifiedCriteria[field] = stringifiedCriteria[field].join(';');
                    } else if (!Array.isArray(criteria[field])) {
                        stringifiedCriteria[field] = {};
                        stringifiedCriteria[field] = copyData(criteria[field]);
                    }
                }
                // } else if (field.toLowerCase().indexOf('__free_text') !== -1 || options && options.isEditCriteria) {
                //     // set free text field to main field
                //     if (criteria[field]) {
                //         // only pass field if it has content
                //         if (!options || !options.isEditCriteria) {
                //             stringifiedCriteria[field.replace('__free_text', '')] = criteria[field];
                //             stringifiedCriteria[field] = criteria[field];
                //             stringifiedCriteria[field] = null;
                //         } else {
                //             stringifiedCriteria[field] = criteria[field];
                //         }
                //     }
            } else if (field.toLowerCase().indexOf('__field_text') === -1) {
                // if name contains 'field_text' do not pass in search
                switch (field) {
                    case 'appl_type_code':
                    case 'activity_code':
                    case 'ic_code':
                    case 'serial_num':
                    case 'support_year':
                    case 'suffix_code':
                        if (!stringifiedCriteria['project_num_split']) {
                            stringifiedCriteria['project_num_split'] = {}
                        }
                        stringifiedCriteria['project_num_split'][field] = copyData(criteria[field]);
                        break;
                    case 'search_text':
                        if (!stringifiedCriteria['advanced_text_search']) {
                            stringifiedCriteria['advanced_text_search'] = {}
                        }
                        for (srcFld in window.searchFieldSpecs) {
                            if (window.searchFieldSpecs[srcFld] && window.searchFieldSpecs[srcFld].id === field) {
                                fieldObj = window.searchFieldSpecs[srcFld];
                                break;
                            }
                        }
                        if (fieldObj) {
                            for (srcFld in window.searchFieldSpecs) {
                                if (window.searchFieldSpecs[srcFld] && window.searchFieldSpecs[srcFld].id === fieldObj.dependentField) {
                                    depObj = window.searchFieldSpecs[srcFld];
                                    break;
                                }
                            }

                            if ((field !== 'search_text' && criteria['search_text']) || field === 'search_text') {
                                stringifiedCriteria['advanced_text_search'][field] = escape(copyData(criteria[field]));
                                if (!criteria[fieldObj.dependentField]) {
                                    stringifiedCriteria['advanced_text_search'][fieldObj.dependentField] = depObj.dbValues.join(',');
                                }
                            }
                        }
                        break;
                    case 'operator':
                        if (!stringifiedCriteria['advanced_text_search']) {
                            stringifiedCriteria['advanced_text_search'] = {}
                        }
                        if ((field !== 'search_text' && criteria['search_text']) || field === 'search_text') {
                            stringifiedCriteria['advanced_text_search'][field] = escape(copyData(criteria[field]));
                        }
                        break;
                    default:
                        if (criteria[field]) {
                            // only pass field if it has content
                            stringifiedCriteria[field] = copyData(criteria[field]);
                            if (typeof criteria[field] === 'string') {
                                stringifiedCriteria[field] = stringifiedCriteria[field]  ? stringifiedCriteria[field].replace(/​/ig,'') : stringifiedCriteria[field];
                            }
                        }
                }
            }
        }

        return stringifiedCriteria;
    };

    window.captchaXHR = null;

    window.getCaptchaData = (params, callback) => {
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (window.captchaXHR) {
            window.captchaXHR.abort();
        }

        window.captchaXHR = new XMLHttpRequest();

        serializedParams = JSON.stringify(params);

        url = '/services/Projects/PiPoEmails';

        window.captchaXHR.open(requestType, url, true);
        window.captchaXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        window.captchaXHR.onload = () => {            

            if (window.captchaXHR.status === 200) {
                data = JSON.parse(window.captchaXHR.response);

                if (callback) {
                    callback(data);
                }
                // } else {
                // console.warn('%cSearch Results %cservice: ' + window.captchaXHR.status + ' error\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);

                // this.serverlessFallback(callback);
            } else {
                if (callback) {
                    callback(window.captchaXHR);
                }
            }
        };

        window.captchaXHR.onerror = () => {
            if (callback) {
                callback(window.captchaXHR);
            }
        };

        window.captchaXHR.send(serializedParams);
    };

    window.getHighestValueInData = (data) => {
        let values = [],
            max;

        for (let i = 0; i < data.length; i++) {
            if (!isNaN(Number(data[i].value))) {
                values.push(data[i].value);
            }
        }

        if (values.length !== 0) {
            max = Math.max(...values);

            return max;
        }

        return false;
    };

    window.fixHeaders = (container, isNonTable) => {
        // fixed table header and left menu
        if (document.getElementById(container)) {
            // if (document.getElementById(container) && document.getElementById('clnTable')) {
            if (!isNonTable) {
                var tableContainer = document.getElementById(container),
                    table = tableContainer.querySelectorAll('table:not(.fixed-header)')[0],
                    tableHeader = table ? table.getElementsByTagName('thead')[0].cloneNode(true) : null,
                    resultsArea = document.getElementsByClassName('dataTable')[0],
                    chartArea = document.getElementsByClassName('chart-container'),
                    mapArea = document.getElementsByClassName('map-container'),
                    eTop = resultsArea ? resultsArea.offsetTop : 0,
                    fixedHeaderAboveTable = document.getElementsByClassName('drill-down-header'),
                    tHeader = document.getElementById('clnTable'),
                    scrollTable = document.getElementsByClassName('data-table')[0],
                    tableContainer = document.getElementsByClassName('tableContainer'),
                    eTableTop = tableContainer && tableContainer.length > 0 ? tableContainer[0].offsetTop : 0,
                    winW = window.innerWidth,
                    fixedHeaderAndLeftSidebar;

                window.sizeTableHeader(container);
                if (resultsArea && resultsArea.offsetParent && resultsArea.offsetParent.offsetTop && resultsArea.offsetParent.offsetTop > eTop) {
                    eTop = resultsArea.offsetParent.offsetTop;
                }
                if (chartArea && chartArea.length > 0) {
                    fixedHeaderAndLeftSidebar = document.querySelectorAll('.sidebar');
                } else if (!table) {
                    fixedHeaderAndLeftSidebar = document.querySelectorAll('#' + container + ', .sidebar');
                } else {
                    fixedHeaderAndLeftSidebar = document.querySelectorAll('#clnTable, .sidebar');
                }
                // if (eTop - window.pageYOffset < 0  && resultsArea.offsetHeight > window.innerHeight) {

                if (eTop - window.pageYOffset < 0) {
                    if (winW >= 993) {
                        for (let i = 0; i < fixedHeaderAndLeftSidebar.length; i++) {
                            fixedHeaderAndLeftSidebar[i].classList.add('fixed-head');
                        }
                    } else {
                        if (chartArea && chartArea.length > 0) {
                            fixedHeaderAndLeftSidebar = document.querySelectorAll('.fixed-head')[0];
                        } else {
                            fixedHeaderAndLeftSidebar = document.querySelectorAll('#clnTable')[0];
                        }
                        if (fixedHeaderAndLeftSidebar) {
                            fixedHeaderAndLeftSidebar.classList.add('fixed-head');
                        }
                        //fixedHeaderAndLeftSidebar.style.top = leftBar.clientHeight + 'px';
                    }
                } else {
                    for (let i = 0; i < fixedHeaderAndLeftSidebar.length; i++) {
                        fixedHeaderAndLeftSidebar[i].classList.remove('fixed-head');
                        if (chartArea && chartArea.length > 0) {
                            tHeader ? tHeader.style.top = 0 : '';
                            scrollTable ? scrollTable.style.marginTop = 0 : '';
                        }
                        if (winW < 993 && fixedHeaderAndLeftSidebar[i].classList.value.indexOf('data-table') !== -1) {
                            fixedHeaderAndLeftSidebar[i].style.top = 0;
                        }
                    }

                }

                if (chartArea && chartArea.length > 0) {
                    if (winW < 993) {
                        eTableTop = eTop + eTableTop;
                    } else {
                        eTableTop = eTop + chartArea[0].offsetHeight;
                        if (mapArea && mapArea.length > 0) {
                            eTableTop += mapArea[0].offsetHeight;
                        }
                        if (fixedHeaderAboveTable && fixedHeaderAboveTable.length > 0) { // Stick drilldown headers to top
                            if (eTableTop - window.pageYOffset < 0) {
                                fixedHeaderAboveTable[0].classList.add('fixed-head');
                            } else {
                                fixedHeaderAboveTable[0].classList.remove('fixed-head');
                            }
                        }
                    }

                    if (eTableTop - window.pageYOffset < 0) {
                        // if (chartArea && chartArea.length > 0 && winW >= 993) {
                        //     tHeader.style.top = chartArea[0].offsetHeight + 'px';
                        // }
                        if (fixedHeaderAboveTable && fixedHeaderAboveTable.length > 0) { // Leave space for drilldown header if present
                            tHeader.style.top = fixedHeaderAboveTable[0].offsetHeight + 'px';
                        } else {
                            tHeader.style.top = '0px';
                        }

                        tHeader.classList.add('fixed-head');
                    } else {
                        if (tHeader) {
                            tHeader.classList.remove('fixed-head');
                            tHeader.style.top = 0;
                        }
                    }
                }
            } else {
                var tableContainer = document.getElementById(container),
                    eTop = tableContainer.parentNode.parentNode.offsetTop,
                    winW = window.innerWidth,
                    fixedHeaderAndLeftSidebar;

                fixedHeaderAndLeftSidebar = document.querySelectorAll('#' + container + ', .sidebar');
                // if (eTop - window.pageYOffset < 0  && resultsArea.offsetHeight > window.innerHeight) {
                if (eTop - window.pageYOffset < 0) {
                    if (winW >= 993) {
                        for (let i = 0; i < fixedHeaderAndLeftSidebar.length; i++) {
                            fixedHeaderAndLeftSidebar[i].classList.add('fixed-head');
                        }
                    } else {
                        fixedHeaderAndLeftSidebar = document.querySelectorAll('#' + container)[0];
                        fixedHeaderAndLeftSidebar.classList.add('fixed-head');
                    }
                } else {
                    for (let i = 0; i < fixedHeaderAndLeftSidebar.length; i++) {
                        fixedHeaderAndLeftSidebar[i].classList.remove('fixed-head');
                        if (winW < 993 && fixedHeaderAndLeftSidebar[i].classList.value.indexOf('data-table') !== -1) {
                            fixedHeaderAndLeftSidebar[i].style.top = 0;
                        }
                    }

                }
            }
        }
    }

    window.sizeTableHeader = (container) => {
        var tableContainer = document.getElementById(container),
            table = tableContainer.querySelectorAll('table:not(.fixed-header):not(.fixed-within)'),
            fixedHeader = tableContainer.querySelectorAll('.fixed-header:not(.project-basics), #smart-search-bar, .fixed-within'),
            newWidth = 0,
            newHeight = 0,
            newTableHeaders,
            orgTableHeaders;

        if (fixedHeader.length !== 0) {
            for (let i = 0; i < fixedHeader.length; i++) {
                orgTableHeaders = table ? table[i].getElementsByTagName('th') : []
                fixedHeader[i].style.width = table ? table[i].clientWidth + 'px' : '100%';
                newTableHeaders = fixedHeader[i].getElementsByTagName('th')

                for (let j = 0; j < orgTableHeaders.length; j++) {
                    newWidth = orgTableHeaders[j].offsetWidth;
                    newHeight = orgTableHeaders[j].offsetHeight;
                    if (newTableHeaders && newTableHeaders[j]) {
                        newTableHeaders[j].style.width = newWidth + 'px';
                        newTableHeaders[j].style.height = newHeight + 'px';
                    }
                }
            }
        } else {
            tableContainer.style.width = tableContainer.parentNode.clientWidth + 'px';
        }
    }

    window.createCookie = (name, value, days) => {
        var expires,
            domain = window.location.hostname,
            protocol = window.location.protocol,
            secure = (protocol !== 'http:') ? 'secure' : '',
            fullCookie = '';

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toGMTString();
        } else {
            expires = '';
        }

        document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + expires + '; path=/; ' + secure;
    }

    window.readCookie = (name) => {

        //TODO: update variable names to be descriptive
        var nameEQ = encodeURIComponent(name) + '=',
            ca = document.cookie.split(';'),
            c,
            i;

        for (i = 0; i < ca.length; i++) {
            c = ca[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) === 0) {
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
            }
        }

        //does this need to return at all?
        return null;
    }

    window.getSearchGraphData = (params, callback, urlOverride) => {
        if (xhrList['graphData']) {
            xhrList['graphData'].abort();
        }

        xhrList['graphData'] = new XMLHttpRequest();
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (params.criteria && params.criteria.fiscal_years) {
            params.criteria.fiscal_years = params.criteria.fiscal_years.replace(/ap(;*)/, '');
            // if (params.criteria.fiscal_years.trim() === '') {
            //     delete params.criteria.fiscal_years;
            // }
        }
        serializedParams = JSON.stringify(params);

        if (urlOverride) {
            url = urlOverride;
        } else {
            url = '/services/Visualization/';
        }
        // if (window.serverless || this.serverless) {
        //    url = '/data/graph-data.json';
        //    requestType = 'GET';
        // } else {
        // url = '/services/Visualization/';
        // url = '/services/Chart/';
        // }

        xhrList['graphData'].open(requestType, url, true);
        xhrList['graphData'].setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhrList['graphData'].onload = () => {
			gtag('event','', {
        'event_category': '',
        'event_label': '',
        'value': 'asda'
      })
			

            if (xhrList['graphData'].status === 200) {
                data = JSON.parse(xhrList['graphData'].response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(xhrList['graphData']);
                }
            }
        };

        xhrList['graphData'].onerror = () => {
            if (callback) {
                callback(xhrList['graphData']);
            }
        };
		
        xhrList['graphData'].send(serializedParams);
    };

    window.getSearchCirclesData = (params, callback, urlOverride) => {
        if (xhrList['circlesData']) {
            xhrList['circlesData'].abort();
        }

        xhrList['circlesData'] = new XMLHttpRequest();
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (params.criteria && params.criteria.fiscal_years) {
            params.criteria.fiscal_years = params.criteria.fiscal_years.replace(/ap(;*)/, '');
            // if (params.criteria.fiscal_years.trim() === '') {
            //     delete params.criteria.fiscal_years;
            // }
        }
        serializedParams = JSON.stringify(params);

        if (urlOverride) {
            url = urlOverride;
        } else {
            url = '/services/Cluster/ClusterData';
        }

        xhrList['circlesData'].open(requestType, url, true);
        xhrList['circlesData'].setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhrList['circlesData'].onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Search Circles Visualization Service'
			});

            if (xhrList['circlesData'].status === 200) {
                data = JSON.parse(xhrList['circlesData'].response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(xhrList['circlesData']);
                }
            }
        };

        xhrList['circlesData'].onerror = () => {
            if (callback) {
                callback(xhrList['circlesData']);
            }
        };

        xhrList['circlesData'].send(serializedParams);
    };

    window.getMapData = (params, callback, callback2, urlOverride) => {
        if (xhrList.mapData) {
            xhrList.mapData.abort();
        }

        xhrList.mapData = new XMLHttpRequest();
        var serializedParams,
            url,
            data,
            requestType = 'POST';

        if (params && params.criteria && params.criteria.fiscal_years) {
            params.criteria.fiscal_years = params.criteria.fiscal_years.replace(/ap(;*)/, '');
            // if (params.criteria.fiscal_years.trim() === '') {
            //     delete params.criteria.fiscal_years;
            // }
        }
        serializedParams = JSON.stringify(params);

        if (urlOverride) {
            url = '/services/Map/' + urlOverride;
        } else {
            url = '/services/Map/DataByCountry';
        }
        // if (window.serverless || this.serverless) {
        //    url = '/data/graph-data.json';
        //    requestType = 'GET';
        // } else {
        // url = '/services/Visualization/';
        // url = '/services/Chart/';
        // }

        xhrList.mapData.open(requestType, url, true);
        xhrList.mapData.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhrList.mapData.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Map Visualization Service'
			});

            if (xhrList.mapData.status === 200) {
                data = JSON.parse(xhrList.mapData.response);

                if (callback) {
                    callback(data, callback2);
                }
            } else {
                if (callback) {
                    callback(xhrList.mapData, callback2);
                }
            }
        };

        xhrList.mapData.onerror = () => {
            if (callback) {
                callback(xhrList.mapData, callback2);
            }
        };

        xhrList.mapData.send(serializedParams);
    };

    window.getTextWidth = (text, font) => {
        // re-use canvas object for better performance
        var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas')),
            context = canvas.getContext('2d'),
            metrics,
            textWidth = 0,
            i;

        context.font = font;

        // if multi-line text
        if (text) {
            if (text.toString().indexOf('\n') !== -1) {
                text = text.toString().split('\n');

                // find longest line
                for (i = 0; i < text.length; i++) {
                    metrics = context.measureText(text[i]);

                    if (metrics.width > textWidth) {
                        textWidth = metrics.width;
                    }
                }
            } else {
                metrics = context.measureText(text);
                textWidth = metrics.width;
            }
        }

        return textWidth;
    }

    window.getProjectBasicInfo = (params, infoType, callback) => {
        var xhr = new XMLHttpRequest(),
            serializedParams = '',
            url,
            data,
            requestType = 'GET';

        switch (infoType) {
            case 'basicInfo':
                url = '/services/Projects/ProjectInfo?projectId=' + params.project_id;
                if (params.search_id && params.search_id.indexOf('CID-') !== 0) {
                    url += '&searchId=' + params.search_id;
                }
                break;
            case 'otherDetails':
                url = '/services/Projects/ProjectDetail?projectId=' + params.project_id;
                break;
            case 'patents':
                url = '/services/Projects/Patents?projectId=' + params.project_id;
                break;
            case 'publications':
                url = '/services/Projects/Publications?projectId=' + params.project_id;
                break;
            case 'clinicalStudies':
                url = '/services/Projects/ClinicalStudies?projectId=' + params.project_id;
                break;
            case 'projectHistory':
                url = '/services/Projects/ProjectHistory';
                requestType = 'POST';
                serializedParams = JSON.stringify(params);
                break;
            case 'subProjects':
                url = '/services/Projects/ProjectSubProjects';
                requestType = 'POST';
                serializedParams = JSON.stringify(params);
                break;
            case 'outcomes':
                url = '/services/Projects/ProjectOutcomes?projectId=' + params.project_id;
                break;
            case 'similarProjects':
                url = '/services/Projects/SimilarProjects';
                requestType = 'POST';
                serializedParams = JSON.stringify(params);
                break;
            case 'PressRelease':
            case 'ResearchMatters':
            case 'AIRS':
            case 'ARRA':
            case 'AIMRS':
                url = '/services/Projects/NewsResults';
                requestType = 'POST';
                serializedParams = JSON.stringify(params);
                break;
        }

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Publications Service'
			});

            if (xhr.status === 200) {
                data = JSON.parse(xhr.response);

                if (callback) {
                    callback(data, infoType);
                }
            } else {
                if (callback) {
                    callback(xhr);
                }
            }
        };

        xhr.onerror = () => {
            if (callback) {
                callback(xhr);
            }
        };

        xhr.send(serializedParams);
    }

    window.findApplId = (params, callback) => {
        var xhr = new XMLHttpRequest(),
            url = '/services/Publication/FetchApplId',
            data,
            requestType = 'POST';

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Publications Service'
			});

            if (xhr.status === 200) {
                data = JSON.parse(xhr.response);

                if (callback) {
                    callback(data);
                }
            } else {
                if (callback) {
                    callback(xhr);
                }
            }
        };

        xhr.onerror = () => {
            if (callback) {
                callback(xhr);
            }
        };

        xhr.send('"' + params + '"');
    }

    window.logPublicationsPage = () => {
        var xhr = new XMLHttpRequest(),
            url = '/services/Publication/PubLog',
            data,
            requestType = 'POST';

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Publications Service'
			});
        };

        xhr.onerror = () => {
        };

        xhr.send();
    }

    window.saveSharedURL = (params) => {
        var xhr = new XMLHttpRequest(),
            url = '/services/Shared/Mark',
            data,
            requestType = 'POST',
            serializedParams = JSON.stringify(params);

        xhr.open(requestType, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = () => {
			gtag('event','timing_complete', {
				'name' : url,
				'value' :  'search',
				'event_category' : 'Save Share URL Service'
			});
        };

        xhr.onerror = () => {
        };
		
        xhr.send(serializedParams);
    }

    function toCSV (csv, filename) {
        var csvData,
            textEncoder,
            csvContentEncoded,
            blob,
            IEwindow;

        if (typeof (TextEncoder) !== 'undefined') {
            textEncoder = new TextEncoder('utf-8');
            csvContentEncoded = textEncoder.encode(['\ufeff' + csv]);
            // Create blob
            blob = new Blob([csvContentEncoded], {
                type: 'attachment/csv;charset=utf-8'
            });

            csvData = URL.createObjectURL(blob);
        } else {
            if (isIE) {
                if (window.ieVer === 9) {
                    IEwindow = window.open();
                    IEwindow.document.write('sep=,\r\n' + csv);
                    IEwindow.document.close();
                    IEwindow.document.execCommand('SaveAs', true, filename + '.csv');
                    IEwindow.close();
                } else {
                    blob = new Blob(['\uFEFF' + csv], {
                        type: 'text/csv;charset=utf-8'
                    });

                    csvData = URL.createObjectURL(blob);
                }
            } else {
                csvData = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(csv);
            }
        }

        // download file
        if (isIE) {
            if (window.navigator.msSaveOrOpenBlob) { // check for IE
                navigator.msSaveBlob(blob, filename);
            }
        } else {
            var downloadLink = document.createElement('a');

            downloadLink.setAttribute('id', 'csv-download');
            downloadLink.setAttribute('href', csvData);
            downloadLink.setAttribute('download', filename);
            downloadLink.setAttribute('style', 'opacity: 0;');
            downloadLink.innerText = 'Download';

            document.querySelector('body').appendChild(downloadLink);
            document.getElementById('csv-download').click();
            document.getElementById('csv-download').remove();
        }
    }

    window.exportDataToCSV = function (filename, columns, data) {
        // Temporary delimiter characters unlikely to be typed by keyboard
        // This is to avoid accidentally splitting the actual contents
        var tmpColDelim = String.fromCharCode(11), // vertical tab character
            tmpRowDelim = String.fromCharCode(0), // null character

            // actual delimiter characters for CSV format
            colDelim = '","',
            rowDelim = '"\r\n"',
            exportData = [],
            tempDataRow = null,
            tempPiNameList = null,
            monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ],
            tempArray,
            dt = new Date(),
            csv = '"',
            filename = filename + '_' + dt.getDate() + '' + monthNames[dt.getMonth()] + '' + dt.getFullYear() + '_' + dt.getHours() + '' + dt.getMinutes() + '' + dt.getSeconds() + '.csv',
            urlData = '';
		
		gtag('event','Click', {
			'event_category': 'Export CSV',
			'event_label': filename,
			'value': 'Export CSV: ' + filename
		});	

        // event header
        tempArray = [];
        for (let col in columns) {
            if (columns[col].isVisible || columns[col].exportOnly) {
                tempArray.push(columns[col].displayName.replace(/\u00a0/g, " "));
            }
        }

        exportData.push(tempArray.join(tmpColDelim));

        function buildURLString (row, col) {
            var tempURL = col.refURL;

            for (let i = 0; i < col.urlFields.length; i++) {
                if (row[col.urlFields[i]]) {
                    tempURL = tempURL.replace('{{field' + i + '}}', row[col.urlFields[i]]);
                }
            }
            if (tempURL.indexOf("{{") !== -1) {
                return '';
            }
            return tempURL;
        }


        function parseExportData (dataRow) { // per data row
            tempArray = [];

            for (let col in columns) {
                const column = columns[col];

                if (column.isVisible || column.exportOnly) {
                    if (typeof (dataRow[column.fieldName]) === 'string' && dataRow[column.fieldName].indexOf('"') !== -1) {
                        dataRow[column.fieldName] = dataRow[column.fieldName].split('"').join('""');
                    } else if (dataRow[column.fieldName] === null) {
                        dataRow[column.fieldName] = "";
                    }

                    switch (column.fieldName) {
                        case 'project_funding_amt':
                        case 'sub_project_funding_amt':
                            if (dataRow[column.fieldName]) {
                                tempArray.push(window.filters.formatCurrency(dataRow[column.fieldName]));
                            } else {
                                tempArray.push('');
                            }
                            break;
                        case 'relative_citation_ratio':
                            if (dataRow[column.fieldName]) {
                                tempArray.push(window.filters.round(dataRow[column.fieldName]));
                            }
                            break;
                        default:
                            if (column.refURLField) {
                                urlData = dataRow[column.refURLField] ? (dataRow[column.refURLField].value ? dataRow[column.refURLField].value : dataRow[column.refURLField]) : '';
                                if (dataRow[column.fieldName]) {
                                    urlData = "<a href='" + urlData + "' target='_blank' rel='noopener'>" + dataRow[column.fieldName] + "</a>";
                                } else if (column.fieldText) {
                                    urlData = "<a href='" + urlData + "' target='_blank' rel='noopener'>" + column.fieldText + "</a>";
                                }
                                tempArray.push(urlData);
                            }
                            else if (column.refURL) {
                                urlData = buildURLString(dataRow, column);
                                if (urlData !== '') {
                                    if (dataRow[column.fieldName]) {
                                        urlData = "<a href='" + urlData + "' target='_blank' rel='noopener'>" + dataRow[column.fieldName] + "</a>";
                                    } else if (column.fieldText) {
                                        urlData = "<a href='" + urlData + "' target='_blank' rel='noopener'>" + column.fieldText + "</a>";
                                    }
                                    tempArray.push(urlData);
                                } else {
                                    if (column.fieldName) {
                                        tempArray.push(dataRow[column.fieldName]);
                                    } else {
                                        tempArray.push('');
                                    }
                                }
                            } else {
                                if (dataRow[column.fieldName]) {
                                    tempArray.push(dataRow[column.fieldName]);
                                } else {
                                    tempArray.push('');
                                }
                            }
                    }
                }
            }

            exportData.push(tempArray.join(tmpColDelim));
        }

        // event data
        for (let j = 0; j < data.length; j++) {
            parseExportData(data[j]);
        }

        // travel requests data
        exportData.push('');
        exportData.push('');

        // compile all data
        csv += exportData.join(tmpRowDelim)
            .split(tmpRowDelim).join(rowDelim)
            .split(tmpColDelim).join(colDelim);

        csv += '"';

        toCSV(csv, filename);
    };


    window.getPreviousMonday = function (week) {
        var date = new Date(),
            day = date.getDay(),
            prevWeekCnt = ((week - 1) * 7),
            prevSaturday,
            prevSatStr;

        if (date.getDay() === 6) {
            prevSaturday = date.setDate(date.getDate() - week * 7);
        } else {
            prevSaturday = date.setDate(date.getDate() - 1 - prevWeekCnt - day);
        }

        prevSaturday = new Date(prevSaturday);
        let prevSatMon = prevSaturday.getMonth() + 1;
        let prevSatDt = prevSaturday.getDate();

        return (prevSatMon < 10 ? '0' : '') + prevSatMon + '/' + (prevSatDt < 10 ? '0' : '') + prevSatDt + '/' + prevSaturday.getFullYear();
    }

    window.getVueComp = (dom) => {
        var key,
            comp;

        // parse if jQuery element
        // if (dom[0]) {
        //     dom = dom[0];
        // }

        for (key in dom) {
            if (key.indexOf('__vue') !== -1) {
                comp = dom[key];

                return comp;
            }
        }

        return null;
    };

    // google maps requires window level function for callback
    // this function will pass back to vue map component
    window.initGoogleMap = function () {
        // emit map init event
        bus.$emit('map:init')
    }

    window.offset = (el) => {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft
        };
    };

    window.getAbsoluteHeight = (el) => {
        // Get the DOM Node if you pass in a string
        el = (typeof el === 'string') ? document.querySelector(el) : el;

        var styles = window.getComputedStyle(el),
            margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

        return Math.ceil(el.offsetHeight + margin);
    };

    window.stateCodeToName = {
        'AL': 'Alabama',
        'AK': 'Alaska',
        'AZ': 'Arizona',
        'AR': 'Arkansas',
        'CA': 'California',
        'CO': 'Colorado',
        'CT': 'Connecticut',
        'DE': 'Delaware',
        'DC': 'District of Columbia',
        'FL': 'Florida',
        'GA': 'Georgia',
        'HI': 'Hawaii',
        'ID': 'Idaho',
        'IL': 'Illinois',
        'IN': 'Indiana',
        'IA': 'Iowa',
        'KS': 'Kansas',
        'KY': 'Kentucky',
        'LA': 'Louisiana',
        'ME': 'Maine',
        'MD': 'Maryland',
        'MA': 'Massachusetts',
        'MI': 'Michigan',
        'MN': 'Minnesota',
        'MS': 'Mississippi',
        'MO': 'Missouri',
        'MT': 'Montana',
        'NE': 'Nebraska',
        'NV': 'Nevada',
        'NH': 'New Hampshire',
        'NJ': 'New Jersey',
        'NM': 'New Mexico',
        'NY': 'New York',
        'NC': 'North Carolina',
        'ND': 'North Dakota',
        'OH': 'Ohio',
        'OK': 'Oklahoma',
        'OR': 'Oregon',
        'PW': 'Palau',
        'PA': 'Pennsylvania',
        'RI': 'Rhode Island',
        'SC': 'South Carolina',
        'SD': 'South Dakota',
        'TN': 'Tennessee',
        'TX': 'Texas',
        'UT': 'Utah',
        'VT': 'Vermont',
        'VA': 'Virginia',
        'WA': 'Washington',
        'WV': 'West Virginia',
        'WI': 'Wisconsin',
        'WY': 'Wyoming',
        // US Territories
        'AS': 'American Samoa',
        'GU': 'Guam',
        'MP': 'Northern Mariana Islands',
        'PR': 'Puerto Rico',
        'VI': 'Virgin Islands'
    };

    window.stateNameToCode = {
        'ALABAMA': 'AL',
        'ALASKA': 'AK',
        'ARIZONA': 'AZ',
        'ARKANSAS': 'AR',
        'CALIFORNIA': 'CA',
        'COLORADO': 'CO',
        'CONNECTICUT': 'CT',
        'DELAWARE': 'DE',
        'DIST OF COL': 'DC',
        'FLORIDA': 'FL',
        'GEORGIA': 'GA',
        'GUAM': 'GU',
        'HAWAII': 'HI',
        'IDAHO': 'ID',
        'ILLINOIS': 'IL',
        'INDIANA': 'IN',
        'IOWA': 'IA',
        'KANSAS': 'KS',
        'KENTUCKY': 'KY',
        'LOUISIANA': 'LA',
        'MAINE': 'ME',
        'MARYLAND': 'MD',
        'MASSACHUSETTS': 'MA',
        'MICHIGAN': 'MI',
        'MINNESOTA': 'MN',
        'MISSISSIPPI': 'MS',
        'MISSOURI': 'MO',
        'MONTANA': 'MT',
        'NEBRASKA': 'NE',
        'NEVADA': 'NV',
        'NEW HAMPSHIRE': 'NH',
        'NEW JERSEY': 'NJ',
        'NEW MEXICO': 'NM',
        'NEW YORK': 'NY',
        'NORTH CAROLINA': 'NC',
        'NORTH DAKOTA': 'ND',
        'OHIO': 'OH',
        'OKLAHOMA': 'OK',
        'OREGON': 'OR',
        'PENNSYLVANIA': 'PA',
        'RHODE ISLAND': 'RI',
        'SOUTH CAROLINA': 'SC',
        'SOUTH DAKOTA': 'SD',
        'TENNESSEE': 'TN',
        'TEXAS': 'TX',
        'UTAH': 'UT',
        'VERMONT': 'VT',
        'VIRGINIA': 'VA',
        'WASHINGTON': 'WA',
        'WEST VIRGINIA': 'WV',
        'WISCONSIN': 'WI',
        'WYOMING': 'WY',
        // US Territories
        'AMERICAN SAMOA': 'AS',
        'GUAM': 'GU',
        'NORTHERN MARIANA ISLANDS': 'MP',
        'PUERTO RICO': 'PR',
        'VIRGIN ISLANDS': 'VI'
    };

    window.getElementY = (element) => {
        // return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top;
        return window.pageYOffset + element.getBoundingClientRect().top;
    };

    window.doScrolling = (element, duration) => {
        var startingY = window.pageYOffset,
            elementY = window.getElementY(element),
            // If element is close to page's bottom then window will scroll only to some position above the element.
            targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY,
            diff = targetY - startingY,
            // Easing function: easeInOutCubic
            // From: https://gist.github.com/gre/1650294
            easing = function (t) {
                return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
            },
            start;

        if (!diff) {
            return
        }

        // Bootstrap our animation - it will get called right before next frame shall be rendered.
        window.requestAnimationFrame(function step (timestamp) {
            if (!start) {
                start = timestamp;
            }
            // Elapsed miliseconds since start of scrolling.
            let time = timestamp - start,
                // Get percent of completion in range [0, 1].
                percent = Math.min(time / duration, 1);
            // Apply the easing.
            // It can cause bad-looking slow frames in browser performance tool, so be careful.
            percent = easing(percent);

            window.scrollTo(0, startingY + diff * percent);

            // Proceed with animation as long as we wanted it to.
            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        });
    };

    window.closest = (el, selector) => {
        var matchesFn;

        // find vendor prefix
        ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
            if (typeof document.body[fn] == 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        })

        var parent;

        // traverse parents
        while (el) {
            parent = el.parentElement;
            if (parent && parent[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }

        return null;
    };

})();


