<template>
    <div class="search-field input-text" :class="$store.state.searchFieldErrors[id] && $store.state.searchFieldErrors[id].message ? 'field-error' : ''" :id="'field-' + id + '-container'" >
        <label
            class="field-title"
            :class="titleHidden ? 'sr-only' : ''"
            :for="'field-' + id"
        >
            {{title}}
            <field-tooltip v-if="tooltipText" :text="tooltipText" :urlKeys="urlKeys" />
        </label>
        <div class="loader" v-if="loading"></div>
        <div
            class=""
        >
            <div class="inline">
                <label class="field-title subtitle sr-only" :for="'field-' + id + '-min'">Min</label>
                <input type="text" :value="minAmount" :id="'field-' + id + '-min'" :min="options.min" :max="options.max" autocomplete="false" ref="fieldMin" :disabled="loading" />
            </div>
            <div class="inline">
                <label class="field-title subtitle sr-only" :for="'field-' + id + '-max'">Max</label>
                <input type="text" :value="maxAmount" :id="'field-' + id + '-max'" :min="options.min" :max="options.max" autocomplete="false" ref="fieldMax" :disabled="loading" />
            </div>
            <vue-slider
                v-if="isLoaded"
                v-model="rangeVal"
                v-bind="options"
                class="slider"
                :id="'field-' + id" :lazy="true"
                @change="validateInput(false)"
                :included="true"
                :hideLabel="true"
                tooltip="none"
                :tooltip-formatter="tooltipFormatter"
                tooltipPlacement="bottom"
                :railStyle="{backgroundColor: '#e4e4e4'}"
                :processStyle="{backgroundColor: '#015496'}"
                :tooltipStyle="{backgroundColor: '#015496', borderColor: '#015496'}"
            ></vue-slider>
                <!-- :data="getDataRange" -->
        </div>
        <div class="field-help-text" v-if="helpTextDisplay" >
            {{helpTextDisplay}}
        </div>
        <field-error
            v-if="$store.state.searchFieldErrors[id]"
            v-show="$store.state.searchFieldErrors[id].message"
            :message="$store.state.searchFieldErrors[id].message"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FieldError from '@/components/searchFields/FieldError.vue';
import FieldTooltip from '@/components/searchFields/FieldTooltip.vue'
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import bus from '@/shared/event-bus.js';

export default {
    name: 'InputDate',
    components: {
        FieldError,
        FieldTooltip,
        VueSlider
    },
    props: {
        title: {
            required: true
        },
        id: {
            required: true
        },
        maxSelection: {
            required: false
        },
        delimiter: {
            required: false,
            default: ';'
        },
        fields: {
            required: false
        },
        helpText: {
            required: false
        },
        placeholder: {
            required: false
        },
        dependentField: {
            required: false
        },
        select: {
            required: false
        },
        deselect: {
            required: false
        },
        change: {
            required: false
        },
        maxLength: {
            required: false
        },
        matchingStr: {
            required: false
        },
        titleHidden: {
            required: false
        },
        alternetFields: {
            required: false
        },
        groupFields: {
            groupFields: false
        },
        bus: {
            required: false
        },
        dynamicLookup: {
            required: false
        },
        tooltipText: {
            required: false
        },
        urlKeys: {
            required: false
        }
        // inputTypes: {
        //     required: false
        // },
        // submitTypes: {
        //     required: false
        // }
    },
    watch: {
        // reset value if not in correct format
        '$store.state.searchCriteria.award_amount_range': function (val, oldVal) {
            if (val && val.length !== undefined) {
                this.updateSearchCriteria();
            }
        },
        '$store.state.lookup.award_amount_range': function (val, oldVal) {
            if (val && val.max_award_amount) {
                this.options.max = Math.ceil(val.max_award_amount/100)*100;
                this.isLoaded = true;
            }
        }
    },
    computed: {
        ...mapGetters([
            'searchCriteria',
            'searchFieldErrors'
        ]),
        getDataRange () {
            var i,
                dataRange = [],
                maxue = this.defaultValues[1],
                roundBy = 1000000,
                maxRounded = Math.ceil(maxue / roundBy) * roundBy;

            this.max = maxRounded;

            for (i = 0; i < 10000 - 1;) {
                dataRange.push(i);
                 i += 1000;
            }

            for (i = 1000000; i < 100000 - 1;) {
                dataRange.push(i);
                 i += 10000;
            }

            for (i = 100000; i < 10000000 - 1;) {
                dataRange.push(i);
                 i += 100000;
            }

            for (i = 10000000; i < maxRounded - 1;) {
                dataRange.push(i);
                i += 10000000;
            }

            // for (i = 0; i < 500000000; i++) {
            //     dataRange.push(i);
            // }

            dataRange.push(maxRounded);

            // console.log(dataRange);

            return dataRange;
        }
    },
    data: function () {
        return {
            loading: true,
            isLoaded: false,
            helpTextDisplay: this.helpText ? this.helpText : null,
            inputHighlightText: '',
            rangeVal: [],
            // maxNumberLength: this.max.toString().length,
            maxAmount:null,
            minAmount:null,
            validationTimeout:null,
            tooltipFormatter: v => `${this.formatTooltip(v)}`,
            ignoreKeys: [
                //arrows
                37, //left
                38, //up
                39, //right
                40, //down

                //other
                9, //tab
                16, //shift
            ],
            allowedKeys: [
                //numbers on top of keyboard
                48, //0
                49, //1
                50, //2
                51, //3
                52, //4
                53, //5
                54, //6
                55, //7
                56, //8
                57, //9

                // numpad on side of keyboard
                96, //0
                97, //1
                98, //2
                99, //3
                100, //4
                101, //5
                102, //6
                103, //7
                104,//8
                105,//9

                //arrows
                37, //left
                38, //up
                39, //right
                40, //down

                //other
                8, //backspace
                9, //tab
                13, //enter
                16, //shift
                46, //delete
            ],
            keyBackspace: 8,
            keyDelete: 46,
            previousValue: null,
            commaCount: null,
            cursorPos: null,
            cursorPosNew: null,
            newValue: null,
            defaultValues: [],
            options: {
                dotSize: 18,
                width: 'auto',
                height: 6,
                contained: false,
                direction: 'ltr',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                min: 0,
                max: 500000000,
                interval: 100,
                disabled: false,
                clickable: true,
                duration: 0.1,
                adsorb: false,
                lazy: false,
                tooltip: 'active',
                tooltipPlacement: 'top',
                tooltipFormatter: void 0,
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                minRange: void 0,
                maxRange: void 0,
                order: true,
                marks: false,
                dotOptions: void 0,
                dotAttrs: void 0,
                process: true,
                dotStyle: void 0,
                railStyle: void 0,
                processStyle: void 0,
                tooltipStyle: void 0,
                stepStyle: void 0,
                stepActiveStyle: void 0,
                labelStyle: void 0,
                labelActiveStyle: void 0,
            },
            value: [0, 50],
        };
    },
    mounted () {
        var inputValDefault = {
            'min_amount': null,
            'max_amount': null
        },
        $this = this;

        this.maxNumberLength = this.options.max.toString().length;

        // initialize field in store so that it will be watched
        if (!this.$store.state.searchCriteria[this.id] || this.$store.state.searchCriteria[this.id].min_amount === undefined || this.$store.state.searchCriteria[this.id].max_amount === undefined) {
            this.$store.state.searchCriteria[this.id] = inputValDefault;
        }

        if (!this.$store.state.searchCriteriaDefault[this.id]  || this.$store.state.searchCriteriaDefault[this.id].min_amount === undefined || this.$store.state.searchCriteriaDefault[this.id].max_amount === undefined) {
            this.$store.state.searchCriteriaDefault[this.id] = inputValDefault;
        }

        if (this.$store.state.editSearchCriteria[this.id] && (this.$store.state.editSearchCriteria[this.id].min_amount !== null || this.$store.state.editSearchCriteria[this.id].max_amount !== null)) {
            this.$store.state.searchCriteria[this.id] = this.$store.state.editSearchCriteria[this.id];
        }

        if (!this.$store.state.searchFieldErrors[this.id]) {
            this.$store.state.searchFieldErrors[this.id] = '';
        }

        if (!this.$store.state.lookup[this.id]) {
            this.$store.state.lookup[this.id] = [];
            this.setData();
        } else {
            this.loading = false;
            this.defaultValues = [this.$store.state.lookup[this.id].min_award_amount, this.$store.state.lookup[this.id].max_award_amount];
            this.options.max = Math.ceil(this.$store.state.lookup[this.id].max_award_amount/100)*100;
            this.isLoaded = true;
            this.rangeVal = [this.$store.state.lookup[this.id].min_award_amount, this.$store.state.lookup[this.id].max_award_amount];
            
            if (this.$store.state.editSearchCriteria[this.id] && this.$store.state.editSearchCriteria[this.id].min_amount !== null && this.$store.state.editSearchCriteria[this.id].min_amount !== undefined) {
                this.rangeVal = [this.$store.state.editSearchCriteria[this.id].min_amount, this.$store.state.editSearchCriteria[this.id].max_amount];
            } else if (this.$store.state.searchCriteria[this.id]) {
                if (this.$store.state.searchCriteria[this.id].min_amount !== null && this.$store.state.searchCriteria[this.id].min_amount !== undefined) {
                    this.rangeVal = [this.$store.state.searchCriteria[this.id].min_amount, this.$store.state.searchCriteria[this.id].max_amount];
                }
            }
            this.getMinAmount();
            this.getMaxAmount();
            this.updateSearchCriteriaDefault();
        }

        bus.$on('reset', this.reset);
        // this.updateSearchCriteriaDefault();
        // this.updateSearchCriteria();
        this.initEvents();
    },
    methods: {
        ...mapActions([
            'setSearchCriteria',
            'setSearchCriteriaDefault',
            'setSearchFieldErrors',
            'setLookup'
        ]),

        serverlessFallback (callback) {
            // try serverless mode for just this component

            if (!this.serverless) {
                this.serverless = true;
                this.setData(callback);
            } else {
                // this.error = this.lookupError;
                this.setSearchFieldErrors({
                    field: this.id,
                    message: this.lookupError,
                    lookupFailed: true,
                    preventSearch: false
                });
                // this.$refs.input.placeholder = 'failed to load data';
                this.loading = false;
                this.failedToLoad = true;

                console.error('%c' + this.id + '%c service: error\nno static data source exists', window.consoleStyle.error, window.consoleStyle.reset);
            }
        },

        setData (callback, thisId, url) {
            var self = this;
            self.xhr = new XMLHttpRequest();

            if (!thisId) {
                thisId = this.id;
            }

            let loadData = () => {
                    self.xhr.open('GET', url, true);
                    self.xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                    self.xhr.onload = () => {
                        if (!self.serverless) {
							gtag('event', 'timing_complete', {
								'name' : url,
								'value' :  'lookup-' + thisId,
								'event_category' : 'Lookup Service - ' + this.title
							});
                        }

                        if (self.xhr.status === 200) {
                            if (self.xhr.response.indexOf('<!DOCTYPE html') === 0) {
                                // service error
                                if (!this.serverless) {
                                    console.warn('%c' + thisId + '%c service: error\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);
									gtag('event', 'exception', {
										'description': thisId + ' service error',
										'fatal': false
									});
                                }

                                this.serverlessFallback(callback);
                            } else {
                                self.data = JSON.parse(self.xhr.response);

                                if (self.data) {
                                    this.setLookup({
                                        field: thisId,
                                        data: copyData(self.data)
                                    });
                                    self.defaultValues = [self.data.min_award_amount, self.data.max_award_amount];
                                    self.rangeVal = [self.data.min_award_amount, self.data.max_award_amount];
                                    self.getMinAmount();
                                    self.getMaxAmount();

                                    // if (callback) {
                                    //     this.$nextTick(() => {
                                    //         callback();
                                    //     });
                                    // }
                                    self.updateSearchCriteria();
                                    self.updateSearchCriteriaDefault();

                                    self.loading = false;
                                } else {
                                    if (!self.serverless) {
                                        console.warn('%c' + thisId + '%c service: returned data in an unexpected format\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);
										gtag('event', 'exception', {
											'description': thisId + ' service error',
											'fatal': false
										});
                                    }

                                    self.serverlessFallback(callback);
                                }
                            }
                        } else {

                            if (!self.serverless) {
                                console.warn('%c' + thisId + '%c service: ' + self.xhr.status + ' error\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);
								
								gtag('event', 'exception', {
										'description': thisId + ' service error',
										'fatal': false
									});									
                            }

                            self.serverlessFallback(callback);
                        }

                        self.isLoaded = true;
                    };

                    self.xhr.onerror = () => {
                        if (!self.serverless) {
                            console.warn('%c' + thisId + '%c service: returned data in an unexpected format\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);
							
							gtag('event', 'exception', {
										'description': thisId + ' service error',
										'fatal': false
									});
									
                            self.serverlessFallback(callback);
                        } else {
                            self.loading = false;
                            // this.error = this.lookupError;

                            self.failedToLoad = true;

                            self.setSearchFieldErrors({
                                field: thisId,
                                message: self.lookupError,
                                lookupFailed: true,
                                preventSearch: false
                            });
                        }
                    };

                    self.xhr.send();
                };

            self.loading = true;

            // check for cached data before hitting service again
            if (!self.dynamicLookup && self.$store.state.lookup[thisId].length !== 0) {
                self.data = copyData(self.$store.state.lookup[thisId]);
                self.filteredData = copyData(self.data);

                if (callback) {
                    self.$nextTick(() => {
                        callback();
                    });
                }
            } else {
                if (window.serverless || this.serverless) {
                    url = '/data/lookup-' + window.snakeToCamel(thisId) + '.json';
                } else {
                    // convert id to camelCase for lookup service
                    url = '/services/Lookup/' + window.snakeToCamel(thisId);

                    if (self.searchParams) {
                        url += self.searchParams;
                    }                   
                }

                self.xhr.abort();

                loadData();
            }


        },

        formatTooltip (val) {
            if (window && window.filters) {
                return window.filters.formatCurrency(val, true);
            } else {
                return val;
            }
        },
        
        getMinAmount () {
            if (this.rangeVal[0] === 0) {
                this.minAmount = '$0';
            } else {
                this.minAmount = window.filters.formatCurrency(this.rangeVal[0], false);
            }
            return this.minAmount;
        },
        
        getMaxAmount () {
            if (this.rangeVal[1] === 0) {
                this.maxAmount = '$0';
            } else {
                this.maxAmount = window.filters.formatCurrency(this.rangeVal[1], false);
            }
            return this.maxAmount;
        },
        
        initEvents () {
            var container = document.getElementById('field-' + this.id + '-container'),
                inputArr = container.querySelectorAll('input[type=text]'),
                self = this;

            for (let i = 0; i < inputArr.length; i++) {
                inputArr[i].addEventListener('keydown', (event) => {
                    self.handleInputEvent(event);
                });

                inputArr[i].addEventListener('paste', (event) => {
                    self.handleBlurCopyEvent(event);
					
					gtag('event','Keyboard', {
						'event_category': 'Search Field',
						'event_label': 'Paste - ' + inputArr[i].id,
						'value': inputArr[i].id
					});	
                });

                inputArr[i].addEventListener('cut', (event) => {
                    self.handleInputEvent(event);

					gtag('event','Keyboard', {
						'event_category': 'Search Field',
						'event_label': 'Cut - ' + inputArr[i].id,
						'value': inputArr[i].id
					});	
					
                });

                inputArr[i].addEventListener('copy', (event) => {
                    self.handleInputEvent(event);

					gtag('event','Keyboard', {
						'event_category': 'Search Field',
						'event_label': 'Copy - ' + inputArr[i].id,
						'value': inputArr[i].id
					});	
                });

                inputArr[i].addEventListener('blur', (event) => {
                    this.handleBlurCopyEvent(event);
                });
            }
            setTimeout(() => {
                this.getMinAmount();
                this.getMaxAmount();
            }, 1);
        },

        parseNumber (num) {
            return Number(num.toString().replace(/[$,]/g, ''));
        },

        reformatNumber (num) {
            let number = this.parseNumber(num);

            return '$' + addCommas(number);
        },

        handleBlurCopyEvent (event) {
            let value = '',
                isMin = false,
                self = this;

            if (event && event.type === 'paste') {
                value = (event.clipboardData || window.clipboardData).getData("text");
                isMin = event.target.getAttribute('id').indexOf('max') === -1
            } else {
                value = event.target.value;
                isMin = event.target.id.indexOf('max') === -1
            }
            
            setTimeout(() => {
                if (isNaN(self.parseNumber(value))) {
                    if (isMin) {
                        event.target.value = self.reformatNumber(self.rangeVal[0].toString());
                    } else {
                        event.target.value = self.reformatNumber(self.rangeVal[1].toString());
                    }
                } else {
                    if (!isMin && self.parseNumber(self.rangeVal[0]) > self.parseNumber(self.rangeVal[1])) {
                        event.target.value = self.reformatNumber(self.rangeVal[0]);
                        self.rangeVal[1] = self.rangeVal[1];
                    } else {
                        self.validateInput(true, event);
                        self.updateSearchCriteria(self.$store.state.searchCriteria[self.id]);
                    }
                }
            }, 601);
        },

        handleInputEvent (event) {
            // this.$refs.input.addEventListener('keydown, paste', (event) => {
            // var key = String.fromCharCode(event.keyCode);

            // if press enter, prevent adding line break
            if (event.keyCode === 13) {
                event.preventDefault();

                //submit search
                this.$parent.$parent.search();
            }

            //stop any entry other than allowed keys
            if (this.allowedKeys.indexOf(event.keyCode) === -1) {
                return false;
            }



            let isMin = event.target.id.indexOf('max') === -1;

            //don't parse input for ignored keys
            if (this.ignoreKeys.indexOf(event.keyCode) === -1) {
                this.previousValue = event.target.value;

                if (this.parseNumber(this.previousValue).toString().length >= this.maxNumberLength && event.keyCode !== this.keyBackspace && event.keyCode !== this.keyDelete && (event.target.selectionStart === event.target.selectionEnd)) {
                    event.preventDefault();

                    return false;
                }

                this.cursorPos = event.target.selectionStart;

                // set timeout so that value is udpated before parsing string
                clearTimeout(this.validationTimeout);

                this.validationTimeout = setTimeout(() => {
                    if (isMin) {
                        let minVal = this.parseNumber(event.target.value);
                        
                        this.rangeVal[0] = !isNaN(minVal) ? minVal : '$0';
                        this.newValue = this.getMinAmount();
                    } else {
                        let maxVal = this.parseNumber(event.target.value);
                        
                        this.rangeVal[1] = !isNaN(maxVal) ? maxVal : this.parseNumber(this.maxAmount);
                        this.newValue = this.getMaxAmount();
                    }

                    if (this.cursorPos === 1 && event.target.value.indexOf('$') === -1) {
                        event.target.value = this.previousValue;
                        event.target.setSelectionRange(this.cursorPos, this.cursorPos);

                        return false;
                    }

                    let previousValueLength = this.parseNumber(this.previousValue).toString().length,
                        newValueLength = this.parseNumber(this.newValue).toString().length

                    if (previousValueLength < newValueLength) {
                        if (newValueLength > this.maxNumberLength) {
                            this.newValue = this.previousValue;
                        }
                    }

                    //check cursor position in input field
                    this.cursorPos = event.target.selectionStart;

                    //check number of commas in front of the cursor before parsing
                    this.commaCount = (this.previousValue.substr(0, this.cursorPos).match(/,/g) || []).length;

                    //check if deleted character was comma
                    if (this.parseNumber(this.previousValue) === this.parseNumber(this.newValue)) {
                        switch (event.keyCode) {
                            case this.keyBackspace:
                                //remove character in front of comma
                                this.newValue = this.newValue.slice(0, this.cursorPos - 1) + this.newValue.slice(this.cursorPos, this.newValue.length);

                                //move cursor to correct position
                                this.cursorPos--;

                                break;
                            case this.keyDelete:
                            // case 46:
                                //remove character after of comma
                                this.newValue = this.newValue.slice(0, this.cursorPos) + this.newValue.slice(this.cursorPos + 2, this.newValue.length);

                                //move cursor to correct position
                                // this.cursorPos++;

                                break;
                        }
                        // newValue = event.target.val();
                    }

                    if (isMin) {
                        if (this.parseNumber(this.newValue) > this.rangeVal[1]) {
                            this.newValue = this.rangeVal[1].toString();
                        }
                    } else {
                        if (this.parseNumber(this.newValue) < this.rangeVal[0]) {
                            this.newValue = this.rangeVal[0].toString();
                        } else if (this.parseNumber(this.newValue) > this.options.max) {
                            this.newValue = this.options.max.toString();
                        }
                    }

                    //update commas in number based on new number length
                    this.newValue = this.reformatNumber(this.newValue);

                    if (isMin) {
                        this.minAmount = this.newValue;
                    } else {
                        this.maxAmount = this.newValue;
                    }

                    event.target.value = this.newValue;

                    setTimeout(() => {
                        this.cursorPosNew = this.cursorPos;
                        //check if newly added or removed commas should affect the cursor position
                        //so that user will type in the same place after the comma is added or removed
                        if (this.commaCount < (this.newValue.substr(0, this.cursorPos).match(/,/g) || []).length) {
                            //move cursor forward one character
                            this.cursorPosNew++;

                        } else if (this.commaCount > (this.newValue.substr(0, this.cursorPos).match(/,/g) || []).length) {
                            //move cursor backward one character
                            this.cursorPosNew--;
                        }

                        //fixes cursor jumping when typing a number directly before comma
                        if (this.newValue.charAt(this.cursorPos) === ',') {
                            this.cursorPosNew++;
                        }

                        this.cursorPos = this.cursorPosNew;

                        //move cursor to new position
                        event.target.setSelectionRange(this.cursorPos, this.cursorPos);
                    }, 1);

                    this.validateInput(true, event);

                    this.updateSearchCriteria(this.$store.state.searchCriteria[this.id]);
                }, 501);
            }
        },

        validateInput (fromInputBox, event) {
            var data,
                inputObj = fromInputBox ? event.target : null,
                validationExp = (this.matchingStr ? new RegExp(this.matchingStr, 'ig') : ''),
                minAmnt = (this.minAmount) ? parseInt(this.minAmount.replace(validationExp,'')) : this.rangeVal[0],
                maxAmnt = (this.maxAmount) ? parseInt(this.maxAmount.replace(validationExp,'')) : this.rangeVal[1],
                tempRangeVal = [],
                curVal = fromInputBox ? parseInt(inputObj.value.replace(validationExp,'')) : null;

            if (fromInputBox) {
                if (inputObj.id.indexOf('min') !== -1) {
                    if (curVal > maxAmnt || curVal > this.max) {
                        inputObj.value = this.getMinAmount();
                        return false;
                    } else {
                        tempRangeVal.push(curVal);
                        tempRangeVal.push(parseInt(this.maxAmount.replace(validationExp,'')));
                        setTimeout(() => {
                            inputObj.value = this.getMinAmount();
                        }, 1);
                    }
                } else {
                    if (curVal < minAmnt || curVal > this.max) {
                        inputObj.value = this.getMinAmount();
                        return false;
                    } else {
                        tempRangeVal.push(parseInt(this.minAmount.replace(validationExp,'')));
                        tempRangeVal.push(curVal);
                        setTimeout(() => {
                            inputObj.value = this.getMaxAmount();
                        }, 1);
                    }
                }

                this.rangeVal = tempRangeVal;
            } else {
                setTimeout(() => {
                    this.minAmount = this.getMinAmount();
                    this.maxAmount = this.getMaxAmount();

                    // console.log(this.rangeVal);
                    this.updateSearchCriteria();
                }, 101)
            }
        },

        reset () {
            this.rangeVal = copyData(this.$store.state.searchCriteriaDefault[this.id]);

            // console.log(this.rangeVal);

            this.minAmount = this.getMinAmount();
            this.maxAmount = this.getMaxAmount();
            // this.$store.state.searchCriteria[this.id] =  this.$store.state.searchCriteriaDefault[this.id];

            this.updateSearchCriteria();
            if (this.$store.state.editSearchCriteria[this.id]) {
                this.$store.state.editSearchCriteria[this.id] = copyData(this.$store.state.searchCriteria[this.id])
            }
        },

        clearText () {
            this.rangeVal = [];
            this.$store.state.searchCriteria[this.id] = '';
        },

        updateSearchCriteriaDefault () {
            if (this.defaultValues) {
                this.setSearchCriteriaDefault({
                    field: this.id,
                    data: copyData(this.defaultValues)
                });
            }
        },

        updateSearchCriteria (data) {
            let min,
                max;

            // prevent min from excceding max
            if (this.rangeVal[0] > this.defaultValues[1]) {
                this.rangeVal[0] = this.defaultValues[1];
                this.minAmount = this.getMinAmount();
            }

            // prevent max from excceding max
            if (this.rangeVal[1] > this.defaultValues[1]) {
                this.rangeVal[1] = this.defaultValues[1];
                this.maxAmount = this.getMaxAmount();
            }

            if (this.rangeVal[0] === this.defaultValues[0] && this.rangeVal[1] === this.defaultValues[1]) {
                min = null;
                max = null;
            } else {
                min = this.rangeVal[0];
                max = this.rangeVal[1];
            }

            this.setSearchCriteria({
                field: this.id,
                data: {
                    'min_amount': min,
                    'max_amount': max,
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.slider {
    width: 100% !important;
}
.field-title {
    margin-bottom: 0.5em;
    font-size: 18px;
    font-weight: 400;
    overflow: visible;
    color: #212529;
}
.inline {
    display: inline-block;
    width: calc(50% - 2px);
    margin-bottom: 10px;
    &:first-child {
        margin-right: 4px;
    }
    input {
        width: 100%;
        overflow: hidden;

        padding: 0.45rem 0.5rem;
        width: 100%;
        height: auto;
        min-height: calc(2.875rem + 2px);
        max-height: calc((2.875rem + 2px) * 3);
        background-color: #ececec;
        color: #495057;
        border-radius: 5px;
        border: none;
        box-shadow: $field-shadow;
        font-size: 15px;
        transition: 0.3s;

        &:focus {
            border-color: #1998fd;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(1, 84, 150, 0.25);
        }
    }
    .subtitle {
        font-size: 14px;
    }
}

.loader {
    $size: 18px;

    display: inline-block;
    position: relative;
    top: 3px;
    left: 6px;

    // negative margin prevents title shifting around
    // when loader is added and removed
    margin-top: -10px;
    width: $size;
    height: $size;
}

.loading-failed {
    .field-title {
        color: $grey-med;
    }

    .field-wrapper {
        opacity: 0.25;
    }
}

</style>