<template>
    <div class="search-field multiselect" :class="failedToLoad ? 'loading-failed' : ''">
        <label class="field-title" :for="'field-' + id">
            <template v-if="title">{{title}}</template>
			<field-tooltip v-if="tooltipText" :text="tooltipText" :urlKeys="urlKeys" />
            <span
                class="field-selected-count"
                v-if="$store.state.searchCriteria[id] && $store.state.searchCriteria[id].length !== 0 && filterSelected($store.state.searchCriteria[id]).length > 0"
            >
                {{filterSelected($store.state.searchCriteria[id]).length}}
                <button
                    class="multiselect__remove_all"
                    @click.stop="deselectAll()"
                >
                    <i class="fas fa-times svg"></i><span class="sr-only">X</span>
                </button>
            </span>
        </label>
        <div class="loader" v-if="loading"></div>
        <div class="field-wrapper">
            <div
                class="input-group"
                @click="focusInput()"
                :class="(!showDropdown ? 'inactive' : '') + ' ' + (hasFocus ? 'focused' : '')"
                ref="inputGroupWrapper"
            >
                <!-- <button
                    class="multiselect__tag__remove_all"
                    v-show="$store.state.searchCriteria[id] && $store.state.searchCriteria[id].length !== 0 && hasFocus"
                    @click.stop="deselectAllButton()"
                    ref="buttonRemoveAll"
                >
                    <i class="fas fa-times svg"></i>
                    <span class="sr-only">Remove All</span>
                </button> -->
                <div class="multiselect__input-group form-control-lg" :class="{'active': showDropdown, 'disabled': (dependentField && dependentFieldHasSelectedData)}" ref="inputGroup">
                    <!-- <div class="loader"></div> -->
                    <!-- <ul class="multiselect__tags" v-if="selected"> -->
					<i v-if="$store.state.searchCriteria[id] && $store.state.searchCriteria[id].length !== 0 && !hasFocus"
                        class="fas fa-times multiselect__expand"></i>

					<i v-if="$store.state.searchCriteria[id] && $store.state.searchCriteria[id].length !== 0 && !hasFocus" class="fas fa-ellipsis-h multiselect__expand"></i>
                    <ul
                        class="multiselect__tags"
                        v-if="$store.state.searchCriteria[id]"
                    >
                        <li v-for="item, ind in filterSelected($store.state.searchCriteria[id])" :key="id + '-selected-' + ind + '-' + item.value" :data-value="item.value" class="multiselect__tag">
                            {{item.abbreviation || item.display}}
                            <button class="multiselect__tag__remove" @click="clearTag(item)">
                                <i class="fas fa-times svg"></i>
                                <span class="sr-only">Remove</span>
                            </button>
                        </li>
                    </ul>
                    <input
                        type="text"
                        autocomplete="off"
                        class="form-control form-control-lg multiselect__input"
                        :aria-label="title"
                        :id="'field-' + id"
                        ref="input"
                        v-model="searchText"
                        :disabled="(!data && !dynamicLookup) || (loading && dependentField && !dependentFieldHasData) || (dependentField && dependentFieldHasSelectedData)"
                        :placeholder="$store.state.searchCriteria[id] && ($store.state.searchCriteria[id] === '' || $store.state.searchCriteria[id].length === 0) ? placeholder : ''"
                    >
                </div>
            </div>
            <div
                v-show="data && showDropdown"
                class="multiselect__dropdown"
                :class="{'wide': ui && ui.dropdownWidth, 'pb-0': id == 'fiscal_years'}"
                ref="dropdown"
            >
                <!-- v-if="data" -->
                <div
                    class="multiselect__dropdown_scroll"
                    :class="loading ? 'multiselect__dropdown_scroll_loading' : ''"
                    ref="dropdownScroll"
                >
                    <!-- v-if="data" -->
                    <multi-select-list
                        v-if="data"
                        :id="id"
                        :data="filterList(data)"
                        :topParent="this"
                        ref="multiselect"
                    />
                    <button v-if="id !== 'fiscal_years'"
                        class="btn multiselect__select_all"
                        :class="($store.state.searchCriteria[id] && $store.state.searchCriteria[id].length) === (data && data.length) ? 'multiselect__deselect_all' : ''"
                        @click.stop="selectDeselectAll()"
                        @keydown.stop="handleKeyboardEvents"
                        ref="selectAllButton"
                    >
                        <!-- @focus="focusSelectAllButton" -->
                        {{($store.state.searchCriteria[id] && $store.state.searchCriteria[id].length) === (data && data.length) ? 'Deselect All' : 'Select All'}}

                    </button>
                </div>
            </div>
        </div>
        <control-list
            v-if="additionalControl"
            :id="id"
            :topParent="this"
            ref="multiselect"
        />

        <div class="field-help-text" v-if="helpTextDisplay" ref="helpText">
            {{helpTextDisplay}}
        </div>
        <field-error
            v-if="$store.state.searchFieldErrors[id]"
            v-show="$store.state.searchFieldErrors[id].message"
            :message="$store.state.searchFieldErrors[id].message"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MultiSelectList from '@/components/searchFields/MultiSelectList.vue';
import ControlList from '@/components/searchFields/ControlList.vue';
import FieldError from '@/components/searchFields/FieldError.vue';
import FieldTooltip from '@/components/searchFields/FieldTooltip.vue';
import bus from '@/shared/event-bus.js';
import { toRaw } from "vue";

export default {
    name: 'MultiSelect',
    components: {
        MultiSelectList,
        ControlList,
        FieldError,
        FieldTooltip
    },
    props: {
        title: {
            required: true
        },
        id: {
            required: true
        },
        idLookup: {
            required: false
        },
        idFreeText: {
            required: false
        },
        fields: {
            required: false
        },
        placeholder: {
            required: false
        },
        type: {
            required: false
        },
        defaultValues: {
            required: false
        },
        maxSelection: {
            required: false
        },
        helpText: {
            required: false
        },
        dependentField: {
            required: false
        },
        dependingField: {
            required: false
        },
        buildHelpText: {
            required: false
        },
        urlKeys: {
            required: false
        },
        select: {
            required: false
        },
        deselect: {
            required: false
        },
        change: {
            required: false
        },
        ui: {
            required: false
        },
        dynamicLookup: {
            required: false
        },
        allowFreeText: {
            required: false
        },
        additionalControl: {
            required: false
        },
        matchingStr: {
            required: false
        },
        bus: {
            required: false
        },
        tooltipText: {
            required: false
        }
    },
    computed: {
        ...mapGetters([
            'searchCriteria',
            'editSearchCriteria',
            'searchFieldErrors',
            'editSearchResults'
        ]),
        dependentFieldHasSelectedData: function () {
            let dependentFieldId = window.searchFieldSpecs[this.dependentField].id;

            if (this.dynamicLookup && (!this.$store.state.searchCriteria[dependentFieldId] || this.$store.state.searchCriteria[dependentFieldId].length === 0)) {
                return true;
            }
            else if (this.matchingStr) {
                var matchExp = new RegExp(this.matchingStr),
                    noAllowedYearFound = false;

                if (this.$store.state.searchCriteria[dependentFieldId] && this.$store.state.searchCriteria[dependentFieldId].length > 0) {
                    for (let i = 0; i < this.$store.state.searchCriteria[dependentFieldId].length; i++) {
                        if (!matchExp.test(this.$store.state.searchCriteria[dependentFieldId][i].value)) {
                            noAllowedYearFound = true;
                            break;
                        }
                    }
                } else {
                    noAllowedYearFound = true;
                }
                return noAllowedYearFound;
            }
            return false;
        }
    },
    data: function () {
        return {
            serverless: false,
            init: false,
            data: null,
            loading: false,
            xhr: null,
            failedToLoad: false,
            filteredData: null,
            // selected: [],
            searchText: '',
            searchTextPrevious: '',
            selectedStatePrevious: [],
            searchParams: null,
            searchTimeout: null,
            showDropdown: false,
            dropdownScrollTimeout: null,
            hasFocus: false,
            focusedEl: null,
            // firstMatchingEl: null,
            dropdownFocused: false,
            openCloseTimer: null,
            allowedCharacters: /[a-zA-Z0-9-_ ]/,
            activeListItemIndex: null,
            // error: null,
            lookupError: 'unable to load data',
            dependentFieldHasData: false,
            freeTextId: this.idFreeText || (this.id + '__free_text'),
            // freeTextId: (this.idFreeText || this.id) + '__free_text',
            // freeTextId: this.id + '__free_text',

            // assigned here so that it can be overwritten below
            // otherwise throws error in Vue
            helpTextDisplay: this.helpText ? this.helpText : null
        };
    },
    mounted () {
        // initialize field in store so that it will be watched
        if (!this.$store.state.searchCriteria[this.id]) {
            if (!this.defaultValues) {
               this.$store.state.searchCriteria[this.id] = [];
            } else {
               this.$store.state.searchCriteria[this.id] = [];
            }
        }

        if (this.allowFreeText && !this.$store.state.searchCriteria[this.freeTextId]) {
           this.$store.state.searchCriteria[this.freeTextId] = [];
        }

        if (!this.$store.state.searchCriteriaDefault[this.id]) {
           this.$store.state.searchCriteriaDefault[this.id] = [];
        }

        if (this.allowFreeText && !this.$store.state.searchCriteriaDefault[this.freeTextId]) {
           this.$store.state.searchCriteriaDefault[this.freeTextId] = '';
        }

        if (!this.$store.state.searchFieldErrors[this.id]) {
           this.$store.state.searchFieldErrors[this.id] = [];
        }

        if (!this.$store.state.lookup[this.id]) {
           this.$store.state.lookup[this.id] = [];
        }

        if (!this.dynamicLookup) {
            // pull full lookup list at start
            this.setData(this.build);
        } else {
            // do not pull lookup data if dynamic field
            if (this.dependentField && this.$store.state.editSearchResults) {
                setTimeout(() => {
                    this.updateDependingField();
                }, 1001)
            } else {
                this.build();
            }

        }

        if (this.allowFreeText && this.$store.state.searchCriteria[this.freeTextId] && this.$store.state.searchCriteria[this.id].length === 0) {
            // repopulate searchText to user value if free text was entered
            // if (this.idFreeText) {
            //     this.searchText = this.$store.state.searchCriteria[this.idFreeText];
            // } else {
            this.searchText = this.$store.state.searchCriteria[this.freeTextId];
            // }
        }

        bus.$on('reset', this.reset);

        if (this.$refs.buttonRemoveAll) {
            this.$refs.buttonRemoveAll.style.marginRight = window.scrollbarWidth + 'px';
        }
        bus.$on('update:freeText', () => {
            this.searchText = this.$store.state.searchCriteria[this.freeTextId];
            this.setSearchFieldErrors({
                field: this.id
            });
        });
    },
    methods: {
        ...mapActions([
            'setSearchCriteria',
            'setEditSearchCriteria',
            'setSearchCriteriaDefault',
            'setSearchFieldErrors',
            'setLookup'
        ]),
        setData (callback, thisId, url) {
            this.xhr = new XMLHttpRequest()
            if (!thisId) {
                if (this.idLookup) {
                    thisId = this.idLookup;
                } else {
                    thisId = this.id;
                }

                // console.log('thisId', thisId);
            }

            let loadData = () => {
                    this.xhr.open('GET', url, true);
                    this.xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                    this.xhr.onload = () => {
                        if (!this.serverless) {
							gtag('event', 'timing_complete', {
								'name' : url,
								'value' :  'lookup-' + thisId,
								'event_category' : 'Lookup Service - ' + this.title
							});							
                        }

                        if (this.xhr.status === 200) {
                            if (this.xhr.response.indexOf('<!DOCTYPE html') === 0) {
                                // service error
                                if (!this.serverless) {
                                    console.warn('%c' + thisId + '%c service: error\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);
									gtag('event', 'exception', {
										description: thisId + ' service error',
										'fatal': false
									});
                                }

                                this.serverlessFallback(callback);
                            } else {
                                this.data = JSON.parse(this.xhr.response);

                                if (this.data.length !== 0 && this.data[0].display !== undefined && this.data[0].value !== undefined) {
                                    this.filteredData = copyData(this.data);

                                    // this.data.sort((a, b) => {
                                    //     let textA = a.display.toLowerCase(),
                                    //         textB = b.display.toLowerCase();

                                    //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                    // });

                                    this.setLookup({
                                        field: thisId,
                                        data: copyData(this.data)
                                    });

                                    if (callback) {
                                        this.$nextTick(() => {
                                            callback();
                                        });
                                    }
                                } else if (this.dynamicLookup) {
                                    if (callback) {
                                        this.$nextTick(() => {
                                            callback();
                                        });
                                    }
                                } else {
                                    if (!this.serverless) {
                                        console.warn('%c' + thisId + '%c service: returned data in an unexpected format\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);
										
										gtag('event', 'exception', {
											'description': thisId + ' service error',
											'fatal': false
										});
                                    }

                                    this.serverlessFallback(callback);
                                }
                            }
                        } else {

                            if (!this.serverless) {
                                console.warn('%c' + thisId + '%c service: ' + this.xhr.status + ' error\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);
								
								gtag('event', 'exception', {
											'description': thisId + ' service error',
											'fatal': false
								});
                            }

                            this.serverlessFallback(callback);
                        }
                    };

                    this.xhr.onerror = () => {
                        if (!this.serverless) {
                            console.warn('%c' + thisId + '%c service: returned data in an unexpected format\nfalling back to static data source', window.consoleStyle.warn, window.consoleStyle.reset);
							
							gtag('event', 'exception', {
								'description': thisId + ' service error',
								'fatal': false
							});								

                            this.serverlessFallback(callback);
                        } else {
                            this.loading = false;
                            // this.error = this.lookupError;

                            this.failedToLoad = true;

                            this.setSearchFieldErrors({
                                field: thisId,
                                message: this.lookupError,
                                lookupFailed: true,
                                preventSearch: false
                            });
                        }
                    };

                    this.xhr.send();
                };

            this.loading = true;

            // check for cached data before hitting service again
            if (!this.dynamicLookup && this.$store.state.lookup[thisId].length !== 0) {
                this.data = copyData(this.$store.state.lookup[thisId]);
                this.filteredData = copyData(this.data);

                if (callback) {
                    this.$nextTick(() => {
                        callback();
                    });
                }
            } else {
                if (window.serverless || this.serverless) {
                    url = '/data/lookup-' + window.snakeToCamel(thisId) + '.json';
                } else {
                    // convert id to camelCase for lookup service
                    url = '/services/Lookup/' + window.snakeToCamel(thisId);

                    if (this.searchParams) {
                        url += this.searchParams;
                    }                    
                }

                this.xhr.abort();

                loadData();
            }


        },

        reset () {
            this.searchText = '';
        },

        serverlessFallback (callback) {
            // try serverless mode for just this component

            if (!this.serverless) {
                this.serverless = true;
                this.setData(callback);
            } else {
                // this.error = this.lookupError;
                this.setSearchFieldErrors({
                    field: this.id,
                    message: this.lookupError,
                    lookupFailed: true,
                    preventSearch: false
                });
                // this.$refs.input.placeholder = 'failed to load data';
                this.loading = false;
                this.failedToLoad = true;

                console.error('%c' + this.id + '%c service: error\nno static data source exists', window.consoleStyle.error, window.consoleStyle.reset);
            }
        },

        setSelections (selections) {
            let selected = [];

            for (let i = 0; i < selections.length; i++) {
                let select = this.data.find((item) => {
                        if (item.value === selections[i]) {
                            return item;
                        }
                    });

                if (select) {
                    selected = selected.concat(select);
                }
            }

            this.updateSearchCriteria(selected);
        },

        pollForDependentField (recursionCount) {
            let dependentFieldId = window.searchFieldSpecs[this.dependentField].id,
                dependentFieldLookupData = this.$store.state.lookup[dependentFieldId];

            if (dependentFieldLookupData && dependentFieldLookupData.length !== 0) {
                this.dependentFieldHasData = true;
                this.loading = false;
            } else {
                if (recursionCount === undefined) {
                    recursionCount = 0;
                }

                if (recursionCount < 120 && !(this.$store.state.searchFieldErrors[dependentFieldId] && this.$store.state.searchFieldErrors[dependentFieldId].lookupFailed)) { // time out after 2 minutes
                // if (recursionCount < 180) { // time out after 3 minutes
                // if (recursionCount < 300) { // time out after 5 minutes
                // if (recursionCount < 5) { // time out after 5 seconds
                    setTimeout(() => {
                        this.pollForDependentField(recursionCount + 1);
                    }, 1000);
                } else {
                    // this.error = 'unable to load associated ' + this.dependentField + ' data';
                    this.setSearchFieldErrors({
                        field: this.id,
                        message: 'unable to load associated ' + this.dependentField + ' data',
                        preventSearch: false
                    });

                    this.failedToLoad = true;
                    this.loading = false;
                }
            }
        },

        build () {
            var multiSelectData,
                dynamicMultiSelectObj = [];

            if (this.$store.state.editSearchResults) {
                if (this.$store.state.editSearchCriteria[this.id] && this.$store.state.editSearchCriteria[this.id].length !== 0) {
                    switch (this.id) {
                        case 'fiscal_years':
                            this.setSelections(this.$store.state.editSearchCriteria[this.id]);
                            let currYear = this.$store.state.editSearchCriteria[this.id];

                            this.$refs.dropdown.querySelector('li[data-value="all"]').classList.add('selected');
                            if (!currYear || (currYear.length > 0 && (currYear[0] === '' || currYear[0].toLowerCase() === 'all'))) {
                                let options = this.$refs.dropdown.querySelectorAll('li:not([data-value="all"])');

                                for (let i = 0; i < options.length; ++i) {
                                    options[i].querySelector("button").setAttribute("disabled", 'disabled');
                                    options[i].classList.add('disabled');
                                }
                            }
                            break;
                        case 'org_names':
                        case 'org_names_exact_match':
                            multiSelectData = this.$store.state.editSearchCriteria[this.id];

                            for (let i = 0; i < multiSelectData.length; i++) {
                                dynamicMultiSelectObj.push({
                                    "abbreviation": null,
                                    "value": multiSelectData[i],
                                    "display": multiSelectData[i],
                                    "properties": null
                                });
                            }
                            this.updateSearchCriteria(dynamicMultiSelectObj);
                            break;
                        case 'award_types':
                            multiSelectData = this.$store.state.editSearchCriteria[this.id];

                            for (let i = 0; i <multiSelectData.length; i++) {
                                let itemExists = dynamicMultiSelectObj.some((item) => {
                                    if (item && item.value && item.value.indexOf(multiSelectData[i]) !== -1) {
                                        return true;
                                    }
                                });
                                if (!itemExists) {
                                    let itemToBeAdded = this.$store.state.lookup[this.id].filter((item) => {
                                        if (item.value.indexOf(multiSelectData[i]) !== -1) {
                                            return item;
                                        }
                                    });
                                    dynamicMultiSelectObj.push(itemToBeAdded[0]);
                                }
                            }
                            this.updateSearchCriteria(dynamicMultiSelectObj);
                            break;
                        case 'funding_mechanism':
                            multiSelectData = this.$store.state.editSearchCriteria[this.id];
                            let editSearchCriteriaRefined = [];

                            for (let i = 0; i <multiSelectData.length; i++) {
                                let itemExists = dynamicMultiSelectObj.some((item) => {
                                    if (item && item.value && item.value.indexOf(multiSelectData[i]) !== -1) {
                                        return true;
                                    }
                                });
                                if (!itemExists) {
                                    let itemToBeAdded = this.$store.state.lookup[this.id].filter((item) => {
                                        if (multiSelectData[i] === item.value) {
                                            return item;
                                        } else if (item.value.indexOf(';') !== -1 && multiSelectData[i].indexOf(item.value) !== -1) {
                                            return item;
                                        }
                                    });

                                    if (itemToBeAdded && itemToBeAdded.length > 0 && editSearchCriteriaRefined.indexOf(itemToBeAdded[0].value) === -1) {
                                        dynamicMultiSelectObj.push(itemToBeAdded[0]);
                                        editSearchCriteriaRefined.push(itemToBeAdded[0].value);
                                    }
                                }
                            }

                            this.setSearchCriteria({
                                field: this.id,
                                data: copyData(editSearchCriteriaRefined)
                            });
                            this.setEditSearchCriteria({
                                field: this.id,
                                data: copyData(editSearchCriteriaRefined)
                            });
                        default:
                            this.setSelections(this.$store.state.editSearchCriteria[this.id]);
                    }

                    this.updateSearchCriteriaDefault();
                } else {
                    if (this.defaultValues && (!this.$store.state.editSearchCriteria[this.id] || this.$store.state.editSearchCriteria[this.id].length === 0)) {
                        this.updateSearchCriteria([]);
                    }
                }
            }
            else if (this.defaultValues && !this.$store.state.searchCriteria[this.id]) {
                this.setSelections(this.defaultValues);
                this.updateSearchCriteriaDefault();
            } else if (this.id === 'fiscal_years' && this.$store.state.searchCriteria[this.id] ) {
                let currYear = this.$store.state.searchCriteria[this.id],
                    allYearObj = _.filter(currYear, yr => yr.value === 'all'),
                    isAllYearsSel = false;

                if (allYearObj.length > 0) {
                    isAllYearsSel = true;
                }

                if (isAllYearsSel) {
                    this.$refs.dropdown.querySelector('li[data-value="all"]').classList.add('selected');
                    let options = this.$refs.dropdown.querySelectorAll('li:not([data-value="all"])');

                    for (let i = 0; i < options.length; ++i) {
                        options[i].querySelector("button").setAttribute("disabled", 'disabled');
                        options[i].classList.add('disabled');
                    }
                }
            }

            if (this.$refs.input) {
                this.$refs.input.addEventListener('focus', () => {
                    this.setFocus(this.id);

                    if (this.dynamicLookup &&  this.dependentField) {
                        setTimeout(() => {
                            if (this.dependentField) {
                                this.updateDependingField();
                            }
                        });
                    }
                    // this.openDropdown();
                });
                this.$refs.input.addEventListener('blur', () => {
                    setTimeout(() => {
                        this.removeFocus(this.id);
                        // if (this.$store.state.searchCriteria[this.id] && this.$store.state.searchCriteria[this.id].length === 0) {
                        //     this.searchText = '';
                        //     this.updateSearchCriteria([]);
                        // }
                    }, 100);
                });
            }

            if (this.$refs.dropdownScroll) {
                this.$refs.dropdownScroll.addEventListener('scroll', () => {
                    // change focus id on scrolling to keep dropdown from closing
                    this.focusedId = 'dropdownScroll';

                    // clear timeout to prevent event from firing multiple times
                    if (this.dropdownScrollTimeout) {
                        clearTimeout(this.dropdownScrollTimeout);
                    }

                    this.dropdownScrollTimeout = setTimeout(() => {
                        // refocus input so that keyboard events work
                        this.$refs.input.focus();
                    }, 100);
                });
            }

            if ( this.$refs.selectAllButton) {
                this.$refs.selectAllButton.addEventListener('focus', this.focusSelectAllButton);
            }

            if (this.buildHelpText) {
                this.helpTextDisplay = this.buildHelpText();
            }

            if (!this.dependentField) {
                this.loading = false;
            } else {
                this.pollForDependentField();
                // this.$store.state[window.searchFieldSpecs[dependentField].id]
                // window.searchFieldSpecs[dependentField].id
            }

            // setup keyboard events on input field
            if (this.$refs.input) {
                    this.$refs.input.addEventListener('keydown', (event) => {
                    var key = String.fromCharCode(event.keyCode);

                    if (event.keyCode === 13) {
                        // prevent submitting form if navigating dropdown list with keyboard
                        if (this.activeListItemIndex === null) {
                            this.closeDropdown();
                        } else {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    }

                    // check if input is letter, number, hyphen, underscore, or space
                    if (!this.allowedCharacters.test(key)) {
                        switch (event.keyCode) {
                            case 8: // backspace/delete
                            case 9: // tab
                                break;

                            default:
                                // event.preventDefault();
                                this.handleKeyboardEvents(event);
                                break;
                        }
                    }

                    if (this.dynamicLookup && !this.dependentField) {
                        setTimeout(() => {
                            // console.log(this.$refs.input.value);
                            // console.log(this.searchText);

                            // check if user input has changed
                            if (this.searchTextPrevious !== this.searchText) {
                                this.searchTextPrevious = this.searchText;

                                if (this.searchText.length >= 3) {
                                    this.searchParams = '?text=' + encodeURI(this.searchText);

                                    // this.loading = true;
                                    // if (this.showDropdown) {
                                    //     this.closeDropdown({keepFocus: true});
                                    // }

                                    if (this.$store.state.searchCriteria[this.id].length === 0) {
                                        this.updateFreeText(this.searchText);
                                    }

                                    // console.log('search', this.searchText);

                                    if (this.searchTimeout) {
                                        clearTimeout(this.searchTimeout);
                                    }

                                    this.searchTimeout = setTimeout(() => {
                                        this.loading = true;

                                        this.setData(this.dynamicDataLoaded);
                                    }, 800);
                                } else {
                                    if (this.searchTimeout) {
                                        clearTimeout(this.searchTimeout);
                                    }

                                    this.filteredData = null;
                                    this.data = null;

                                    // clear free text if entry is less than 3 characters
                                    this.updateFreeText([]);
                                }
                            }
                        }, 1);
                    }
                });
            }
            //////////////////////////////////
            // this.openDropdown();
        },

        dynamicDataLoaded () {
            this.loading = false;

            if (this.data.length !== 0) {

                // this.firstMatchingEl = this.data[0];
                // reset errors
                this.setSearchFieldErrors({
                    field: this.id
                });

                this.openDropdown();
            } else {
                // console.log(this.data);
                this.closeDropdown({keepFocus: true});

                this.setSearchFieldErrors({
                    field: this.id,
                    message: 'No items match your search terms',
                    lookupFailed: true,
                    preventSearch: false
                });
            }
        },

        filterList (data) {
            if (!this.dynamicLookup) {
                let filteredData = [],
                    addMissingParents = () => {
                        let missingParent;

                        for (let i = 0; i < filteredData.length; i++) {
                            if (filteredData[i].parent_value) {
                                missingParent = filteredData.filter((item) => {
                                    if (filteredData[i].parent_value === item.value) {
                                        return item;
                                    }
                                }).length === 0;

                                if (missingParent) {
                                    filteredData.push(this.getItemByValue(filteredData[i].parent_value));
                                }

                            }
                        }
                    };

                if (this.searchText) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].display.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) {
                            filteredData.push(data[i]);
                        }
                    }

                    addMissingParents();

                    this.filteredData = filteredData;
                    // console.log(1);
                    // this.firstMatchingEl = filteredData[0];

                    return filteredData;
                }
            }

            return data;
        },

        focusInput () {
            if (this.data || this.dynamicLookup) {
                let maxHeight = this.$refs.inputGroupWrapper.offsetHeight * 3,
                    newHeight,
                    // tagsHeight = this.$refs.inputGroup.offsetHeight + 50;
                    tagsHeight = 9999;

                if (this.$refs.inputGroup.offsetHeight > maxHeight) {
                    newHeight = maxHeight;
                } else {
                    newHeight = this.$refs.inputGroup.offsetHeight;
                }

                if (this.$refs && this.$refs.inputGroupWrapper) {
                    this.$refs.inputGroupWrapper.style.height = (newHeight - 2) + 'px';
                }

                if (this.$refs && this.$refs.input) {
                    this.$refs.input.focus();
                }

                if (this.$refs && this.$refs.inputGroup) {
                    this.$refs.inputGroup.scrollTop = tagsHeight;
                }
                // setTimeout(() => {
                // }, 1);

                if (this.searchText && this.searchText.length >= 3) {
                    this.searchParams = '?text=' + encodeURI(this.searchText);

                    this.searchTimeout = setTimeout(() => {
                        this.loading = true;

                        this.setData(this.dynamicDataLoaded);
                    }, 1);
                }

                setTimeout(() => {
                    if (this.openCloseTimer) {
                        clearTimeout(this.openCloseTimer);
                    }

                    this.focusedId = this.id;
                },100);

                setTimeout(() => {
                    this.$refs.inputGroupWrapper.style.height = '';
                    // this.$refs.inputGroup.scrollTop = tagsHeight;
                }, 301);
            }
        },

        blurInput () {
            this.showDropdown = false;

            if (this.$refs.inputGroupWrapper) {
                this.$refs.inputGroupWrapper.style.height = this.$refs.inputGroup.offsetHeight + 'px';
            }

            if (this.$refs && this.$refs.input) {
                this.$refs.input.blur();
            }

            setTimeout(() => {
                if (this.$refs.inputGroupWrapper) {
                    this.$refs.inputGroupWrapper.style.height = '';
                }

                if (this.$refs && this.$refs.inputGroup) {
                    this.$refs.inputGroup.scrollTop = 0;
                }
            }, 1);
        },

        setFocus (id) {
            this.focusedId = id;

            // if (!this.showDropdown) {
            // if (!this.dynamicLookup) {
            this.openDropdown();
            // }
            // }
        },

        removeFocus (id) {
            if (this.allowFreeText && this.searchText.length < 3) {
                this.searchText = '';

                // clear free text if entry is less than 3 characters
                this.updateFreeText([]);
            }

            // prevent searching if user has already clicked
            // out of field before the data returns
            if (this.dynamicLookup && this.xhr) {
                this.xhr.abort();
            }

            // prevent submitting search if user has already clicked
            // out of field before search delay has elapsed
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }

            if (this.openCloseTimer) {
                clearTimeout(this.openCloseTimer);
            }

            this.openCloseTimer = setTimeout(() => {
                if (this.focusedId === id) {
                    this.focusedId = null;

                    //////////////////////////
                    this.closeDropdown();
                }
            }, 100);
        },

        focusSelectAllButton () {
            this.focusedId = 'selectAllButton';

            if (this.openCloseTimer) {
                clearTimeout(this.openCloseTimer);
            }
        },

        focusListItem (whichItem) {
            var listItems = this.$refs.multiselect.$el.getElementsByClassName('multiselect__list__item'),
                listButton,
                toggleButton;

            // console.log(listItems);

            if (this.activeListItemIndex !== null) {
                if (!listItems[this.activeListItemIndex]) {
                    this.activeListItemIndex = 0;
                }

                //deactivate current active list item before reassigning active item index
                // listButton = listItems[this.activeListItemIndex].childNodes[0];
                listButton = listItems[this.activeListItemIndex].getElementsByClassName('multiselect__list__item_button')[0];
                listButton.classList.remove('active');
                listButton.blur();

                // console.log(listItems[this.activeListItemIndex]);
                // console.log(listItems[this.activeListItemIndex].getElementsByClassName('multiselect__list__item_button')[0]);
            }

            switch (whichItem) {
                case 'prev':
                    if (this.activeListItemIndex === 0 || this.activeListItemIndex === null) {
                        this.activeListItemIndex = listItems.length - 1;

                        // listButton = listItems[this.activeListItemIndex].childNodes[0];
                        listButton = listItems[this.activeListItemIndex].getElementsByClassName('multiselect__list__item_button')[0];

                        // scroll active item into view
                        this.$refs.dropdownScroll.scrollTop = this.$refs.dropdownScroll.scrollTop = listButton.offsetTop - this.$refs.dropdownScroll.offsetHeight + listButton.offsetHeight;

                    // } else if (this.activeListItemIndex === null) {
                    //     this.activeListItemIndex = listItems.length;
                    } else {
                        this.activeListItemIndex += -1;

                        // listButton = listItems[this.activeListItemIndex].childNodes[0];
                        listButton = listItems[this.activeListItemIndex].getElementsByClassName('multiselect__list__item_button')[0];

                        // scroll active item into view
                        if (listButton.offsetTop < this.$refs.dropdownScroll.scrollTop) {
                            this.$refs.dropdownScroll.scrollTop = listButton.offsetTop;
                        }
                    }
                    break;
                case 'next':
                    if (this.activeListItemIndex + 1 === listItems.length || this.activeListItemIndex === null) {
                        // wrap around to beginning of list
                        this.activeListItemIndex = 0;

                        // listButton = listItems[this.activeListItemIndex].childNodes[0];
                        listButton = listItems[this.activeListItemIndex].getElementsByClassName('multiselect__list__item_button')[0];

                        // scroll active item into view
                        this.$refs.dropdownScroll.scrollTop = listButton.offsetTop;
                    // } else if (this.activeListItemIndex === null) {
                    //     this.activeListItemIndex = 0;
                    } else {
                        this.activeListItemIndex += 1;

                        // listButton = listItems[this.activeListItemIndex].childNodes[0];
                        listButton = listItems[this.activeListItemIndex].getElementsByClassName('multiselect__list__item_button')[0];

                        // console.log('listButton.offsetTop', listButton.offsetTop);
                        // console.log('listButton.offsetHeight', listButton.offsetHeight);
                        // console.log('this.$refs.dropdownScroll.offsetHeight', this.$refs.dropdownScroll.offsetHeight);
                        // console.log('this.$refs.dropdownScroll.scrollTop', this.$refs.dropdownScroll.scrollTop);

                        // scroll active item into view
                        if (listButton.offsetTop + listButton.offsetHeight > this.$refs.dropdownScroll.offsetHeight + this.$refs.dropdownScroll.scrollTop) {
                            this.$refs.dropdownScroll.scrollTop = listButton.offsetTop - this.$refs.dropdownScroll.offsetHeight + listButton.offsetHeight;
                        }
                    }

                    break;
                case 'expand':
                    toggleButton = listItems[this.activeListItemIndex].getElementsByClassName('multiselect__expand_toggle')[0];

                    if (!toggleButton.classList.contains('expanded')) {
                        toggleButton.click();
                    }

                    listButton = listItems[this.activeListItemIndex].getElementsByClassName('multiselect__list__item_button')[0];

                    break;
                case 'collapse':
                    toggleButton = listItems[this.activeListItemIndex].getElementsByClassName('multiselect__expand_toggle')[0];

                    if (toggleButton.classList.contains('expanded')) {
                        toggleButton.click();
                    }

                    listButton = listItems[this.activeListItemIndex].getElementsByClassName('multiselect__list__item_button')[0];

                    break;
                default:
                    // if it is a number, go to that index
                    if (!isNan(whichItem)) {
                        this.activeListItemIndex = whichItem;
                    }

                    break;
            }


            listButton.classList.add('active');

            this.focusedEl = listButton;


            // listButton.focus();


            // this.$refs.multiselect.$el.childNodes[this.activeListItemIndex].childNodes[0].focus();

            // this.$refs.multiselect.$el.childNodes[this.activeListItemIndex].getElementsByClassName('multiselect__list');
            // this.$refs.multiselect.$el.childNodes[this.activeListItemIndex].getElementsByClassName('multiselect__list__item');
        },

        openDropdown () {
            this.showDropdown = true;
            this.hasFocus = true;

            if (this.openCloseTimer) {
                clearTimeout(this.openCloseTimer);
            }

            this.openCloseTimer = setTimeout(() => {
                if (this.$refs.dropdown) {
                    this.$refs.dropdown.classList.add('open');
                }
            }, 1);
        },

        closeDropdown (options) {
            this.hasFocus = false;

            if (this.$refs.dropdown) {
                this.$refs.dropdown.classList.remove('open');

                if (this.allowFreeText && this.searchText !== '' && this.id === 'org_names') {
                    this.selectItem({
                        abbreviation: null,
                        display: this.searchText,
                        properties: null,
                        value: this.searchText
                    });
                }
                if ((!options || (options && !options.keepFocus)) && (!this.allowFreeText || this.$store.state.searchCriteria[this.id].length !== 0)) {
                    this.searchText = '';
                }

                if (this.openCloseTimer) {
                    clearTimeout(this.openCloseTimer);
                }

                this.openCloseTimer = setTimeout(() => {
                    // this.showDropdown = false;

                    if (!options || (options && !options.keepFocus)) {
                        this.blurInput();
                    }

                    this.activeListItemIndex = null;
                    // this.searchText = '';

                    if (this.dynamicLookup && (!this.allowFreeText || this.$store.state.searchCriteria[this.id].length !== 0)) {
                        this.filteredData = null;
                        this.data = null;
                    }

                    if (this.focusedEl) {
                        this.focusedEl.classList.remove('active');
                        this.focusedEl = null;
                    }
                }, 301);
            } else {
                // this.showDropdown = false;
                this.blurInput();
            }
        },

        clearTag (item) {
            if (this.showDropdown) {
                // if dropdown is open, keep it open by focusing the input
                this.focusInput();
            }

			gtag('event','Click', {
				'event_category': 'Search Field',
				'event_label': 'Clear Tag - ' + this.title + ' - ' + item.value,
				'value': this.title + ' - ' + item.value
			});	

            this.selectDeselect(item);
        },

        handleKeyboardEvents (event) {
            let keyCode = event.keyCode,
                inputFocused = this.id === this.focusedId;

            // console.log(event);

            // use preventDefault inside switch to allow left/right
            // arrows to work inside input as expected
            switch (keyCode) {
                case 37: // left
                    if (this.activeListItemIndex !== null && this.activeListItemIndex !== undefined) {
                        this.focusListItem('collapse');
                    }

                    break;
                case 38: // up
                    event.preventDefault();
                    this.focusListItem('prev');
					
					gtag('event','Keyboard', {
						'event_category': 'Search Field',
						'event_label': 'Up - (Highlight previous dropdown item) - ' + this.title,
						'value': this.title
					});	
					
                    break;
                case 39: // right
                    if (this.activeListItemIndex !== null && this.activeListItemIndex !== undefined) {
                        this.focusListItem('expand');
                    }

                    break;
                case 40: // down
                    event.preventDefault();
                    this.focusListItem('next');

					gtag('event','Keyboard', {
						'event_category': 'Search Field',
						'event_label': 'Down - (Highlight next dropdown item) - ' + this.title,
						'value': this.title
					});	
					
                    break;
                case 9: // tab
                    // if pressing tab from the select all button
                    if (event.target.classList.contains('multiselect__select_all')) {
                        if (!event.shiftKey) {
                            this.closeDropdown();
                        }
                    }

                    // event.preventDefault();
                    // if (event.shiftKey) {
                    //     this.focusListItem('prev');
                    // } else {
                    //     this.focusListItem('next');
                    // }
                    break;
                case 13: // enter
                    // console.log('entered');
                    if (this.focusedEl) {
                        this.focusedEl.click();
						
						gtag('event','Keyboard', {
							'event_category': 'Search Field',
							'event_label': 'Enter - (Select dropdown item) - ' + this.title,
							'value': this.title
						});	
						
                    // } else if (this.firstMatchingEl) {
                    //     this.selectItem(this.firstMatchingEl);
                    //     this.firstMatchingEl = null;
                    }

                    break;
                case 27: // escape
                    this.closeDropdown();
					gtag('event','Keyboard', {
						'event_category': 'Search Field',
						'event_label': 'Escape - (Close dropdown) - ' + this.title,
						'value': this.title
					});	
                    break;
                // default:
                //     console.log(this.$refs.input);
                //     break;
            }
        },

        getItemByValue (value) {
            var item;

            item = this.data.filter((item) => {
                if (value === item.value) {
                    return item;
                }
            });

            return item[0];
        },

        isSelected (value) {
            // if (this.searchCriteria[this.id]) {
            if (this.$store.state.searchCriteria[this.id]) {
                // return this.searchCriteria[this.id].some((item) => {
                return this.$store.state.searchCriteria[this.id].some((item) => {
                    if (item.value === value) {
                        return true;
                    }
                });
            }

            return false;
        },

        filterSelected (selected) {
            var selectDisplayItems = [],
                parent,
                childrenToHide = [],
                allChildrenSelected = (children_values) => {
                    var childrenData = selected.filter((item) => {
                        if (children_values.indexOf(item.value) !== -1) {
                            return item;
                        }
                    });

                    return true;
                };

            for (let i = 0; i < selected.length; i++) {
                if (selected[i] && selected[i].children_values) {
                    childrenToHide = childrenToHide.concat(selected[i].children_values);
                }
            }

            selectDisplayItems = selected.filter((item) => {
                if (item) {
                    if (childrenToHide.indexOf(item.value) === -1) {
                        return item;
                    }
                }
            });

            return selectDisplayItems;
        },

        getChildren (parent, children_values) {
            let childrenData,
                dataSource = this.searchText ? this.filteredData : this.data;

            childrenData = dataSource.filter((item) => {
                if (children_values.indexOf(item.value) !== -1 && item.parent_value === parent.value) {
                    return item;
                }
            });

            return childrenData;
        },

        updateSearchCriteriaDefault () {
            if (this.defaultValues) {
                let defaultItems = [];

                for (let i = 0; i < this.defaultValues.length; i++) {
                    let item = this.data.filter((item) => {
                        if (item.value === this.defaultValues[i]) {
                            return item;
                        }
                    })[0];

                    defaultItems.push(item);
                }

                this.setSearchCriteriaDefault({
                    field: this.id,
                    data: defaultItems
                });
            }
            if (this.additionalControl) {
                for (let i = 0; i < this.additionalControl.options.length; i++) {
                    this.setSearchCriteriaDefault({
                        field: this.additionalControl.options[i].name,
                        data: this.additionalControl.options[i].value
                    });
                }
            }
        },

        updateSearchCriteria (data) {
            this.setSearchCriteria({
                field: this.id,
                data: copyData(data)
            });

            // clear free text if options are selected form the picklist
            this.updateFreeText([]);
        },

        updateFreeText (data) {
            this.setSearchCriteria({
                field: this.freeTextId,
                data: data
            });

            // if (this.idFreeText) {
            //     this.setSearchCriteria({
            //         field: this.idFreeText,
            //         data: data
            //     });
            // }
        },

        selectItem (item) {
            if (!this.isSelected(item.value)) {
                this.setSearchCriteria({
                    field: this.id,
                    add: [item]
                });
            }

            if (this.select) {
                this.select();
            }

            if (this.change) {
                this.change(this.$refs.dropdown);
            }
        },

        deselectItem (item) {
            // this.firstMatchingEl = null;
            this.setSearchCriteria({
                field: this.id,
                remove: [item]
            });

            if (this.deselect) {
                this.deselect();
            }

            if (this.change) {
                this.change(this.$refs.dropdown);
            }
        },

        deselectParents (parent_value) {
            let parent = this.getItemByValue(parent_value),
                siblingValues = parent.children_values,
                siblingsSelectedCount = 0;

            // see how many siblings are selected
            for (let i = 0; i < siblingValues.length; i++) {
                if (this.isSelected(siblingValues[i])) {
                    siblingsSelectedCount++;
                }
            }

            if (siblingsSelectedCount === siblingValues.length) {
                // if all siblings are selected, select parent also
                this.selectItem(parent);
            } else {
                // if not all siblings are selected, deselect parent
                if (this.isSelected(parent_value)) {
                    this.deselectItem(parent);
                }
            }

            if (parent.parent_value) {
                this.deselectParents(parent.parent_value);
            }
        },

        selectChildren (item, children_values) {
            let children = this.getChildren(item, children_values),
                child;

            for (let i = 0; i < children.length; i++) {
                this.selectItem(children[i]);

                child = this.getItemByValue(children[i].value);

                if (child.children_values) {
                    this.selectChildren(child, child.children_values);
                }
            }
        },

        deselectChildren (item, children_values) {
            let children = this.getChildren(item, children_values),
                child;

            for (let i = 0; i < children.length; i++) {
                this.deselectItem(children[i]);

                child = this.getItemByValue(children[i].value);

                if (child.children_values) {
                    this.deselectChildren(child, child.children_values);
                }
            }
        },

        selectDeselect (item) {
            let children,
                $this = this;

            if (this.showDropdown) {
                // if dropdown is open, keep it open by focusing the input
                this.focusedId = 'selectDeselect';

                // reset focus id afer a short timeout
                // to enable dropdown to close properly
                // when navigating using the keyboard
                setTimeout(() => {
                    $this.focusedId = this.id;
                }, 100);

                setTimeout(() => {
                    // refocus input so that keyboard events work
                    $this.$refs.input.focus();
                }, 110);
            }

            if (!this.isSelected(item.value)) {
                // add item
                this.selectItem(item);

                // if selected item has children, select all of them
                if (item.children_values) {
                    this.selectChildren(item, item.children_values);
                }
				
				gtag('event','Keyboard', {
					'event_category': 'Search Field',
					'event_label':  'Select - ' + this.title + ' - ' + item.value,
					'value': item.value
				});							
            } else {
                // remove item
                this.deselectItem(item);

                // if selected item has children, deselect all
                if (item.children_values) {
                    this.deselectChildren(item, item.children_values);
                }

				gtag('event','Click', {
					'event_category': 'Search Field',
					'event_label': 'Deselect - ' + this.title + ' - ' + item.value,
					'value': item.value
				});	
            }

            // check parent-child relation
            if (item.parent_value) {
                this.deselectParents(item.parent_value);
            }

            // clear free text if options are selected form the picklist
            this.updateFreeText([]);

            // this.$nextTick(() => {
            //     this.$refs.input.style.width = ''
            // });
        },

        deselectAllButton () {
            this.focusedId = 'selectAllButton';

            setTimeout(() => {
                // refocus input so that keyboard events work
                this.$refs.input.focus();
            }, 110);

            this.deselectAll();
        },

        selectDeselectAll () {
            if (this.showDropdown) {
                // if dropdown is open, keep it open by focusing the input
                this.focusedId = 'selectAllButton';

                setTimeout(() => {
                    // refocus input so that keyboard events work
                    this.$refs.input.focus();
                }, 110);
            }

            if (this.$store.state.searchCriteria[this.id].length !== this.data.length) {
                this.selectAll();
            } else {
                this.deselectAll();
            }
        },

        selectAll () {
            this.updateSearchCriteria(this.data);

            if (this.select) {
                this.select();
            }

            if (this.change) {
                this.change(this.$refs.dropdown);
            }
			gtag('event','Click', {
				'event_category': 'Search Field',
				'event_label': 'Select All - ' + this.title,
				'value': this.title
			});	
        },

        deselectAll () {
            this.updateSearchCriteria([]);

            if (this.deselect) {
                this.deselect();
            }

            if (this.change) {
                this.change(this.$refs.dropdown);
            }
			
			gtag('event','Click', {
				'event_category': 'Search Field',
				'event_label': 'Select All - ' + this.title,
				'value': this.title
			});	
        },

        updateDependingField () {
            let depFieldId = window.searchFieldSpecs[this.dependentField].id,
                isEdit = false;

            this.searchParams = '';
            var criteria = toRaw(this.$store.state.searchCriteria);

            if (!criteria[depFieldId] || criteria[depFieldId].length === 0 ) {
                criteria = toRaw(this.$store.state.editSearchCriteria);
                isEdit = true;
            }

            if (this.dynamicLookup && this.dependentField) {
                var selectedState = [];
                if (criteria[depFieldId] && criteria[depFieldId].length > 0) {
                    let allStates = this.$store.state.lookup[depFieldId];
                    for (let i = 0; i < criteria[depFieldId].length; i++) {
                        if (!isEdit) {
                            switch (criteria[depFieldId][i].value.toLowerCase()) {
                                case 'all':
                                    for (let sCnt = 0; sCnt < allStates.length; sCnt++) {
                                        if (allStates[sCnt].children_values === null) {
                                            selectedState.push(allStates[sCnt].value);
                                            this.searchParams += (this.searchParams === '' ? '?' : '&') + 'states=' + encodeURI(allStates[sCnt].value);
                                        }
                                    }
                                    break;
                                case 'us_states':
                                case 'us_territories':
                                    let currentState = _.filter(allStates, st => st.value.toLowerCase() === criteria[depFieldId][i].value.toLowerCase())
                                    if (currentState.length > 0) {
                                        //selectedState.push(criteria[depFieldId][i].toLowerCase());
                                        //this.searchParams += (this.searchParams === '' ? '?' : '&') + 'states=' + encodeURI(criteria[depFieldId][i].toLowerCase());
                                        for (let sCnt = 0; sCnt < currentState[0].children_values.length; sCnt++) {
                                                selectedState.push(currentState[0].children_values[sCnt]);
                                                this.searchParams += (this.searchParams === '' ? '?' : '&') + 'states=' + encodeURI(currentState[0].children_values[sCnt]);
                                        }
                                    }
                                    break; 
                            }

                            selectedState.push(criteria[depFieldId][i].value.toLowerCase());
                            this.searchParams += (this.searchParams === '' ? '?' : '&') + 'states=' + encodeURI(criteria[depFieldId][i].value);
                        } else {
                            switch (criteria[depFieldId][i].toLowerCase()) {
                                case 'all':
                                    for (let sCnt = 0; sCnt < allStates.length; sCnt++) {
                                        if (allStates[sCnt].children_values === null) {
                                            selectedState.push(allStates[sCnt].value);
                                            this.searchParams += (this.searchParams === '' ? '?' : '&') + 'states=' + encodeURI(allStates[sCnt].value);
                                        }
                                    }
                                    break;
                                case 'us_states':
                                case 'us_territories':
                                    let currentState = _.filter(allStates, st => st.value.toLowerCase() === criteria[depFieldId][i].toLowerCase())
                                    if (currentState.length > 0) {
                                        selectedState.push(criteria[depFieldId][i].toLowerCase());
                                        this.searchParams += (this.searchParams === '' ? '?' : '&') + 'states=' + encodeURI(criteria[depFieldId][i].toLowerCase());
                                        for (let sCnt = 0; sCnt < currentState[0].children_values.length; sCnt++) {
                                                selectedState.push(currentState[0].children_values[sCnt]);
                                                this.searchParams += (this.searchParams === '' ? '?' : '&') + 'states=' + encodeURI(currentState[0].children_values[sCnt]);
                                        }
                                    }
                                    break; 
                            }
                            selectedState.push(criteria[depFieldId][i]);
                            this.searchParams += (this.searchParams === '' ? '?' : '&') + 'states=' + encodeURI(criteria[depFieldId][i]);
                        }
                    }

                    if (selectedState.join(',') !== this.selectedStatePrevious.join(',')) {
                        this.selectedStatePrevious = copyData(selectedState);

                        //this.searchParams = '?states=' + encodeURI(selectedState.join(','));

                        this.setData(this.dependingDataLoaded);
                    } else {
                        this.data = this.$store.state.lookup[this.id];
                        this.filteredData = copyData(this.data);
                    }
                } else {
                    this.build();
                }
            }
        },

        dependingDataLoaded () {
            this.loading = false;

            if (this.data.length !== 0) {

                // reset errors
                this.setSearchFieldErrors({
                    field: this.id
                });

                if (this.$store.state.editSearchResults) {
                    this.build();
                }
            } else {
                this.setSearchFieldErrors({
                    field: this.id,
                    message: 'No items match your search terms',
                    lookupFailed: true,
                    preventSearch: false
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import '@/scss/variables.scss';
@import 'node_modules/bootstrap/scss/_mixins';

$multiselect-spacing: 0.15rem;
$select-all-btn-height: 30px;

.multiselect {
    position: relative;
}

.field-title {
    margin-bottom: 0.5em;
    color: #212529;
    font-size: 18px;
    font-weight: 400;
    overflow: visible;
}

.input-group {
    overflow: hidden;
    // box-shadow: none;
    box-shadow: $field-shadow;
    // border-radius: $input-radius;
    transition: 0.3s;

    // height: 0;
    height: auto;
    min-height: calc(2.875rem + 2px);
    max-height: calc((2.875rem + 2px) * 3);

    &.inactive {
        // height: 42.25px;
        height: calc(2.875rem + 2px);
        // max-height: calc((2.875rem + 2px) * 1.5);
    }

    &.focused {
        // height: auto;
        border-color: #1998fd;
        box-shadow: 0 0 0 0.2rem rgba(1, 84, 150, 0.25);
    }
}

.form-control {
    background-color: transparent;
}


.multiselect__input-group {
    position: relative;
    // padding: 0.4rem 0.5rem;
    padding: 0.25rem 0.5rem;
    width: 100%;
    height: auto;
    min-height: calc(2.875rem + 2px);
    // max-height: calc((2.875rem + 2px) * 2);
    max-height: calc((2.875rem + 2px) * 3);
    background-color: $grey-lighter;
    border-radius: $input-radius;
    font-size: 15px;
    transition: 0.3s;
    overflow: hidden;
    scroll-behavior: smooth;

    &.active {
        // max-height: calc((2.875rem + 2px) * 3);
        // max-height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        border-color: #1998fd;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 84, 150, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(1, 84, 150, 0.25);

        .multiselect__input {
            // position: relative;
            margin: $multiselect-spacing 0;
            height: 1.6em;
            // line-height: 1em;
        }

        // .multiselect__tags {
        //     position: relative;
        // }
    }
    &.disabled {
        opacity: 0.5;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: 14px;
        font-weight: 500;
        color: #757575;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        font-size: 14px;
        font-weight: 500;
        color: #757575;
    }
    :-ms-input-placeholder { /* IE 10+ */
        font-size: 14px;
        font-weight: 500;
        color: #757575;
    }
    :-moz-placeholder { /* Firefox 18- */
        font-size: 14px;
        font-weight: 500;
        color: #757575;
    }
}

.multiselect__tags {
    display: inline;
    margin: 0;
    padding: 0.5rem 0;
    list-style: none;

    // position: absolute;
    // top: 0;
    // z-index: 1;
}

.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 0.1em 1.5em 0.1em 0.5em;
    margin: $multiselect-spacing 0.4rem $multiselect-spacing 0;
    // margin-right: $multiselect-spacing;
    background-color: $blue-lightest;
    border-radius: 12px;
    // font-size: 15px;
    font-size: 14px;
    font-weight: 700;
    color: #333;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    transition: 0.3s;

    float: left;

    &:hover,
    &:focus {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
    }
}

.multiselect__tag__remove {
    $iconSize: 1.75em;

    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: none;
    background: none;
    width: $iconSize;
    height: $iconSize;
    text-align: center;
    cursor: pointer;

    .svg {
        padding: 0.4em 0.4em 0.5em 0.5em;
        width: $iconSize;
        height: $iconSize;
        // fill: rgba(0,0,0,0.25);
        color: rgba($blue-dark, 0.4);
    }

    vertical-align: middle;

    &:hover,
    &:focus {
        .svg {
            color: $orange;
        }
    }
}

.multiselect__tag__remove_all {
    $iconSize: 2em;

    position: absolute;
    top: 0.45em;
    right: 0.25em;

    // float: right;
    // margin-right: -0.25em;

    padding: 0;
    border: none;
    background: none;
    width: $iconSize;
    height: $iconSize;
    text-align: center;
    cursor: pointer;

    z-index: 10;

    .svg {
        padding: 0.4em 0.4em 0.5em 0.5em;
        width: $iconSize;
        height: $iconSize;
        color: rgba(0,0,0,0.25);
    }

    vertical-align: middle;

    &:hover,
    &:focus {
        .svg {
            color: $orange;
        }
    }
}

.multiselect__remove_all {
    $iconSize: 1.4em;

    // position: absolute;
    // top: 0.45em;
    // right: 0.25em;

    float: right;
    margin-right: -0.2em;
    // margin-left: -0.25em;
    // margin-top: -0.3em;

    padding: 0;
    border: none;
    background: none;
    width: $iconSize;
    height: $iconSize;
    text-align: center;
    cursor: pointer;

    z-index: 10;

    .svg {
        position: relative;
        margin-top: -0.2em;
        padding: 0 0.25em;
        width: $iconSize;
        height: $iconSize;
        color: rgba($blue-dark, 0.4);
    }

    vertical-align: middle;

    &:hover,
    &:focus {
        .svg {
            color: $orange;
        }
    }
}

.multiselect__expand {
    $iconSize: 2em;

    position: absolute;
    top: 0.45em;
    right: 0.25em;
    // float: right;
    margin-right: -0.25em;

    padding: 0.4em 0.4em 0.5em 0.5em;
    width: $iconSize;
    height: $iconSize;
    color: rgba(0,0,0,0.25);

    z-index: 20;
    // position: relative;
    background-color: #ececec;
    box-shadow: 0 0 15px #ececec;
    border-radius: 5px;

}

.multiselect__select_all {
    @include button-variant(#fff, #fff);

    position: absolute;
    bottom: 0;
    width: 100%;
    height: $select-all-btn-height;

    color: $blue;
    font-weight: 700;

    border-radius: 0;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);

    &:hover,
    &:focus {
        background-color: $blue;
        border-color: $blue;
        color: #fff;
    }

    &.multiselect__deselect_all {
        &:hover,
        &:focus {
            background-color: #fff;
            border-color: #fff;
            color: $red;
        }
    }
}

.multiselect__input {
    // position: absolute;
    // display: block;
    //display: inline-block;
    // margin: $multiselect-spacing;
    // margin: 0;
    margin: 0;
    padding: 0;
    // padding-left: 0;
    // padding-right: 0;
    // width: 2em;
    font-size: 15px;
    width: 100%;
    // height: 1.5em;
    height: 1.6em;
    // height: 0;
    // line-height: 0;
    transition: 0.3s;
    // transition-delay: 0.3s;

    overflow: visible;

    border: none;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }

    &:disabled {
        opacity: 0.5;
    }
}

.multiselect__dropdown {
    position: absolute;
    top: 100%;
    // bottom: 0;
    left: 0;
    // padding-bottom: $select-all-btn-height;
    padding-bottom: 0;
    // width: 100%;
    min-width: 100%;
    max-width: 200%;
    height: 0;
    background-color: #fff;
    box-shadow: 0 2px 8px 0px rgba(0,0,0,0.25);
    border-radius: $input-radius;
    // overflow: auto;
    overflow: hidden;
    z-index: 100;
    transition: 0.3s;

    &.wide {
        width: 130%;
        @media screen and (max-width: $screen-sm) {
            width: 100%;
        }
    }

    &.open {
        padding-bottom: $select-all-btn-height;
        height: 200px;
    }

    .multiselect__dropdown_scroll {
        overflow: auto;
        height: 100%;
    }
}

.multiselect__dropdown_scroll_loading {
    opacity: 0.5;
    pointer-events: none;
}

.loader {
    $size: 18px;

    display: inline-block;
    position: relative;
    top: 3px;
    left: 6px;

    // negative margin prevents title shifting around
    // when loader is added and removed
    margin-top: -10px;
    width: $size;
    height: $size;
}

.loading-failed {
    .field-title {
        color: $grey-med;
    }

    .field-wrapper {
        opacity: 0.25;
    }
}

</style>