<template>
    <div class="navigation" :class="$route.path === '/release-notes' ? 'dark-theme' : ''">
        <div class="container-fluid" ref="headerContainer">
            <div class="row row-header row-full" ref="headerRow">
                <nav class="navbar navbar-expand-lg navbar-dark">
                    <div class="navbar-brand">
                        <a :href="NIHURL" target="_blank" class="link-report" rel="noopener">
                            <img src="./../assets/nih-logo.png" alt="NIH" class="logo" />
                            <span class="sr-only">NIH</span>
                        </a>
                        <a v-bind:href="reportUrl" target="_blank" class="link-report" rel="noopener">
                            <span class="logo-text">RePORT</span>
                        </a>
                        <img src="./../assets/angle-right.png" class="logo-divider" alt=">" />
                        <!-- <router-link :to="'/' + searchMode"> -->
                        <router-link :to="'/'" rel="noopener">
                            <span class="logo-text">RePORTER</span>
                        </router-link>
                    </div>
                    <button class="navbar-toggler" v-b-toggle.navbarSupportedContent>
                        <span class="navbar-toggler-icon"></span>
                        <span class="sr-only">Toggle</span>
                    </button>

                    <b-collapse class="collapse navbar-collapse" id="navbarSupportedContent">
                        <div class="ms-auto">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a v-bind:href="reportUrl + '/faqs'" target="_blank" class="nav-link" rel="noopener">FAQs</a>
                                </li>
                                <li class="nav-item">
                                    <a :href="apiURL" target="_blank" class="nav-link font-weight-bold" rel="noopener">API</a>
                                </li>
                                <li class="nav-item">
                                    <span class="nav-link">
                                        <router-link :to="'/exporter'" rel="noopener">ExPORTER</router-link>
                                    </span>
                                </li>
                                <li class="nav-item mt-1 me-2">
                                    <UserMenu v-if="showUserMenu"></UserMenu>
                                </li>
                            </ul>
                        </div>
                    </b-collapse>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserMenu from '@/components/UserMenu.vue';
import bus from '@/shared/event-bus.js';

export default {
    name: 'navigation',
    data: function () {
        return {
            showUserMenu: true,
            reportUrl: null,
            isFullscreen: false,
            maxWidth: 0,
            apiURL: '',
            NIHURL: ''
        };
    },
    components: {
        UserMenu
    },
    computed: {
        ...mapGetters([
            'fullscreen',
            'searchMode',
            'externalURLs'
        ])
    },
    watch: {
        fullscreen: function (val) {
            if (val === true) {
                // set width for animation
                this.$refs.headerRow.style.width = this.$refs.headerRow.offsetWidth + 'px';
                this.$refs.headerRow.style.maxWidth = 'none';

                this.maxWidth = this.$refs.headerRow.offsetWidth;

                setTimeout(() => {
                    let computedStyle = getComputedStyle(this.$refs.headerContainer);

                    this.$refs.headerRow.style.width = (this.$refs.headerContainer.offsetWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight)) + 'px';

                    setTimeout(() => {
                        this.$refs.headerRow.style.width = '';
                        this.$refs.headerRow.style.maxWidth = '';
                        this.isFullscreen = true;
                    }, 301);
                }, 1);
            } else {
                this.$refs.headerRow.style.width = this.$refs.headerRow.offsetWidth + 'px';

                setTimeout(() => {
                    this.$refs.headerRow.style.width = this.maxWidth + 'px';

                    setTimeout(() => {
                        this.isFullscreen = false;
                        this.$refs.headerRow.style.width = '';
                    }, 301);
                }, 1);
            }
        }
    },
    async created () {
        bus.$on('get:appsettings', () => {
            this.apiURL = this.externalURLs['reporter_api_base_url'];
            this.NIHURL = this.externalURLs['reporter_nih_url'];
            this.reportUrl = this.externalURLs['report_base_url'];
        });
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.row-header {
    transition: 0.3s;
}

.row-full {
    max-width: none;
}

.navigation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;


    // background: #0c2e5c;
    background: #02243d;
    background: -moz-linear-gradient(left, rgba(#031835, 0.45) 0%, rgba(#02243d, 0.25) 100%);
    background: -webkit-linear-gradient(left, rgba(#031835, 0.45) 0%, rgba(#02243d, 0.25) 100%);
    background: linear-gradient(to right, rgba(#031835, 0.45) 0%, rgba(#02243d, 0.25) 100%);

    // background-color: rgba(0, 0, 0, 0.5);
    // background-color: rgba(#02243d, 0.25);
    &.dark-theme {
        background: -moz-linear-gradient(to right, #063c63 1%, #1c496f 50%, #347a7d 75%, #507b82 100%);
        background: -webkit-linear-gradient(to right, #063c63 1%, #1c496f 50%, #347a7d 75%, #507b82 100%);
        background: linear-gradient(to right, #063c63 1%, #1c496f 50%, #347a7d 75%, #507b82 100%);
    }
}

.navbar {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    color: $header-text-color;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

    a {
        color: $header-text-color;
        text-decoration: none;
    }
}

.navbar-brand {
    font-size: 24px;
    font-weight: 500;

    svg {
        vertical-align: middle;
    }
}

.logo-text {
    display: inline-block;
    padding: 0 0.5em 0.1em 0.25em;
    vertical-align: middle;
}

.navbar-collapse {
    text-align: right;
    position: relative;

    &.show {
        div {
            &:after {
                content: "";
                background-color: #1f5581;
                background-image: url('../../static/img/header-background-m.jpg');
                background-position: center 0;
                background-size: cover;
                opacity: 1;
                top: 0px;
                left: -5px;
                bottom: 0;
                right: 0;
                position: absolute;
                z-index: -1;
                height: 105%;
                width: 105%;
            }
        }
    }
}

.logo {
    display: inline-block;
    width: 64px;
    height: 40px;
    fill: $logo-color;
}

.link-report {
    .logo-text {
        @media screen and (max-width: $screen-sm) {
            display: none;
        }
    }
}

.logo-divider {
    display: inline-block;
    margin-right: 0.4em;
    width: 15px;
    height: 40px;
    fill: rgba($logo-color, 0.5);

    @media screen and (max-width: $screen-sm) {
        display: none;
    }
}

.navbar-dark .navbar-nav .nav-link {
    font-size: 20px;
    color: #fff;
}

.nav-item {
    +.nav-item {
        margin-left: 1em;
    }
}
</style>