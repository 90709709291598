import {reactive} from "vue";

const state = reactive({
    screen: {}
});

/* This assumes you're using default bootstrap breakpoint names */
/* You need to hardcode the breakpoint values if you want to support IE11 */
const style = getComputedStyle(document.body);
const xs = style.getPropertyValue("--breakpoint-xs") ? style.getPropertyValue("--breakpoint-xs").replace("px", "") : 576;
const sm = style.getPropertyValue("--breakpoint-sm") ? style.getPropertyValue("--breakpoint-sm").replace("px", "") : 768;
const md = style.getPropertyValue("--breakpoint-md") ? style.getPropertyValue("--breakpoint-md").replace("px", "") : 992;
const lg = style.getPropertyValue("--breakpoint-lg") ? style.getPropertyValue("--breakpoint-lg").replace("px", "") : 1200;
const xl = style.getPropertyValue("--breakpoint-xl") ? style.getPropertyValue("--breakpoint-xl").replace("px", "") : 1400;

function onResize () {
    const width = window.innerWidth;

    /* Not really sure how to properly define gt or lt */
    state.screen = {
        xs: width >= xs && width < sm,
        sm: width >= sm && width < md,
        md: width >= md && width < lg,
        lg: width >= lg && width < xl,
        xl: width >= xl,
        /* Greater than */
        gt: {
            xs: width >= xs,
            sm: width >= sm,
            md: width >= md,
            lg: width >= lg,
            xl: width >= xl
        },
        /* Less than */
        lt: {
            xs: width < sm,
            sm: width < md,
            md: width < lg,
            lg: width < xl,
            xl: width < 9999
        }
    };
}

/* Might want to debounce the event, to limit amount of calls */
window.calculateViewPortSize = onResize;
onResize();

export default state;