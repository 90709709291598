<template>
    <button class="field-tooltip-icon">
		<i class="svg fas fa-question-circle" :class="cssClass"></i>
        <div class="field-tooltip" v-html="updatedText">
        </div>
    </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'FieldTooltip',
    // components: {
    // },
    props: {
        text: {
            required: false
        },
        urlKeys: {
            required: false
        },
        cssClass: {
            required: false
        }
    },
    data: function () {
        return {
            reportUrl: null,
            updatedText: this.text,
            urlKeyList: this.urlKeys
        };
    },
    computed: {
        ...mapGetters([
            'externalURLs'
        ])
    },
    methods: {
        setReportUrl () {
            this.reportUrl = this.externalURLs['report_base_url'];
            if (this.reportUrl !== "https://report.nih.gov") {
                this.updatedText = this.updatedText.replace("https://report.nih.gov", this.reportUrl);
            }
        }
    },
    async created () {

        var externalURLKeys = this.urlKeyList;
        var URLList = [];
        if (externalURLKeys && externalURLKeys.length > 0) {
            for (let i = 0; i < externalURLKeys.length; i++) {
                if (!this.externalURLs[externalURLKeys[i]]) {
                    URLList[URLList.length] = externalURLKeys[i];
                }
            }

            if (URLList.length > 0 ) {
                await this.$store.dispatch('getAppSettingsPropertyByNameAction', URLList);
            }
            for (let i = 0; i < externalURLKeys.length; i++) {

                var regex = new RegExp(externalURLKeys[i], "g");
                this.updatedText = this.updatedText.replace(regex, this.externalURLs[externalURLKeys[i]]);
            }
        }
    }
    // computed: {
    // },
    // data: function () {
    //     return {
    //     };
    // },
    // mounted () {

    // },
    // methods: {

    // }
}
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.field-tooltip-icon {
    // position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: none;
    border: none;
    padding: 0;
    top: -1px;
    margin: 0 4px;

    .svg {
        z-index: 22;
        color: $blue;
    }

    &:hover,
    &:focus {
        outline: none;
        color: $blue-light;

        .field-tooltip {
            display: block;
            pointer-events: all;
            opacity: 1;
            color: $gray-900;
        }
    }

    @media screen and (max-width: $grid-float-breakpoint-max) {
        float: right;
    }
}

.field-tooltip {
    // display: none;
    position: absolute;
    top: 100%;
    left: 25%;
    // z-index: 26;
    z-index: 110;
    padding: 10px;
    border-radius: 5px;
    // z-index: 0;
    width: 300px;
    font-size: 14px;
    text-align: left;
    line-height: 1.5em;
    background-color: $blue-lightest;
    box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.25);
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;
    user-select: text;
    cursor: default;

    a {
        word-break: break-all;
        color: $blue;
        text-decoration: none;

        &.no-break {
            word-break: break-word;
        }

        &:hover,
        &:focus,
        &:active {
            color: $blue-light;
        }
    }

    p {
        margin-top: 0.5em;
    }

    @media screen and (max-width: $grid-float-breakpoint-max) {
        // position: relative;
        left: auto;
        right: 0;
    }
}
</style>