export default window.filters = {
    getFirstPIName (nameList) {
        if (nameList !== undefined && nameList !== null) {
            nameList = nameList.split(';');
            if (nameList.length > 1) {
                if ($.trim(nameList[0]) === ',') {
                    return '';
                }

                return $.trim(nameList[0]);
            } else {
                if ($.trim(nameList[0]) === ',') {
                    return '';
                }

                return $.trim(nameList[0]);
            }
        }
    },
    removeFirstPIName (nameList) {
        if (nameList !== undefined && nameList !== null) {
            nameList = nameList.split(';');
            var uniqueNames = [];

            $.each(nameList, function (i, el) {
                if ($.inArray(el, uniqueNames) === -1) {
                    uniqueNames.push(el);
                }
            });

            if (uniqueNames.length > 1) {
                uniqueNames.shift();
                uniqueNames = '; ' + uniqueNames.join('; ');
                return uniqueNames;
            }
        }
    },
    removeFirstNameWithEmail (nameList, email) {
        if (nameList !== undefined && nameList !== null) {
            nameList = nameList.split(';');
            var uniqueNames = [];

            $.each(nameList, function (i, el) {
                if ($.inArray(el, uniqueNames) === -1) {
                    uniqueNames.push(el);
                }
            });

            if (uniqueNames.length > 1) {
                uniqueNames.shift();
                uniqueNames = '; ' + uniqueNames.join('; ');
                return uniqueNames + '<br><br>Contact: ' + email;
            } else {
                return '<br><br>Contact: ' + email;
            }
        }
    },

    splitArrayWithParam (array, param) {
    if (array && param) {
        if ($.isNumeric(array)) {
            array = array.toString();
        }

        return array.split(param);
    }
    },

    formatDate (value, format) {
    var date,
        month,
        day,
        year,
        monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

    if (value) {
        date = new Date(value);

        month = (date.getMonth() + 1);
        day = date.getDate();
        year = date.getFullYear();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        switch (format) {
            case 'dd-MON-yyyy':
                return day + '-' + monthNames[date.getMonth()] + '-' + year;
            case 'mon_yyyy':
                return monthNames[date.getMonth()] + ' ' + year
        }
        return month + '/' + day + '/' + year;
    }
},

formatDateTime (value) {
    var date,
        month,
        day,
        year,
        hours,
        minutes,
        period;

    if (value) {
        date = new Date(value);

        month = (date.getMonth() + 1);
        day = date.getDate();
        year = date.getFullYear();
        hours = date.getHours();
        minutes = date.getMinutes();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        if (hours > 12) {
            hours = hours - 12;
            period = 'PM';
        } else {
            period = 'AM';
        }

        return `${month}/${day}/${year} ${hours}:${minutes} ${period}`;

    }
},

capitalizeFirst (value) {
    if (value) {
        value = value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
    }

    return value;
},

pluralize (value) {
    if (value !== 'Displayed') {
        if (value.charAt(value.length - 1) === 'y') {
            return value.substring(0, value.length - 1) + 'ies';
        } else {
            return value + 's';
        }
    }

    return value;
},

addCommas (value) {
    if (value) {
        if (typeof value === 'number' && value !== 0) {
            return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        } else {
            return value;
        }
    }
},

addBreakAfterCommas (value) {
    return value.split(',').join(', ');
},

breakAtUnderscore (value) {
    return value.split('_').join('_\u200B');
},

breakEmail (value) {
    return value.split('@').join('@\u200B').split('.').join('.\u200B');
},

round(value) {
    return value ? value.toFixed(2) : value;
},

formatCurrency (value, formatLargeNo) {
    var addCommas = window.filters.addCommas,
        formatLargeNumber = window.filters.formatLargeNumber;

    if (!isNaN(value)) {
        return '$' + (formatLargeNo ? formatLargeNumber(value) : addCommas(value));
    } else {
        return value;
    }
},


formatLargeNumber (value) {
    var addCommas = window.filters.addCommas,
        round = {
            T: 0.000000000001,
            B: 0.000000001,
            M: 0.000001,
            K: 0.001
        },
        roundKey;

    if (value) {
        if (value.toString().length >= 4) {
            if (value.toString().length >= 13) {
                //if trillions
                roundKey = 'T';
            } else if (value.toString().length >= 10) {
                //if billions
                roundKey = 'B';
            } else if (value.toString().length >= 7) {
                //if millions
                roundKey = 'M';
            } else if (value.toString().length >= 4) {
                //if thousands
                roundKey = 'K';
            } else {
                value = addCommas(value);
            }

            if (roundKey) {
                value = value * round[roundKey];
                value = Math.round(value * 10) / 10;
                value = value + roundKey;
            }
        } else {
            value = addCommas(value);
        }
    } else {
        value = addCommas(value);
    }

    return value;
},

formatLargeCurrency (value) {
    var formatLargeNumber = window.filters.formatLargeNumber;

    if (value > 0) {
        return '$' + formatLargeNumber(value);
    } else {
        return value;
    }
},

formatPercentage (value) {
    return value + '%';
},

titleCase (title) {
    return title.toTitleCase();
},

globalFormat (value, format) {
    if (format) {
        switch (format.toString().toLowerCase()) {
            case '$':
                if (value === null || value === "") {
                    return "";
                }
                return window.filters.formatCurrency(value);
            case '%':
                if (value !== null && value !== "") {
                    return window.filters.formatPercentage(value);
                }
            case ',':
                if (typeof value === 'number') {
                    return window.filters.addCommas(value);
                } else {
                    return value;
                }
                break;
            case 'count':
                if (typeof value === 'number' && value !== 0) {
                    return window.filters.addCommas(value);
                } else {
                    if (value === 0) {
                        return '';
                    }
                    return value;
                }
                break;
            case 'year':
            case 'fy':
                return value;
            case 'disclosure':
                if (value !== null) {
                    return value;
                }
                return 'D';
            case 'mon_yyyy':
                if (value !== null) {
                    return window.filters.formatDate(value, format);
                }
                break;
            case 'round':
                if (value !== null) {
                    return window.filters.round(value);
                }
                break;

        }

        return value;
    }

    return value;
},

addSymbolInHeader (value, colHeader, index) {
    if (colHeader && colHeader[index] !== null && colHeader[index] !== undefined && value && value !== "") {
        switch (colHeader[index].toString().toLowerCase()) {
            case '%':
                return value + '%';
        }
    }

    return value;
},

chartAxisFormat (value, colHeader, format) {
    if (format) {
        switch (format.toString().toLowerCase()) {
            case '$':
                return window.filters.formatLargeCurrency(value);
            case '%':
                return window.filters.formatPercentage(value);
            case 'year':
            case 'fy':
                return value;
        }
    }

    if (colHeader) {
        colHeader = colHeader.toString().toLowerCase();

        if (colHeader.indexOf('year') !== -1 || colHeader.indexOf('fy') !== -1) {
            return value;
        }

        if (colHeader.indexOf('%') !== -1) {
            return window.filters.formatPercentage(value);
        }
    }

    if (typeof value === 'number') {
        return window.filters.formatLargeNumber(value);
    } else {
        return value;
    }
},

globalChartTooltipFormat(value, colHeader, format) {
    if (value !== null && value !== undefined && value !== '') {
        if (format) {
            switch (format.toString().toLowerCase()) {
                case '$':
                    return window.filters.formatCurrency(value);
                case '%':
                    return window.filters.formatPercentage(value);
                case 'year':
                case 'fy':
                    return value;
            }
        }

        if (colHeader) {
            colHeader = colHeader.toString().toLowerCase();

            if (colHeader.indexOf('year') !== -1 || colHeader.indexOf('fy') !== -1) {
                return value;
            }

            if (colHeader.indexOf('%') !== -1) {
                return window.filters.formatPercentage(value);
            }
        }

        if (typeof value === 'number') {
            return window.filters.addCommas(value);
        } else {
            return value;
        }
    }

    // return value;
    return 'N/A';
},

highlightTerms (value, terms) {
    var termsArray,
        termIndex,
        lowerCaseValue,
        searchMask,
        regEx,
        replaceMask,
        i;

    if (value !== null && value !== undefined && value !== '') {
        value = value.toString();
        termsArray = terms.toLowerCase().split(' ');
        lowerCaseValue = value.toLowerCase();

        for (i = 0; i < termsArray.length; i++) {
            termIndex = lowerCaseValue.indexOf(termsArray[i]);

            if (termIndex !== -1) {
                searchMask = termsArray[i];
                regEx = new RegExp(searchMask + '(?!([^<]+)?>)', 'ig');
                replaceMask = '<span class="search-highlight">' + value.slice(termIndex, termIndex + termsArray[i].length) + '</span>';

                value = value.replace(regEx, replaceMask);
                lowerCaseValue = value.toLowerCase();
            }
        }
    }

    return value;
},

//thank you: https://stackoverflow.com/questions/35070271/vue-js-components-how-to-truncate-the-text-in-the-slot-element-in-a-component
truncate (text, stop, clamp) {
    if(text == undefined) return "";
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
}
}

