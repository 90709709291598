import axios, * as others from 'axios';

const API = "/services/AppSettings"; //relative...

const getPropertyByName = async function (propNameList) {
    try {
        const response = await axios.post(`${API}/getSettingByName`, propNameList);

        if (response.status !== 200) throw Error(response.message);

        return response.data;
    }
    catch (error) {		
        return null;
    }

};

export const appSettingsService = {
    getPropertyByName
}
