<template>
    <div class="home">

        <FeatureArea />

        <div class="col-10 offset-1">
            <div class="home-charts">
                <div class="row">
                <div class="col-lg-4 tile-container" id="mapChart">
                    <mapTile name="Active Funding by State" subTitle="Select a state to view projects"  />
                </div>
                <div class="col-lg-8 tile-container" id="barChart">
                    <chartTile name="Active Projects by Institute/Center" subTitle="Select a bar to view projects for an Institute/Center" />
                </div>
                </div>
            </div>

            <SearchTiles />
        </div>


    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import FeatureArea from '@/components/home/FeatureArea.vue'
import SearchTiles from '@/components/home/SearchTiles.vue'
import MapTile from '@/components/home/MapTile.vue'
import ChartTile from '@/components/home/ChartTile.vue'
import bus from '@/shared/event-bus.js';

export default {
    name: 'home',
    components: {
        FeatureArea,
        SearchTiles,
        MapTile,
        ChartTile
    },
    data: function () {
        return {
            chartSearchId: null,
            mapSearchId: null
        };
    },
    methods: {
        ...mapActions([
            'setFullscreen',
            'setEditSearchResultsFlag',
            'setSearchMode',
            'setSelectedPerson'
        ])
    },
    async created () {
      this.setEditSearchResultsFlag(false);
      this.setFullscreen(false);

        // if (window.readCookie('searchMode')) {
        //     this.$router.push('/advanced-search');
        //     this.setSearchMode('advanced-search');
        // } else {
        //     this.setSearchMode('');
        // }
    },
    mounted () {


        this.setSelectedPerson(null);
        switch (this.$route.path) {
            case '/advanced-search':
            case '/matchmaker':
            case '/publications':
                // document.getElementById('search-tiles').scrollIntoView();
                let target = document.getElementById('search-tiles');

                setTimeout(() => {
                    window.doScrolling(target, 400);
                }, 500);

                break;
            default:
                if (!window.isMobile) {
                    document.getElementById('smartSearch').focus();
                }
                break;
        }

        // reset criteria when going to home page
        this.$store.state.searchCriteria = copyData(this.$store.state.searchCriteriaDefault);
        bus.$emit('update:freeText');
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.home-charts {
  margin-top: 30px;
}
.tile-container {

    @media screen and (max-width: $screen-sm) {
        padding-left: 0;
        padding-right: 0;
        margin-left: -5px;
        margin-right: -5px;
        width: calc(100% + 10px);
    }

    + .tile-container {
        @media screen and (max-width: $screen-sm-max) {
            margin-top: 30px;
        }
    }
}
</style>