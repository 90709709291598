<template>
    <div class="search-field input-text" :class="$store.state.searchFieldErrors[id] && $store.state.searchFieldErrors[id].message ? 'field-error' : ''">
        <label
            class="field-title"
            :class="titleHidden ? 'sr-only' : ''"
            :for="'field-' + id"
        >
            {{title}}
            <field-tooltip v-if="tooltipText" :text="tooltipText" :urlKeys="urlKeys" />
        </label>
        <div
            class="input-group"
        >
        <template v-if="calendarType === 'range'">
            <date-picker range
                v-model:value="inputDateValue"
                :lang="lang" separator="-"
                :id="'field-' + id"
                format="MM/DD/YYYY"
                v-model:open="isControlOpen"
                @pick="validateDate($event)"
                @open="setMaxMinDate()"
                :not-before="notBeforeDate"
                :not-after="notAfterDate"
                :confirm="true"
                :appendToBody="false"
                @clear="clearText()"
                @close="removeHeader(); validateDate($event);"
                type="date"
            >

            </date-picker>
        </template>
        <template v-else>
            <date-picker
                v-model:value="inputDateValueSingle"
                :lang="lang"
                :id="'field-' + id"
                :appendToBody="false"
                format="MM/DD/YYYY"
                @pick="validateDate($event)"
                @open="setMaxMinDate()"
                :not-before="notBeforeDate"
                :not-after="notAfterDate"
                :confirm="false"
                @clear="clearText()"
                @close="removeHeader(); validateDate($event);"
            >
            </date-picker>
        </template>
        <!-- <span class="display-date" v-if="inputDateValueSingle" @click.prevent="openCalendar()">{{inputDateValueSingle}}
                <svgicon name="icon-x" @click.stop="clearText()" />
                <span class="sr-only">Remove All</span>
            </span> -->
            <div class="date-selection-options" :id="'date-selection-' + id">
                <div class="triangle"></div>
                <span class="label" v-if="calendarType === 'range'">Select {{title}} Range</span>
                <span class="label" v-else>Select Date</span>
                <span class="options">
                    <ul>
                        <li :class="{'active': calendarType === 'range'}"><a href @click.prevent="setCalendarType('range')">Range</a></li>
                        <li :class="{'active': calendarType === 'before'}"><a href @click.prevent="setCalendarType('before')">Before</a></li>
                        <li :class="{'active': calendarType === 'after'}"><a href @click.prevent="setCalendarType('after')">After</a></li>
                    </ul>
                </span>
            </div>
        </div>
        <div class="field-help-text" v-if="helpTextDisplay" >
            {{helpTextDisplay}}
        </div>
        <field-error
            v-if="$store.state.searchFieldErrors[id]"
            v-show="$store.state.searchFieldErrors[id].message"
            :message="$store.state.searchFieldErrors[id].message"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FieldError from '@/components/searchFields/FieldError.vue';
import FieldTooltip from '@/components/searchFields/FieldTooltip.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import bus from '@/shared/event-bus.js';

export default {
    name: 'InputDate',
    components: {
        FieldError,
        FieldTooltip,
        DatePicker
    },
    props: {
        title: {
            required: true
        },
        id: {
            required: true
        },
        default: {
            required: false
        },
        maxSelection: {
            required: false
        },
        delimiter: {
            required: false,
            default: ';'
        },
        fields: {
            required: false
        },
        helpText: {
            required: false
        },
        placeholder: {
            required: false
        },
        dependentField: {
            required: false
        },
        select: {
            required: false
        },
        deselect: {
            required: false
        },
        change: {
            required: false
        },
        maxLength: {
            required: false
        },
        matchingStr: {
            required: false
        },
        titleHidden: {
            required: false
        },
        alternetFields: {
            required: false
        },
        groupFields: {
            groupFields: false
        },
        bus: {
            required: false
        },
        tooltipText: {
            required: false
        },
        urlKeys: {
            required: false
        }
        // inputTypes: {
        //     required: false
        // },
        // submitTypes: {
        //     required: false
        // }
    },
    computed: {
        ...mapGetters([
            'searchCriteria',
            'searchFieldErrors'
        ])
    },
    data: function () {
        return {
            lang: {
                days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                placeholder: {
                    date: '',
                    dateRange: ''
                }
            },
            shortcuts: [
                {
                    text: 'Today',
                    onClick: () => {
                        this.inputDateValue = [ new Date(), new Date() ]
                    }
                }
            ],
            inputDateValue: [],
            notBeforeDate: '',
            notAfterDate: '',
            inputTypes: null,
            submitTypes: null,
            inputDateValueSingle: '',
            // inputValue: ''

            // assigned here so that it can be overwritten below
            // otherwise throws error in Vue
            helpTextDisplay: this.calendarType ? 'on or ' + this.calendarType : this.helpText,
            showSelectionOptions: false,
            calendarType: 'before',
            isControlOpen: false
        };
    },
    mounted () {
        var inputValDefault = {
            'from_date': null,
            'to_date': null
        }
        // initialize field in store so that it will be watched
        // this.helpTextDisplay = '';
        if (!this.$store.state.searchCriteria[this.id]) {
            this.$store.state.searchCriteria[this.id] = inputValDefault;
        }

        if (!this.$store.state.searchCriteriaDefault[this.id]) {
            this.$store.state.searchCriteriaDefault[this.id] = inputValDefault;
        }

        if (this.$store.state.editSearchCriteria[this.id]) {
            this.$store.state.searchCriteria[this.id] =  this.$store.state.editSearchCriteria[this.id];
        }

        if (!this.$store.state.searchFieldErrors[this.id]) {
            this.$store.state.searchFieldErrors[this.id] = '';
        }

        bus.$on('reset', this.reset);

        this.updateSearchCriteriaDefault();

        this.initDateField();
        //document.addEventListener('scroll', this.repositionControl);

        // console.log(this);
        // console.log(this.$el.querySelectorAll('input'));

        this.inputEl = this.$el.querySelectorAll('input');

        if (this.inputEl) {
            this.inputEl = this.inputEl[0];
            this.inputEl.addEventListener('keydown', (event) => {
                this.handleInputEvent(event);
            });
        }

        setTimeout(() => {
            this.setLabelId();
        }, 101)

    },
    methods: {
        ...mapActions([
            'setSearchCriteria',
            'setSearchCriteriaDefault',
            'setSearchFieldErrors'
        ]),
        setLabelId () {
            var inputTextFields = this.$el.querySelectorAll('input[type=text]'),
            itemLabel;

            if (inputTextFields && inputTextFields.length > 1) {
                for (let i = 0; i < inputTextFields.length; i++) {
                    inputTextFields[i].setAttribute("id", "field-" + this.id + '-' + i);

                    itemLabel = document.createElement("Label");
                    itemLabel.setAttribute("for", "field-" + this.id + '-' + i);
                    itemLabel.setAttribute("class", "sr-only");
                    itemLabel.innerHTML = "date";
                    inputTextFields[i].parentNode.insertBefore(itemLabel, inputTextFields[i]);
                }
            } else {
                inputTextFields[0].setAttribute("id", "field-" + this.id );

                itemLabel = document.createElement("Label");
                itemLabel.setAttribute("for", "field-" + this.id);
                itemLabel.setAttribute("class", "sr-only");
                itemLabel.innerHTML = "date";
                inputTextFields[0].parentNode.insertBefore(itemLabel, inputTextFields[0]);

            }
        },

        setCalendarType (type) {
            this.calendarType = type;
            if (type.toLowerCase() === 'range') {
                this.isControlOpen = true;
            } else {
                this.isControlOpen = false;
            }
            this.removeHeader();
            this.$nextTick(() => {
                let $this = this;
                setTimeout(() => {
                    $this.setLabelId();
                    $this.setMaxMinDate();
                }, 101);
            });
        },

        handleInputEvent (event) {
            // this.$refs.input.addEventListener('keydown, paste', (event) => {
            // var key = String.fromCharCode(event.keyCode);

            // if press enter, prevent adding line break
            if (event.keyCode === 13) {
                event.preventDefault();

                //submit search
                this.$parent.$parent.search();
            }
        },

        validateDate (e1) {
			gtag('event','Click', {
				'event_category': 'Search Field',
				'event_label': 'Select - ' + this.title,
				'value': 'Select - ' + this.title
			});	

            if (this.calendarType === 'range') {
                if (this.inputDateValue) {
                    this.inputDateValueSingle = '';
                    this.updateSearchCriteria({
                        'from_date': this.inputDateValue[0],
                        'to_date': this.inputDateValue[1],
                    });
                }
                this.helpTextDisplay = this.helpText;
            } else {
                this.inputDateValue = null;
                this.updateSearchCriteria({
                    'from_date': this.calendarType === 'after' ? this.inputDateValueSingle : null,
                    'to_date': this.calendarType === 'before' ? this.inputDateValueSingle : null,
                });
                this.helpTextDisplay = 'on or ' + this.calendarType;
            }
            this.showSelectionOptions = false;
        },

        openCalendar () {
            var event = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true
                });

            document.getElementById('field-' + this.id).getElementsByTagName('input')[0].dispatchEvent(event);
        },

        removeHeader () {
            var curId = this.id,
                datePickerObjContainer = document.getElementById('field-' + curId).parentNode.parentNode.parentNode,
                header = datePickerObjContainer.getElementsByClassName('date-selection-options')[0];

            datePickerObjContainer.insertBefore(header, datePickerObjContainer.getElementsByClassName('field-help-text')[0]);
        },

        setHeader () {
            var curDatePicker,
                thisId = this.id,
                header = document.getElementById('date-selection-' + thisId),
                datePickerObjContainer;

            setTimeout(() => {
                datePickerObjContainer = document.getElementById('field-' + thisId).parentNode.parentNode;
                curDatePicker = datePickerObjContainer.getElementsByClassName('mx-datepicker-popup')[0];
                if (!curDatePicker.getElementsByClassName('date-selection-options')[0]) {
                    curDatePicker.insertBefore(header, curDatePicker.childNodes[0]);
                    curDatePicker.getElementsByClassName('date-selection-options')[0];
                    // setTimeout(() => {
                    //     this.repositionControl();
                    // });
                }
            });
        },

        initDateField () {
            if (this.$store.state.searchCriteria[this.id] && this.$store.state.searchCriteria[this.id] !== '' && this.$store.state.searchCriteria[this.id] !== null) {
                if (this.$store.state.searchCriteria[this.id]['from_date'] && this.$store.state.searchCriteria[this.id]['to_date']) {
                    var startDate = new Date(this.$store.state.searchCriteria[this.id]['from_date']),
                        endDate = new Date(this.$store.state.searchCriteria[this.id]['to_date']);

                    this.inputDateValue.push(startDate);
                    this.inputDateValue.push(endDate);
                    this.inputDateValueSingle = '';
                    this.calendarType = 'range';
                } else {
                    if (this.$store.state.searchCriteria[this.id]['from_date']) {
                        var startDate = new Date(this.$store.state.searchCriteria[this.id]['from_date']);

                        this.inputDateValue = null;
                        this.inputDateValueSingle = startDate; //this.formatDate(startDate);
                        this.calendarType = 'after';
                    } else if (this.$store.state.searchCriteria[this.id]['to_date']) {
                        var endDate = new Date(this.$store.state.searchCriteria[this.id]['to_date']);

                        this.inputDateValue = null;
                        this.inputDateValueSingle = endDate; //this.formatDate(endDate);
                        this.calendarType = 'before';
                    } else {
                        this.inputDateValue = null;
                        this.inputDateValueSingle = '';
                        this.calendarType = 'before';
                    }
                }
            } else {
                this.inputDateValue = null;
                this.inputDateValueSingle = '';
                this.calendarType = 'before';
            }
        },

        setMaxMinDate () {
            this.setHeader();
            // this.calendarType = 'before';
            // this.helpTextDisplay = 'on or ' + this.calendarType;
            this.showSelectionOptions = true;
            if (this.dependentField && window.searchFieldSpecs[this.dependentField]) {
				gtag('event','Set Min/Max Date', {
					'event_category': 'Search Field',
					'event_label': 'Select - ' + this.title,
					'value': 'Select - ' + this.title
				});	
                //this.inputDateValueSingle = null;
                var self = this,
                    depFieldId = window.searchFieldSpecs[self.dependentField].id;

                if (self.dependentField && self.dependentField.indexOf('StartDate') !== -1) {
                    this.notAfterDate = '';
                    if (self.$store.state.searchCriteria[depFieldId] && self.$store.state.searchCriteria[depFieldId].to_date) {
                        this.notBeforeDate = self.$store.state.searchCriteria[depFieldId].to_date;
                    } else {
                        this.notBeforeDate = '';
                    }
                } else {
                    this.notBeforeDate = '';
                    if (self.$store.state.searchCriteria[depFieldId] && self.$store.state.searchCriteria[depFieldId].from_date) {
                        this.notAfterDate = self.$store.state.searchCriteria[depFieldId].from_date;
                    } else {
                        this.notAfterDate = '';
                    }
                }
            }
        },

        formatDate (d) {
            return ("0"+(d.getMonth()+1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2) + "/" + d.getFullYear();
        },

        repositionControl () {
            // var datePickerObjArr = document.getElementsByClassName('mx-datepicker-popup'),
            //     curDatePicker = datePickerObjArr[0],
            //     winScrollHeight = Math.max(
            //         document.body.scrollHeight, document.documentElement.scrollHeight,
            //         document.body.offsetHeight, document.documentElement.offsetHeight,
            //         document.body.clientHeight, document.documentElement.clientHeight
            //     ),
            //     header = curDatePicker.getElementsByClassName('date-selection-options')[0],
            //     dateControl = document.getElementById('field-' + this.id);

            // if (datePickerObjArr.length > 0 && header) {
            //     if (this.id === 'project_end_date'  && datePickerObjArr.length > 1) {
            //         curDatePicker = document.getElementsByClassName('mx-datepicker-popup')[1];
            //     }

            //     console.log(curDatePicker.style.top);
            //     console.log(dateControl.getBoundingClientRect().top + document.documentElement.scrollTop)
            //     if (curDatePicker && parseInt(dateControl.getBoundingClientRect().top) > document.documentElement.scrollTop) {
            //         curDatePicker.style.top = (parseInt(dateControl.getBoundingClientRect().top) - (2 * header.offsetHeight)) + 10 + 'px';
            //         curDatePicker.classList.add('down');
            //     } else {
            //         curDatePicker.style.top = (parseInt(dateControl.getBoundingClientRect().top)) + 15 + 'px';
            //         curDatePicker.classList.remove('down');
            //     }
            // }
        },

        reset () {
            var inputValDefault = {
                'from_date': null,
                'to_date': null
            }

            this.inputDateValue = [];
            this.inputDateValueSingle = null;
            this.calendarType = 'before';
            this.helpTextDisplay = this.helpText;
            this.updateSearchCriteria(inputValDefault);
        },

        clearText () {
            var inputValDefault = {
                'from_date': null,
                'to_date': null
            }

            this.inputDateValueSingle = [];
            this.inputDateValue = null;
            this.updateSearchCriteria(inputValDefault);
        },

        updateSearchCriteriaDefault () {
            if (this.default) {
                this.setSearchCriteriaDefault({
                    field: this.id,
                    data: copyData(this.default)
                });
            }
        },

        updateSearchCriteria (data) {
            this.setSearchCriteria({
                field: this.id,
                data: copyData(data)
            });

        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

$input-text-spacing: 0.15rem;

.input-text {
    position: relative;
}

.field-title {
    margin-bottom: 0.5em;
    font-size: 18px;
    font-weight: 400;
    overflow: visible;
    color: #212529;
}

.input-group {
    box-shadow: $field-shadow;

    background-color: $grey-lighter;
    border-radius: $input-radius;

}

.date-selection-options {
    display: none;
    padding: 10px 10px 0;
    .label {
        display: inline-block;
        border-right: none;
        font-weight: bold;
        padding: 5px 7px 10px 0;
    }
    .options {
        text-align: right;

        ul {
            padding: 7px 0;
            box-shadow: 3px 5px 6px 0px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            display: inline;
            li {
                display: inline-block;
                padding: 5px 5px;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-right: 1px solid rgba(0, 0, 0, 0.25);
                &:last-child {
                    border-radius: 0 5px 5px 0;
                    border-right: none;
                }
                &:first-child{
                    border-radius: 5px 0 0 5px;
                }
                &.active {
                    border-right: none;
                    background-color: $blue;
                    a {
                        color: $white;
                    }
                    &:hover {
                        background-color: $blue-dark;
                        a {
                            text-decoration: none;
                        }
                    }
                }
                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.mx-datepicker-popup {
    .date-selection-options {
     display: block;
    }
}

.mx-datepicker-range{
    width: 100%;
    background-color: transparent;
}

span.display-date {
    display: inline-block;
    margin: 0;
    overflow: auto;
    font-size: 16px;
    min-height: 42px;
    z-index: 2;
    padding: 0.72rem 0.5rem;
    height: calc(2.875rem + 2px);
    background-color: #ECECEC;
    position: absolute;
    line-height: 1.4;
    fill: rgba(0, 0, 0, 0.25);
    width: 100%;
    .svg-icon {
        position: absolute;
        right: 9px;
        height: 12px;
        top: 16px;
        .color1 {
            fill:rgba(0, 0, 0, 0.25);
        }
    }
}

.mx-calendar {
    .mx-calendar-content{
        overflow: visible !important;
    }
}
</style>