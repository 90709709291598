import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import qs from 'qs';


  
const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/matchmaker',
      name: 'matchmaker',
      component: Home
    },
    {
      path: '/advanced-search',
      name: 'advanced-search',
      component: Home
    },
    {
      path: '/publications',
      name: 'publications-search',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/smartSearch/:queryString',
      name: 'smartSearch',
      component: () => import(/* webpackChunkName: "about" */ './views/ReportSmartSearch.vue'),
      alias: '/quickSearch/:queryString'
    },
    {
      path: '/PISearch/:pi_id',
      name: 'PISearch',
      component: () => import(/* webpackChunkName: "about" */ './views/PersonSummary.vue')
    },
    {
      path: '/PISearch/:pi_id/:pi_name',
      name: 'PISearchName',
      component: () => import(/* webpackChunkName: "about" */ './views/PersonSummary.vue')
    },
    {
      path: '/POSearch/:po_name',
      name: 'POSearch',
      component: () => import(/* webpackChunkName: "about" */ './views/PersonSummary.vue')
    },
    {
      path: '/search/:search_id/projects/charts',
      name: 'charts',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/SearchResults.vue'),
      alias: '/share/:search_id/projects/charts'
    },
    {
      path: '/search/:search_id/projects/map',
      name: 'map',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/SearchResults.vue')
    },
    {
      path: '/search/:search_id/projects',
      name: 'list',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/SearchResults.vue'),
      alias: ['/share/:search_id/projects']
    },
    {
      path: '/pi_summary/:profile_id/:search_id/projects',
      name: 'pi_list',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/SearchResults.vue')
    },
    {
      name: 'search-project-details',
      path: '/search/:search_id/project-details/:appl_id',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/ProjectDetails.vue')
    },
    {
      name: 'search-project-details-map',
      path: '/search/:search_id/projects/map/project-details/:appl_id',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/ProjectDetails.vue')
    },
    {
      name: 'publications-project-details',
      path: '/publications/:search_id/project-details/:appl_id',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import( /* webpackChunkName: "about" */ './views/ProjectDetails.vue')
    },
    {
      name: 'search-project-details-publications',
      path: '/search/:search_id/publications/project-details/:appl_id',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/ProjectDetails.vue')
    },
    {
      name: 'search-project-details-news',
      path: '/search/:search_id/news/project-details/:appl_id',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/ProjectDetails.vue')
    },
    {
      name: 'search-project-details-charts',
      path: '/search/:search_id/projects/charts/project-details/:appl_id',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/ProjectDetails.vue')
    },
    {
      name: 'search-project-details-matchmaker',
      path: '/search/:search_id/projects/matchmaker/project-details/:appl_id',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/ProjectDetails.vue')
    },
    {
      name: 'project-details',
      path: '/project-details/:appl_id',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/ProjectDetails.vue')
    },
    {
      path: '/search/:search_id/publications',
      name: 'publications',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Publications.vue'),
      alias: '/share/:search_id/publications'
    },
    {
      path: '/search/:search_id/patents',
      name: 'patents',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Patents.vue'),
      alias: '/share/:search_id/patents'
    },
    {
      path: '/search/:search_id/clinicalStudies',
      name: 'clinicalStudies',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/ClinicalStudies.vue'),
      alias: '/share/:search_id/clinicalStudies'
    },
    {
      path: '/search/:search_id/news',
      name: 'news',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/News.vue')
    },
    {
      path: '/release-notes',
      name: 'release-notes',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './components/ReleaseNotes.vue')
    },
    // {
    //   path: '/pi_summary/:profile_id',
    //   name: 'pi-summary',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/PersonSummary.vue')
    // },
    {
      path: '/matchmaker/:search_id/projects',
      name: 'matchmaker-projects',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import( /* webpackChunkName: "about" */ './views/MatchmakerResults.vue')
    },
    {
      path: '/matchmaker/:search_id/POs',
      name: 'matchmaker-pos',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import( /* webpackChunkName: "about" */ './views/MatchmakerResults.vue')
    },
    {
      path: '/publications/:search_id',
      name: 'publication-search-results',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import( /* webpackChunkName: "about" */ './views/PublicationSearchResults.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue')
    },
    {
      path: '/search/:search_id/bridge',
      name: 'query-bridge',
      component: () => import(/* webpackChunkName: "querybridge" */ './views/QueryBridge.vue')
    },
    {
      path: '/exporter',
      name: 'exporter-projects',
      component: () => import(/* webpackChunkName: "querybridge" */ './components/exporter/projects.vue'),
      props: { tabName: 'PROJECT' },
      alias: ['/exporter/projects']
    },
    {
      path: '/exporter/abstracts',
      name: 'exporter-abstracts',
      component: () => import(/* webpackChunkName: "querybridge" */ './components/exporter/projects.vue'),
      props: { tabName: 'ABSTRACT' }
    },
    {
      path: '/exporter/publications',
      name: 'exporter-publications',
      component: () => import(/* webpackChunkName: "querybridge" */ './components/exporter/projects.vue'),
      props: { tabName: 'PUBLICATION' }
    },
    {
      path: '/exporter/patents',
      name: 'exporter-patent',
      component: () => import(/* webpackChunkName: "querybridge" */ './components/exporter/CSPatents.vue')
    },
    {
      path: '/exporter/clinicalstudies',
      name: 'exporter-clinicalstudy',
      component: () => import(/* webpackChunkName: "querybridge" */ './components/exporter/CSPatents.vue')
    },
    {
      path: '/exporter/linktables',
      name: 'exporter-linktables',
      component: () => import(/* webpackChunkName: "querybridge" */ './components/exporter/projects.vue'),
      props: { tabName: 'LINK' }
    },
    {
      path: '/exporter/crisp',
      name: 'crisp_project',
      component: () => import(/* webpackChunkName: "querybridge" */ './components/exporter/crisp-projects-abstracts.vue'),
      alias: ['/exporter/crisp-projects']
    },
    {
      path: '/exporter/crisp-abstracts',
      name: 'crisp_abstract',
      component: () => import(/* webpackChunkName: "querybridge" */ './components/exporter/crisp-projects-abstracts.vue')
    },

    { path: '/:pathMatch(.*)*', redirect: '/#/' }
  ],
  // set custom query resolver
  parseQuery (query) {
    return qs.parse(decodeURIComponent(query));
  },
  stringifyQuery (query) {
    var result = qs.stringify(query);

    return result ? (decodeURIComponent(result)) : '';
  }
});

export default router;
