<template>
    <div class="smart-search">
        <label class="field-title" for="smartSearch" v-if="showTitle">Quick Search</label>
        <div class="input-group smartSearchContainer">
            <span class="position-relative">
                <i v-if="clonedSmartSearch && clonedSmartSearch != '' && !loading" class="clearIcon fas fa-times" @click="clonedSmartSearch = ''"></i>
                <input type="search" class="form-control form-control-lg"
                    autofocus placeholder="Search RePORTER" aria-label="Smart Search" aria-describedby="button-addon2" id="smartSearch"
                    v-model="clonedSmartSearch" ref="input" :disabled="loading"
                >
            </span>
            <div class="input-group-append">
                <button
                    class="btn btn-primary btn-lg"
                    type="button"
                    id="button-addon2"
                    @click.prevent="search"
                    :disabled="loading"
                >
                    Search
                    <div class="loader" v-if="loading"></div>
                </button>
            </div>
        </div>
        <transition name="slide-fade">
            <field-error
                v-if="error"
                :message="error"
                class="field-help-text"
            />
        </transition>
        <transition name="slide-fade">
            <div class="field-help-text" v-if="showHelpText && !error">
                {{helpText}}<br>{{helpTextTypeExamples}}<br />
                <button
                    class="btn btn-primary btn-lg btn-orange"
                    type="button"
                    @click.prevent="loadAdvancedSearch"
                >
                    Advanced Search
                </button>

            </div>
        </transition>
        <!-- <a href="/advanced-search" v-if="!showTitle" class="advanced-link" @click.prevent="goToPage('/advanced-search')">
            Advanced Search
        </a> -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FieldError from '@/components/searchFields/FieldError.vue';
import bus from '@/shared/event-bus.js';

export default {
    name: 'SmartSearch',
    components: {
        FieldError
    },
    props: {
        showTitle: {
            required: false
        },
        showHelpText: {
            required: false
        },
        fromPage: {
            required: true
        }
    },
    computed: {
        ...mapGetters([
            'searchCriteria',
            'searchType',
            'externalURLs'
        ])
    },
    data: function () {
        return {
            displayName: 'Smart Search',
            id: 'smart_search_text',
            helpText: 'Enter just about anything in the RePORTER Quick Search box above (text, PI names, project numbers, fiscal year, agency) or launch the Advanced Search to precisely configure searches using separate search fields.',
            // helpText: 'Search NIH projects and funding information:',
            helpTextTypeExamples: '',
            error: null,
            errorTimeout: null,
            loading: false,
            animationTime: 501,
            clonedSmartSearch: '' //dont directly map this to state, use a clone...
        };
    },
    watch: {
        'searchCriteria.smart_search_text': function (n, o) {
            if (this.searchCriteria && this.searchCriteria.smart_search_text) {
                this.clonedSmartSearch = _.clone(this.searchCriteria.smart_search_text.replace(/​/ig,''));
            }
        },
        '$store.state.searchResults': function (n, o) {
            this.loading = false;
        },
        '$store.state.graphData': function (n, o) {
            this.loading = false;
        }
    },
    // smart_search_text
    mounted () {
        // initialize field in store so that it will be watched
        if (!this.$store.state.searchCriteria[this.id]) {
            this.$store.state.searchCriteria[this.id] = '';
        }

        this.clonedSmartSearch = _.clone(this.searchCriteria.smart_search_text.replace(/​/ig,''));

        if (this.$store.state.editSearchCriteria[this.id]) { //copying back from editSearchCriteria, which appears to be stale...
            if (typeof this.$store.state.editSearchCriteria[this.id] === 'object' && this.$store.state.editSearchCriteria[this.id].length > 0) {
                this.$store.state.searchCriteria[this.id] = this.$store.state.editSearchCriteria[this.id].join(' ');
            } else {
                this.$store.state.searchCriteria[this.id] = this.$store.state.editSearchCriteria[this.id];
            }
            this.setSearchType('Smart');
        }
        // setup keyboard events on input field
        this.initEvents();

        if (this.$route.name === 'smartSearch' && this.$route.params && this.$route.params.queryString) {
            // encodeURIComponent('this is a test');
            let queryString = decodeURIComponent(this.$route.params.queryString);

            this.$store.state.searchCriteria[this.id] = queryString;
            this.search();
        }

        // if (!window.isMobile) {
        //     document.querySelector("input[type='text']").focus();
        // }
     },
     methods: {
        ...mapActions([
            'setSearchCriteria',
            'setSearchMeta',
            'setSearchResults',
            'setSearchType',
            'setCurrentQueryDetailsAction',
            'setSearchFacetCriteria',
            'setEditSearchCriteria'
        ]),

        initEvents () {
            this.$refs.input.addEventListener('keydown', this.handleInputEvent);
            this.$refs.input.addEventListener('paste', (event) => {
                this.handleInputEvent(event);
								
				gtag('event','Keyboard', {
					'event_category': 'Search Field',
					'event_label': 'Paste - ' + this.title,
					'value': 'Paste - ' + this.title
				});	
            });
        },

        handleInputEvent (event) {
             var $this = this,
                tempDomEle = document.createElement('div'),
                tempChilds,
                refinedText = '';

            setTimeout(function () {
                let thisData = $this.clonedSmartSearch ? $this.clonedSmartSearch : '';

                tempDomEle.innerHTML = copyData(thisData.replace("onerror", ""));

                tempChilds = tempDomEle.childNodes;
                for (let i = 0; i <tempChilds.length; i++) {
                    if (tempChilds[i].nodeType !== 1) {
                        refinedText += (tempChilds[i].textContent);
                    }
                }
                $this.clonedSmartSearch = refinedText.replace(/​/ig,'');


                // if press enter search!
                if (event.keyCode === 13) {
                    $this.search();
                }

                // set timeout so that value is udpated before parsing string
                setTimeout(() => {
                    // change tabs to spaces to prevent search errors,
                    // espaeically when pasting project numbers

                     if ($this.clonedSmartSearch) {
                        $this.clonedSmartSearch = $this.clonedSmartSearch.replace(/\t/g, ' ').replace(/​/ig,'');

                        // $this.updateSearchCriteria($this.$store.state.searchCriteria[$this.id]);
                    }
                }, 1);
            }, 1);
        },

        updateSearchCriteria (data) {
            this.setSearchCriteria({
                field: this.id,
                data: copyData(data)
            });
        },

        nukeSearchCriteria (criteriaToSave) {
            // go nuclear on search criteria
            this.$store.state.searchCriteria = {};

            if (criteriaToSave) {
                this.$store.state.searchCriteria[this.id] = criteriaToSave;
            }
        },

        search () {
            //remove the currentQueryDetails when doing a new search...
            this.setCurrentQueryDetailsAction(null);

            // var parsedCriteria = window.parseCriteria(this.searchCriteria),
            //     criteria = window.stringifySearchCriteriaFields(parsedCriteria);

            if(_.isEmpty(this.clonedSmartSearch))   {
                // let message = 'Please enter something to search: ' + this.helpTextTypeExamples;
                // this.setErrorMessage(message);
                this.setErrorMessage('Please enter something to search', 5000);
            }
            else if(this.clonedSmartSearch.length < 3) {
                this.setErrorMessage('Please enter at least 3 characters to search', 5000);
            }
            else if(this.clonedSmartSearch !== '') {
                let criteria = {};

                this.loading = true;

                this.$store.state.searchCriteria[this.id] = this.clonedSmartSearch || '';

                criteria[this.id] = this.$store.state.searchCriteria[this.id];

                this.nukeSearchCriteria(criteria[this.id]);

                this.setSearchFacetCriteria(null);
                this.setEditSearchCriteria(null);
				
				gtag('event', 'Click', {
					'event_category': 'Home',
					'event_label': 'Quick Search - Submit',
					'value': 'Quick Search - Submit'
				});		

                this.setSearchType('Smart');
                bus.$emit('clear:searchResults', {resetFacets: true});
                setTimeout(() => {
                    bus.$emit('update:searchResults', true, this.handleSearchResults);
                },1);
            }
        },

        setErrorMessage (message, timeout) {
            // reset error message so that it animates to get the users
                // attention if they keep clicking the search button
            this.error = null;

            setTimeout(() => {
                this.error = message;
            }, 1);

            if (timeout) {
                clearTimeout(this.errorTimeout);

                this.errorTimeout = setTimeout(() => {
                    this.error = null;
                }, timeout);
            }
        },

        handleSearchResults (data) {
            this.loading = false;

            if (data && data.meta && data.results) {
                this.setSearchMeta(data.meta);
                this.setSearchResults(data.results);

                let pathName = this.$route.name === 'home' || this.$route.name === '' ? '' : "/" + this.$route.name;
                this.$router.push('/search/' + data.meta.search_id + '/projects' + pathName);//?searchMode=' + this.searchType);
                bus.$emit('update:searchResultsData', data);
            } else {
                var smartSearchTextData = copyData(this.$store.state.searchCriteria[this.id]);
                this.$store.state.searchCriteria = copyData(this.$store.state.searchCriteriaDefault);
                this.$store.state.searchCriteria[this.id] = smartSearchTextData;
                this.setErrorMessage(this.externalURLs.reporter_project_search_error_message, 8000);
            }
        },

        goToPage (url) {
            if (event.ctrlKey || event.metaKey) {
                let routeData = this.$router.resolve({path: url});
                window.open(routeData.href, '_blank');
            } else {
                this.$router.push({
                    path: url
                });
            }
        },

        loadAdvancedSearch () {
            var sysAppMsgnodes = document.querySelectorAll('.collapse.sysAppMessage'),
                sysAppMsgnodesHt = 0,
                stepHeight = document.querySelector(".v-step") ? document.querySelector(".v-step").clientHeight : 0;

            for (var i = 0; i < sysAppMsgnodes.length; i++) {
                sysAppMsgnodesHt = sysAppMsgnodesHt + sysAppMsgnodes[i].clientHeight
            }
            setTimeout(() => {
                window.scrollTo(0, document.querySelectorAll('.search-tiles-container')[0].parentElement.offsetTop +  document.querySelectorAll('.home-charts')[0].offsetHeight + sysAppMsgnodesHt);
            }, this.animationTime);

            bus.$emit('load:advance-search');
            if (this.$route.path !== '/advanced-search') {
                this.$router.push('/advanced-search');
            }

        }
     }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import '@/scss/variables.scss';
@import 'node_modules/bootstrap/scss/_mixins';

.input-group {
    margin-bottom: 0.25rem;
}

.form-control {
    z-index: 1;
}

.input-group-append {
    z-index: 2;
}

.smartSearchContainer {
    span {
        flex: 1 1 auto;
    }
    .clearIcon {
        position: absolute;
        right: 10px;
        top: 15px;
        z-index: 100;
        color: grey;
        font-size: small;
        cursor: default;
    }

    /* clears the 'X' from Internet Explorer */
    input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
}

// .smart-search .btn-primary {
//     @include button-variant($yellow, $yellow);

//     color: #000;

//     &:hover,
//     &:focus {
//         background-color: #ffc226;
//         border-color: #ffc226;
//     }
// }
.btn-primary {
    &.disabled,
    &:disabled{
        opacity: 1;
        background-color: $blue-dark;
        border-color: $blue-dark;
    }
}

.btn-lg {
    padding-left: 1.75em;
    padding-right: 1.75em;
    @media screen and (max-width: $screen-sm) {
        padding-left: 0.75em;
        padding-right: 0.75em;
    }
}

.btn-orange {
    background-color: #c58100;
    &:active, &:hover {
        background-color: $yellow !important;
    }
}

.field-title {
    font-size: 28px;
    &:after {
        background-color: $yellow;
    }
}

.field-help-text {
    width: 100%;
    // padding-right: 35px;
}

.field-error-message {
    color: #fff;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: 0.3s;
}
// .slide-fade-leave-active {
//   transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
// }
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.loader {
    top: 6px;
    left: -34px;
    height: 26px;
    width: 26px;
}

.advanced-link {
    position: absolute;
    display: inline-block;
    margin-top: 5px;
    padding: 0.2em 0.5em;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2em;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 1em;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
    transition: 0.3s;

    &:hover,
    &:focus {
        color: $blue;
        background-color: #fff;
        text-decoration: none;
    }
}

</style>
