import {createStore} from 'vuex';
import { appSettingsService } from '@/shared/appsettings.service.js'



// global function used to parse both active
// Search Criteria and Default Search Critera
var parseCriteria = (criteria, state, payload) => {
    if (payload) {
        if (payload.data !== null && payload.data !== undefined) {
            // save search criteria for field passed in
            state[criteria][payload.field] = payload.data;
        } else if (payload.add) {
            state[criteria][payload.field] = state[criteria][payload.field].concat(payload.add);
        } else if (payload.remove) {
            for (let i = 0; i < payload.remove.length; i++) {
                state[criteria][payload.field] = state[criteria][payload.field].filter((item) => {
                    if (item.value !== payload.remove[i].value) {
                        return item;
                    }
                });
            }
        } else if (typeof payload === 'string') {
            state[criteria][payload.field] = payload;
        }
    } else {
        var searchFields = window.searchFieldSpecs,
            fieldObj;

        // reset search criteria if nothing is passed
        for (let field in state[criteria]) {
            fieldObj = _.filter(searchFields, searchFld => searchFld.id === field);
            if (typeof state[criteria][field] === 'string') {
                state[criteria][field] = fieldObj && fieldObj.length > 0 && fieldObj[0].defaultValues ? fieldObj[0].defaultValues : '';
            } else {
                state[criteria][field] = fieldObj && fieldObj.length > 0 && fieldObj[0].defaultValues ? fieldObj[0].defaultValues : [];
            }
        }
    }

    localStorage.setItem(criteria, JSON.stringify(payload));
};

var parseFilters = (criteria, state, payload) => {
    //for (let field in payload) {
    if (payload) {
        state[criteria] = {};

        for (let i = 0; i < payload.facets.length; i++) { // Need to update it
            state[criteria][payload.facets[i].name] = [];
        }
    } else {
        state[criteria] = payload;
    }
};

export default createStore({
    state: {
        searchCriteria: {
            // dynmically populated by search fields
        },
        searchCriteriaDefault: {
            // dynmically populated by search fields
        },
        editSearchCriteria: {

        },
        editSearchResults: false,
        searchFieldErrors: {
            // dynmically populated by search fields
        },
        searchMeta: null,
        searchResults: null,
        publicationResults: null,
        matchmakerResults: null,
        matchmakerPOResults: null,
        patentResults: null,
        clinicalStudyResults: null,
        newsResults: {
            PressRelease: null,
            ResearchMatters: null,
            AIRS: null,
            ARRA: null,
            AIMRS: null
        },
        newsCount: {
            PressRelease: null,
            ResearchMatters: null,
            AIRS: null,
            ARRA: null,
            AIMRS: null
        },
        lookup: {
            // dynmically populated by search fields
        },
        fullscreen: false,
        externalURLs: {},
        searchFacetCriteria: null,
        searchFacetData: null,
        publicationsFacetCriteria: null,
        publicationsFacetData: null,
        coreProjCountPub: null,
        matchmakerFacetCriteria: null,
        matchmakerFacetData: null,
        searchMode: '',
        graphData: null,
        circlesData: null,
        graphSettings: null,
        searchType: '',
        exportCriteria: '',
        projectDetails: {
            basicInfo: null,
            description: null,
            otherDetails: null,
            funding: null,
            spendingCategories: null,
            publications: null,
            patents: null,
            history: null,
            subProjects: null,
            projectOutcomes: null,
            clinicalStudies: null,
            similarProjects: null,
            PressRelease: null,
            ResearchMatters: null,
            AIRS: null,
            ARRA: null,
            AIMRS: null
            // dynmically populated by search fields
        },
        exportFields: null,
        isExportingFile: false,
        currentExportTab: '',
        defaultFieldsToExport: [],
        piSummaryProjects: null,
        homePageChartData: null,
        homePageMapData: null,
        selectedPerson: null,
        selectedRowId: null,
        noDataErrorMsg: 'No records found matching your search criteria. Please alter your query and try again.',
        invalidProjectIdMsg: 'The data you requested is not available in RePORTER.',
        currentQueryDetails: null,
        loggedInUser: null,
        savedSearches: null,
        userPreferences: null,
        homeMapSearchId:null,
        homeChartSearchId:null
    },
    getters: {
        searchCriteria: state => state.searchCriteria,
        searchCriteriaDefault: state => state.searchCriteriaDefault,
        editSearchCriteria: state => state.editSearchCriteria,
        editSearchResults: state => state.editSearchResults,
        searchFieldErrors: state => state.searchFieldErrors,
        searchMeta: state => state.searchMeta,
        searchResults: state => state.searchResults,
        searchResultsTableMeta: state => state.searchResultsTableMeta,
        publicationResults: state => state.publicationResults,
        matchmakerResults: state => state.matchmakerResults,
        matchmakerPOResults: state => state.matchmakerPOResults,
        patentResults: state => state.patentResults,
        clinicalStudyResults: state => state.clinicalStudyResults,
        newsResults: state => state.newsResults,
        newsCount: state => state.newsCount,
        lookup: state => state.lookup,
        fullscreen: state => state.fullscreen,
        externalURLs: state => state.externalURLs,
        searchFacetCriteria: state => state.searchFacetCriteria,
        searchFacetData: state => state.searchFacetData,
        publicationsFacetCriteria: state => state.publicationsFacetCriteria,
        publicationsFacetData: state => state.publicationsFacetData,
        coreProjCountPub: state => state.coreProjCountPub,
        matchmakerFacetCriteria: state => state.matchmakerFacetCriteria,
        matchmakerFacetData: state => state.matchmakerFacetData,
        searchMode: state => state.searchMode,
        graphData: state => state.graphData,
        circlesData: state => state.circlesData,
        graphSettings: state => state.graphSettings,
        searchType: state => state.searchType,
        exportCriteria: state => state.exportCriteria,
        projectDetails: state => state.projectDetails,
        exportFields: state => state.exportFields,
        isExportingFile: state => state.isExportingFile,
        currentExportTab: state => state.currentExportTab,
        defaultFieldsToExport: state => state.defaultFieldsToExport,
        piSummaryProjects: state => state.piSummaryProjects,
        homePageChartData: state => state.homePageChartData,
        homePageMapData: state => state.homePageMapData,
        selectedPerson: state => state.selectedPerson,
        selectedRowId: state => state.selectedRowId,
        noDataErrorMsg: state => state.noDataErrorMsg,
        invalidProjectIdMsg: state => state.invalidProjectIdMsg,
        currentQueryDetails: state => state.currentQueryDetails,
        loggedInUser: state => state.loggedInUser,
        savedSearches: state => state.savedSearches,
        userPreferences: state => state.userPreferences,
        homeChartSearchId: state => state.homeChartSearchId,
        homeMapSearchId: state => state.homeMapSearchId,
    },
    mutations: {
        setSearchCriteria (state, payload) {
            parseCriteria('searchCriteria', state, payload);
        },
        setSearchCriteriaDefault (state, payload) {
            parseCriteria('searchCriteriaDefault', state, payload);
        },
        setEditSearchCriteria (state, payload) {
            parseCriteria('editSearchCriteria', state, payload);
        },
        setEditSearchResultsFlag (state, payload) {
            state.editSearchResults = payload;
        },
        setSearchFieldErrors (state, payload) {

            if (payload.message) {
                state.searchFieldErrors[payload.field] = {
                    message: payload.message,
                    lookupFailed: payload.lookupFailed,
                    preventSearch: payload.preventSearch
                };
            } else {
                state.searchFieldErrors[payload.field] = {
                    message: '',
                    lookupFailed: false,
                    preventSearch: false
                };
            }

            localStorage.setItem('searchFieldErrors', JSON.stringify(payload));
        },
        setSearchMeta (state, payload) {
            state.searchMeta = payload;

            localStorage.setItem('searchMeta', JSON.stringify(payload));
        },
        setSearchResults (state, payload) {
            state.searchResults = payload;

            localStorage.setItem('searchResults', JSON.stringify(payload));
        },
        setPublicationResults (state, payload) {
            state.publicationResults = payload;
        },
        updatePublicationResults (state, payload) {
            state.publicationResults.results = state.publicationResults.results.concat(payload.results);
        },
        setMatchmakerResults (state, payload) {
            state.matchmakerResults = payload;

            localStorage.setItem('matchmakerResults', JSON.stringify(payload));
        },
        updateMatchmakerResults (state, payload) {
            state.matchmakerResults.results = state.matchmakerResults.results.concat(payload.results);

            localStorage.setItem('matchmakerResults', JSON.stringify(state.matchmakerResults));
        },
        setMatchmakerPOResults (state, payload) {
            state.matchmakerPOResults = payload;

            localStorage.setItem('matchmakerPOResults', JSON.stringify(payload));
        },
        updateMatchmakerPOResults (state, payload) {
            state.matchmakerPOResults.results = state.matchmakerPOResults.concat(payload.results);

            localStorage.setItem('matchmakerPOResults', JSON.stringify(state.matchmakerPOResults));
        },
        setPatentResults (state, payload) {
            state.patentResults = payload;

            localStorage.setItem('patentResults', JSON.stringify(payload));
        },
        updatePatentResults (state, payload) {
            state.patentResults.results = state.patentResults.results.concat(payload.results);

            localStorage.setItem('patentResults', JSON.stringify(state.patentResults));
        },
        setClinicalStudyResults (state, payload) {
            state.clinicalStudyResults = payload;

            localStorage.setItem('clinicalStudyResults', JSON.stringify(payload));
        },
        updateClinicalStudyResults (state, payload) {
            state.clinicalStudyResults.results = state.clinicalStudyResults.results.concat(payload.results);

            localStorage.setItem('clinicalStudyResults', JSON.stringify(state.clinicalStudyResults));
        },
        setNewsResults (state, payload) {
            if (payload && payload.name && payload.data) {
                state.newsResults[payload.name] = payload.data;
            }
        },
        updateNewsResults (state, payload) {
            if (payload && payload.name && payload.data) {
                state.newsResults[payload.name].meta.properties = payload.data.meta.properties;
                state.newsResults[payload.name].results = state.newsResults[payload.name].results.concat(payload.data.results);
            }
        },
        updateMetaNewsResults (state, payload) {
            if (payload && payload.name && payload.data) {
                state.newsResults[payload.name].meta = payload.data.meta;
            }
        },
        setNewsCount (state, payload) {
            if (payload && payload.name && payload.data) {
                state.newsCount[payload.name] = {
                    total: payload.data.results[0],
                    totalCoreProjects: payload.data.meta.total,
                    lastItem: payload.data.meta.properties ? payload.data.meta.properties.lastItem : null
                };
            }
        },
        resetNewsResults (state, payload) {
            state.newsResults = {
                PressRelease: payload,
                ResearchMatters: payload,
                AIRS: payload,
                ARRA: payload,
                AIMRS: payload
            };
            state.newsCount = {
                PressRelease: payload,
                ResearchMatters: payload,
                AIRS: payload,
                ARRA: payload,
                AIMRS: payload
            };
        },
        updateSearchResults (state, payload) {
            state.searchResults = state.searchResults.concat(payload);
            localStorage.setItem('searchResults', JSON.stringify(payload));
        },
        setSearchResultsTableMeta (state, payload) {
            state.searchResultsTableMeta = payload;

            localStorage.setItem('searchResultsTableMeta', JSON.stringify(payload));
        },
        setLookup (state, payload) {
            if (payload && payload.field && payload.data) {
                state.lookup[payload.field] = payload.data;

                localStorage.setItem('Lookup', JSON.stringify(payload));
            }
        },
        setFullscreen (state, payload) {
            state.fullscreen = payload;

            localStorage.setItem('searchResultsTableMeta', JSON.stringify(payload));
        },
        addPropertyToAppSettings (state, payload) {
            if (payload && payload.data) {
                _.merge(state.externalURLs, payload.data);
            }
        },
        setSearchFacetCriteria (state, payload) {
            parseFilters('searchFacetCriteria', state, payload);
        },
        updateSearchFacetCriteria (state, payload) {
            if (payload && payload.field && payload.data) {
                state.searchFacetCriteria[payload.field] = payload.data;
            }
        },
        setSearchFacetData (state, payload) {
            state.searchFacetData = payload;
        },
        updateSearchResultsFacets (state, payload) {
            state.searchFacetCriteria = payload;
        },
        setPublicationsFacetCriteria (state, payload) {
            parseFilters('publicationsFacetCriteria', state, payload);
        },
        setPublicationsFacetData (state, payload) {
            state.publicationsFacetData = payload;
        },
        updatePublicationsFacets (state, payload) {
            state.publicationsFacetCriteria = payload;
        },
        setCoreProjCountPub (state, payload) {
            state.coreProjCountPub = payload;
        },
        setMatchmakerFacetCriteria (state, payload) {
            parseFilters('matchmakerFacetCriteria', state, payload);
        },
        setMatchmakerFacetData (state, payload) {
            state.matchmakerFacetData = payload;
        },
        updateMatchmakerFacetCriteria (state, payload) {
            state.matchmakerFacetCriteria = payload;
        },
        updatePublicationsFacetCriteria (state, payload) {
            state.publicationsFacetCriteria = payload;
        },
        setSearchMode (state, payload) {
            state.searchMode = payload;
        },
        setGraphData (state, payload) {
            state.graphData = payload;
        },
        setCirclesData (state, payload) {
            state.circlesData = payload;
        },
        updateGraphData (state, payload) {
            if (state.graphData) {
                state.graphData.meta = payload.meta;
                state.graphData.results = state.graphData.results.concat(payload.results);
            } else {
                state.graphData = payload;
            }
        },
        setGraphSettings (state, payload) {
            state.graphSettings = payload;
        },
        setSearchType (state, payload) {
            state.searchType = payload;
        },
        setExportCriteria (state, payload) {
            state.exportCriteria = payload;
        },
        setProjectDetails (state, payload) {
            if (payload && payload.name && payload.data) {
                state.projectDetails[payload.name] = payload.data;
            }
            // state.projectDetails = payload;
        },
        resetProjectDetails (state, payload) {
            state.projectDetails = {
                basicInfo: payload,
                description: payload,
                otherDetails: payload,
                funding: payload,
                spendingCategories: payload,
                publications: payload,
                patents: payload,
                history: payload,
                subProjects: payload,
                projectOutcomes: payload,
                clinicalStudies: payload,
                similarProjects: payload,
                PressRelease: payload,
                ResearchMatters: payload,
                AIRS: payload,
                ARRA: payload,
                AIMRS: payload
                // dynmically populated by search fields
            }
            // state.projectDetails = payload;
        },
        updateProjectDetails (state, payload) {
            if (payload && payload.name && payload.data) {
                state.projectDetails[payload.name].results = state.projectDetails[payload.name].results.concat(payload.data.results);

                localStorage.setItem('projectDetails', JSON.stringify(payload));
            }
        },
        setExportFields (state, payload) {
            state.exportFields = payload;
        },
        setExportingFileFlag (state, payload) {
            state.isExportingFile = payload;
        },
        setCurrentExportTab (state, payload) {
            state.currentExportTab = payload;
        },
        setDefaultfieldsToExport (state, payload) {
            state.defaultFieldsToExport = payload;
        },
        setPersonSummaryProjects (state, payload) {
            state.piSummaryProjects = payload;
        },
        setHomePageChartData (state, payload) {
            state.homePageChartData = payload;
        },
        setHomePageMapData (state, payload) {
            state.homePageMapData = payload;
        },
        setSelectedPerson (state, payload) {
            state.selectedPerson = payload;
        },
        setSelectedRowId (state, payload) {
            state.selectedRowId = payload;
        },
        setCurrentQueryDetails (state, payload) {
            state.currentQueryDetails = payload;
        },
        setLoggedInUser (state, payload) {
            state.loggedInUser = payload;
        },
        setSavedSearches (state, payload) {
            state.savedSearches = payload;
        },
        setUserPreferences (state, payload) {
            state.userPreferences = payload;
        },
        updateUserPreferences (state, payload) {
            let prefIndex = _.findIndex(state.userPreferences, up => up.preference_name === payload.name);

            if (prefIndex != -1) {
                state.userPreferences[prefIndex].preference_value = payload.value;
            }
        },
        setHomeMapSearchId (state, payload) {
            state.homeMapSearchId = payload;
        },
        setHomeChartSearchId (state, payload) {
            state.homeChartSearchId = payload;
        }
    },
    actions: {
        setSearchCriteria ({ commit }, payload) {
            commit('setSearchCriteria', payload);
        },
        setSearchCriteriaDefault ({ commit }, payload) {
            commit('setSearchCriteriaDefault', payload);
        },
        setEditSearchCriteria ({ commit }, payload) {
            commit('setEditSearchCriteria', payload);
        },
        setEditSearchResultsFlag ({ commit }, payload) {
            commit('setEditSearchResultsFlag', payload);
        },
        setSearchFieldErrors ({ commit }, payload) {
            commit('setSearchFieldErrors', payload);
        },
        setSearchMeta ({ commit }, payload) {
            commit('setSearchMeta', payload);
        },
        setSearchResults ({ commit }, payload) {
            commit('setSearchResults', payload);
        },
        setMatchmakerResults ({ commit }, payload) {
            commit('setMatchmakerResults', payload);
        },
        updateMatchmakerResults ({ commit }, payload) {
            commit('updateMatchmakerResults', payload);
        },
        setMatchmakerPOResults ({ commit }, payload) {
            commit('setMatchmakerPOResults', payload);
        },
        updateMatchmakerPOResults ({ commit }, payload) {
            commit('updateMatchmakerPOResults', payload);
        },
        setPublicationResults ({ commit }, payload) {
            commit('setPublicationResults', payload);
        },
        updatePublicationResults ({ commit }, payload) {
            commit('updatePublicationResults', payload);
        },
        setPatentResults ({ commit }, payload) {
            commit('setPatentResults', payload);
        },
        updatePatentResults ({ commit }, payload) {
            commit('updatePatentResults', payload);
        },
        setClinicalStudyResults ({ commit }, payload) {
            commit('setClinicalStudyResults', payload);
        },
        updateClinicalStudyResults ({ commit }, payload) {
            commit('updateClinicalStudyResults', payload);
        },
        setNewsResults ({ commit }, payload) {
            commit('setNewsResults', payload);
        },
        updateNewsResults ({ commit }, payload) {
            commit('updateNewsResults', payload);
        },
        updateMetaNewsResults ({ commit }, payload) {
            commit('updateMetaNewsResults', payload);
        },
        setNewsCount ({ commit }, payload) {
            commit('setNewsCount', payload);
        },
        resetNewsResults ({ commit }, payload) {
            commit('resetNewsResults', payload);
        },
        updateSearchResults ({ commit }, payload) {
            commit('updateSearchResults', payload);
        },
        setSearchResultsTableMeta ({ commit }, payload) {
            commit('setSearchResultsTableMeta', payload);
        },
        setLookup ({ commit }, payload) {
            commit('setLookup', payload);
        },
        setFullscreen ({ commit }, payload) {
            commit('setFullscreen', payload);
        },
        addPropertyToAppSettings ({ commit }, payload) {
            commit('addPropertyToAppSettings', payload);
        },
        async getAppSettingsAction ({ commit }) {
            let _appSettings = await appSettingsService.getAppSettings(); //axios call
            commit('setAppSettings', _appSettings);
        },
        async getAppSettingsPropertyByNameAction ({ commit }, payload) {
            let data = await appSettingsService.getPropertyByName(payload); //axios call
            commit('addPropertyToAppSettings', { data });
        },
        setSearchFacetCriteria ({ commit }, payload) {
            commit('setSearchFacetCriteria', payload);
        },
        updateSearchFacetCriteria ({ commit }, payload) {
            commit('updateSearchFacetCriteria', payload);
        },
        setSearchFacetData ({ commit }, payload) {
            commit('setSearchFacetData', payload);
        },
        updateSearchResultsFacets ({ commit }, payload) {
            commit('updateSearchResultsFacets', payload);
        },
        setPublicationsFacetCriteria ({ commit }, payload) {
            commit('setPublicationsFacetCriteria', payload);
        },
        setPublicationsFacetData ({ commit }, payload) {
            commit('setPublicationsFacetData', payload);
        },
        updatePublicationsFacets ({ commit }, payload) {
            commit('updatePublicationsFacets', payload);
        },
        setCoreProjCountPub ({ commit }, payload) {
            commit('setCoreProjCountPub', payload);
        },
        setMatchmakerFacetCriteria ({ commit }, payload) {
            commit('setMatchmakerFacetCriteria', payload);
        },
        setMatchmakerFacetData ({ commit }, payload) {
            commit('setMatchmakerFacetData', payload);
        },
        updateMatchmakerFacetCriteria ({ commit }, payload) {
            commit('updateMatchmakerFacetCriteria', payload);
        },
        updatePublicationsFacetCriteria ({ commit }, payload) {
            commit('updatePublicationsFacetCriteria', payload);
        },
        setSearchMode ({ commit }, payload) {
            commit('setSearchMode', payload);
        },
        setGraphData ({ commit }, payload) {
            commit('setGraphData', payload);
        },
        setCirclesData ({ commit }, payload) {
            commit('setCirclesData', payload);
        },
        updateGraphData ({ commit }, payload) {
            commit('updateGraphData', payload);
        },
        setGraphSettings ({ commit }, payload) {
            commit('setGraphSettings', payload);
        },
        setSearchType ({ commit }, payload) {
            commit('setSearchType', payload);
        },
        setExportCriteria ({ commit }, payload) {
            commit('setExportCriteria', payload);
        },
        setProjectDetails ({ commit }, payload) {
            commit('setProjectDetails', payload);
        },
        resetProjectDetails ({ commit }, payload) {
            commit('resetProjectDetails', payload);
        },
        updateProjectDetails ({ commit }, payload) {
            commit('updateProjectDetails', payload);
        },
        setExportFields ({ commit }, payload) {
            commit('setExportFields', payload);
        },
        setExportingFileFlag ({ commit }, payload) {
            commit('setExportingFileFlag', payload);
        },
        setCurrentExportTab ({ commit }, payload) {
            commit('setCurrentExportTab', payload);
        },
        setDefaultfieldsToExport ({ commit }, payload) {
            commit('setDefaultfieldsToExport', payload);
        },
        setPersonSummaryProjects ({ commit }, payload) {
            commit('setPersonSummaryProjects', payload);
        },
        setHomePageChartData ({ commit }, payload) {
            commit('setHomePageChartData', payload);
        },
        setHomePageMapData ({ commit }, payload) {
            commit('setHomePageMapData', payload);
        },
        setSelectedPerson ({ commit }, payload) {
            commit('setSelectedPerson', payload);
        },
        setSelectedRowId ({ commit }, payload) {
            commit('setSelectedRowId', payload);
        },
        setCurrentQueryDetailsAction ({ commit }, payload) {
            commit('setCurrentQueryDetails', payload);
        },
        setLoggedInUser ({ commit }, payload) {
            commit('setLoggedInUser', payload);
        },
        setSavedSearches ({ commit }, payload) {
            commit('setSavedSearches', payload);
        },
        setUserPreferences ({ commit }, payload) {
            commit('setUserPreferences', payload);
        },
        updateUserPreferences ({ commit }, payload) {
            commit('updateUserPreferences', payload);
        },
        setHomeMapSearchId ({ commit }, payload) {
            commit('setHomeMapSearchId', payload);
        },
        setHomeChartSearchId ({ commit }, payload) {
            commit('setHomeChartSearchId', payload);
        }
    }
});
