// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../static/img/header-background-m.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row-header[data-v-fc85597c]{-webkit-transition:.3s;transition:.3s}.row-full[data-v-fc85597c]{max-width:none}.navigation[data-v-fc85597c]{position:absolute;top:0;left:0;z-index:50;width:100%;background:#02243d;background:-webkit-gradient(linear,left top,right top,from(rgba(3,24,53,.45)),to(rgba(2,36,61,.25)));background:linear-gradient(90deg,rgba(3,24,53,.45) 0,rgba(2,36,61,.25))}.navigation.dark-theme[data-v-fc85597c]{background:-webkit-gradient(linear,left top,right top,color-stop(1%,#063c63),color-stop(50%,#1c496f),color-stop(75%,#347a7d),to(#507b82));background:linear-gradient(90deg,#063c63 1%,#1c496f 50%,#347a7d 75%,#507b82)}.navbar[data-v-fc85597c]{padding-left:5px;padding-right:5px;width:100%;color:#fff;font-family:Helvetica Neue,Helvetica,Arial,sans-serif}.navbar a[data-v-fc85597c]{color:#fff;text-decoration:none}.navbar-brand[data-v-fc85597c]{font-size:24px;font-weight:500}.logo-text[data-v-fc85597c],.navbar-brand svg[data-v-fc85597c]{vertical-align:middle}.logo-text[data-v-fc85597c]{display:inline-block;padding:0 .5em .1em .25em}.navbar-collapse[data-v-fc85597c]{text-align:right;position:relative}.navbar-collapse.show div[data-v-fc85597c]:after{content:\"\";background-color:#1f5581;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center 0;background-size:cover;opacity:1;top:0;left:-5px;bottom:0;right:0;position:absolute;z-index:-1;height:105%;width:105%}.logo[data-v-fc85597c]{display:inline-block;width:64px;height:40px;fill:#fff}@media screen and (max-width:768px){.link-report .logo-text[data-v-fc85597c]{display:none}}.logo-divider[data-v-fc85597c]{display:inline-block;margin-right:.4em;width:15px;height:40px;fill:hsla(0,0%,100%,.5)}@media screen and (max-width:768px){.logo-divider[data-v-fc85597c]{display:none}}.navbar-dark .navbar-nav .nav-link[data-v-fc85597c]{font-size:20px;color:#fff}.nav-item+.nav-item[data-v-fc85597c]{margin-left:1em}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
