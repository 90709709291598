<template>
    <div class="field-error-message">
        {{message}}
        <div class="error-code" v-if="errorCode">{{errorCode}}</div>
    </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'FieldError',
    // components: {
    // },
    props: {
        message: {
            required: false
        },
        errorCode: {
            required: false
        }
    },
    // computed: {
    // },
    // data: function () {
    //     return {
    //     };
    // },
    // mounted () {

    // },
    // methods: {

    // }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

// .field-error-message {
//     display: block;
//     width: 100%;
//     // padding: 0.25em 0;
//     margin-top: 5px;
//     color: #cc4545;
//     font-weight: 500;
//     // background-color: rgb(240, 214, 214);
//     border-radius: 4px;
// }

</style>