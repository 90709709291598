<template>
    <ul class="multiselect__list">
        <li
            v-for="(item, index) in filterData(data)"
            :key="id + '-' + nestingIndex + '-' + index + '-' + item.value.replace(/\s/g,'')"
            :data-value="item.value"
            class="multiselect__list__item"
            :class="isSelected(item.value) ? 'selected' : ''"
            :title="getToolTipText(item)"
        >
            <button
                v-if="item.children_values && item.children_values.length !== 0"
                @click.stop="expandCollapse(index)"
                tabindex="-1"
                class="multiselect__expand_toggle"
                :class="expanded[index] || topParent.searchText ? 'expanded' : ''"
            >
				<i class="fas fa-chevron-right multiselect__expand_toggle__icon"></i>                
                <span class="sr-only">+</span>
            </button>
            <button
                :id="id + '-' + nestingIndex + '-' + index + '-' + item.value.replace(/\s/,'')"
                @click="selectDeselect(item)"
                tabindex="-1"
                class="multiselect__list__item_button"
                :class="item.children_values && item.children_values.length !== 0 ? 'has-children' : ''"
            >
                <!-- :class="item.children_values && item.children_values.length !== 0 ? 'has-children' : ''" -->
                {{item.display}}
            </button>
            <!-- <transition name="slide-fade"> -->
            <transition-collapse-height>
                <multi-select-list
                    v-if="item.children_values && item.children_values.length !== 0 && (expanded[index] || topParent.searchText)"
                    :id="id"
                    :data="getChildren(item, item.children_values)"
                    :topParent="topParent"
                    :nestingIndex="nestingIndex + 1"
                />
                    <!-- v-show="expanded[index] || topParent.searchText" -->
            </transition-collapse-height>
            <!-- </transition> -->
        </li>
    </ul>
</template>

<script>
// import { mapGetters } from 'vuex';
import MultiSelectList from '@/components/searchFields/MultiSelectList.vue'
import transitionCollapseHeight from '@/components/searchFields/transitionCollapseHeight.vue'

export default {
    name: 'MultiSelectList',
    components: {
        MultiSelectList,
        transitionCollapseHeight
    },
    props: {
        topParent: {
            required: true
        },
        id: {
            required: true
        },
        data: {
            required: true
        },
        nestingIndex: {
            required: false,
            default: 0
        }
        // selected: {
        //     required: true
        // }
    },
    computed: {
        // ...mapGetters([
        //     'searchCriteria'
        // ])
    },
    data: function () {
        return {
            expanded: [],
            expandIconSize: 23,
            nestingIndent: 18
        };
    },
    // watch: {
    //     data: function () {
    //         this.$nextTick(() => {
    //             this.setIndent();
    //         });
    //     },
    // },
    mounted () {
        // update nested lists collapse to open
        // may need to make configurable in the future
        if ((this.topParent.ui && !this.topParent.ui.collapseAll) || !this.topParent.ui) {
            for (let i = 0; i < this.$el.children.length; i++) {
                this.expanded[i] = true;
            }
        }

        // this.setIndent();
    },
    methods: {
        getToolTipText (item) {
            if (item && item.properties) {
                if (item.properties.Description) {
                    return item.properties.Description;
                }
            }
            return '';
        },

        expandCollapse (index) {
            // if (index === undefined) {
            //     this.expanded[index] = false;
            // }

            // use Vue set method or v-show will not update
            // as Vue is not watching these dynamic variables
            this.expanded[index]=!this.expanded[index];

            this.topParent.focusInput();
        },

        setIndent () {
            let children = this.$el.children;

            for (let i = 0; i < children.length; i++) {
                let itemChildren = children[i].children;

            for (let i2 = 0; i2 < itemChildren.length; i2++) {
                    if (itemChildren[i2].className.indexOf('multiselect__list__item_button') !== -1) {
                        itemChildren[i2].style.paddingLeft = (this.expandIconSize + this.nestingIndent * this.nestingIndex) + 'px';
                    }

                    if (itemChildren[i2].className.indexOf('multiselect__expand_toggle') !== -1) {
                        itemChildren[i2].style.left = (this.nestingIndent * this.nestingIndex) + 'px';
                    }
                }
            }
        },

        isSelected (value) {
            // console.log(this.$store.state.searchCriteria[this.id]);
            if (this.$store.state.searchCriteria && this.$store.state.searchCriteria[this.id]) {
                return this.$store.state.searchCriteria[this.id].some((item) => {
                    if (item) {
                    if ((item.value && item.value === value) || item === value) {
                        // console.log(item.value, value);
                        return true;
                    }
                    if (item.value && item.value.indexOf(';') !== -1 && value.indexOf(item.value) !== -1) {
                        return true;
                    }
                    }
                });
            }

            return false;
        },

        filterData (data) {
            var newData = [];

            if (this.nestingIndex === 0) {
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].parent_value) {
                        newData.push(data[i]);
                    }
                }
            } else {
                newData = data;
            }

            this.$nextTick(() => {
                this.initFocusEvents();
            });

            return newData;
        },

        initFocusEvents () {
            let buttons,
                button;

            for (let i = 0; i < this.$el.childElementCount; i++) {
                buttons = this.$el.children[i].getElementsByTagName('button');

                for (let i2 = 0; i2 < buttons.length; i2++) {
                    button = buttons[i2];

                    if (button.classList.length === 0 || button.className.indexOf('init') === -1) {
                        button.addEventListener('focus', () => {
                            this.focus(this.id);
                        });

                        button.addEventListener('blur', () => {
                            this.blur(this.id);
                        });

                        button.addEventListener('keydown', (event) => {
                            var key = String.fromCharCode(event.keyCode);
                            // console.log(event);

                            if (event.keyCode === 13) {
                                event.preventDefault();
                                event.stopPropagation();
                            }

                            // switch (event.keyCode) {
                            //     case 37: // left
                            //         this.
                            //         break;
                            //     case 39: // right

                            //         break;
                            // }

                            // check if input is letter, number, hyphen, underscore, or space
                            if (this.topParent.allowedCharacters.test(key)) {
                                // focus input to filter list
                                this.topParent.focusInput();
                            } else {
                                // event.preventDefault();
                                // event.stopPropagation();

                                this.topParent.handleKeyboardEvents(event);
                                // switch (e.keyCode) {
                                //     case 8: // backspace/delete
                                //     case 9: // tab
                                //         break;

                                //     default:
                                //         e.preventDefault();
                                //         e.stopPropagation();
                                //         this.topParent.handleKeyboardEvents(e.keyCode);
                                //         break;
                                // }
                            }
                        });

                        button.className += ' init';
                    }
                }
            }
        },

        getChildren (item, children_values) {
            return this.topParent.getChildren(item, children_values);
        },

        selectDeselect (item) {
            this.topParent.selectDeselect(item);
        },

        focus (id) {
            this.topParent.setFocus(id);
        },

        blur (id) {
            this.topParent.removeFocus(id);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

$expand-button-size: 1.5em;
$expand-arrow-color: #99aed8;
// $expand-arrow-color: $green-dark;
// $indent-line-color: $blue-lighter;
$indent-line-color: #f2f2f2;
// $indent-line-color: $green-light;

.multiselect__list {
    list-style: none;
    padding: 0;
    margin: 0;

    .multiselect__list__item {
        // do not make position relative, or it breaks scrolling for keyboard accessibility
        // position: relative;
        font-size: 14px;

        .multiselect__list__item_button {
            position: relative;
            display: inline-block;
            padding: 0.25em 0.75em 0.25em $expand-button-size;
            width: 100%;
            border: none;
            background: none;
            text-align: left;
            line-height: 1.2em;
            font-weight: 500;
            z-index: 1;

            // white-space: nowrap;

            // &:after {
            //     float: left;
            //     content: "";
            //     width: 2px;
            //     height: 100%;
            //     background-color: blue;
            // }

            // &.has-children {
            //     // width: calc(100% - #{$expand-button-size});
            //     // padding-left: 0;
            //     padding-left: $expand-button-size;
            // }

            &.active,
            &:hover,
            &:focus {
                background-color: $blue-lighter;
                // background-color: #f7f7f7;
                // color: $blue;
            }
        }

        &.selected {
            .multiselect__list__item_button {
                background-color: $blue;
                color: #fff;

                &.active,
                &:hover,
                &:focus {
                    background-color: $blue-light;
                }
            }

            .multiselect__expand_toggle {
                .multiselect__expand_toggle__icon {
                    color: #fff;
                }
            }
        }

        .multiselect__list__item {
            // padding-left: 1.5em;
            margin-left: 1.5em;

            &:first-child {
                > .multiselect__list__item_button {
                    &:after {
                        top: 0.25em;
                        height: calc(100% - 0.25em);
                    }
                }
            }

            &:last-child {
                > .multiselect__list__item_button {
                    &:after {
                        height: calc(100% - 0.25em);
                    }
                }
            }

            .multiselect__list__item_button {
                padding: 0.25em 0.75em 0.25em 1em;
                font-weight: 400;
                // border-left: 3px solid $blue-lighter;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 3px;
                    height: 100%;
                    background-color: $indent-line-color;
                }

                &.has-children {
                    // padding-left: 1.25em;
                    padding-left: 1.5em;
                    // border-left-color: transparent;

                    &:after {
                        display: none;
                        // top: 0.25em;
                        // height: calc(100% - 0.5em);
                    }
                }

                &.active,
                &:hover,
                &:focus {
                    &:after {
                        top: 0;
                        height: 100%;
                        background-color: $blue-light;
                        // background-color: $expand-arrow-color;
                    }
                }

                // &.active {
                //     &:after {
                //         background-color: $blue;
                //     }
                // }
                // white-space: inherit;
            }



            &:first-child:last-child {
                > .multiselect__list__item_button {
                    &:after {
                        top: 0.25em;
                        height: calc(100% - 0.5em);
                    }
                }
            }

            &.selected {
            // .multiselect__list__item {
                .multiselect__list__item_button {
                    &:after {
                        top: 0;
                        height: 100%;
                        background-color: $blue-light;
                    }

                    &.active,
                    &:hover,
                    &:focus {
                        &:after {
                            background-color: $blue;
                        }
                    }
                }
            }

            // .multiselect__list__item_button {
            // }
        }

        // &.selected {
        //     .multiselect__expand_toggle {
        //         .multiselect__expand_toggle__icon {
        //             fill: #fff;
        //         }
        //     }
        // }
    }
}

.multiselect__expand_toggle {
    display: block;
    position: relative;
    // top: 0;
    // left: 0;
    margin-bottom: -$expand-button-size;
    padding: 0 0.25em;
    width: $expand-button-size;
    height: $expand-button-size;
    // height: 100%;
    // background-color: #eee;
    background: none;
    border: none;
    // border-radius: 50%;
    outline: none;
    z-index: 5;

    .multiselect__expand_toggle__icon {
        width: 100%;
        height: 100%;
        transition: 0.3s;
        color: $expand-arrow-color;
        // &.expanded {
        //     transform: rotate(90deg);
        // }
    }

    &.expanded {
        .multiselect__expand_toggle__icon {
            transform: rotate(90deg);
        }
    }

    &:hover,
    &:focus {
        .multiselect__expand_toggle__icon {
            color: $blue-light;
        }
        ~ .multiselect__list__item_button {
            background-color: $blue-lighter;
        }
    }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: 0.3s;
}
.slide-fade-leave-active {
  transition: 0.3s;
}
.slide-fade-enter,
.slide-fade-leave-to {
    opacity: 0;
    height: 0;
}
.slide-fade-enter {
    transform: translateY(-10px);
}
.slide-fade-leave-to {
  transform: translateY(-10px);
}

</style>