<template>
    <div class="search-field option__control">
        <div  v-if="topParent">
            <!-- checkbox or radio additional field -->
            <template v-for="(obj, index) in topParent.additionalControl.options" v-bind:key="index">
                <div class="form-check form-check-inline mt-1" :class="{'custom-radio': topParent.additionalControl.controlType === 'radio', 'form-check': topParent.additionalControl.controlType === 'checkbox'}">
                    <input
                        v-if="topParent.additionalControl.controlType !== 'radio'"
                        class="form-check-input"
                        :type="topParent.additionalControl.controlType"
                        :name="obj.name" :id="obj.id+index"
                        :disabled="dependentFieldHasData"
                        v-model="$store.state.searchCriteria[obj.id]"
                    />
                    <input
                        v-if="topParent.additionalControl.controlType === 'radio'"
                        class="form-check-input"
                        type="radio"
                        :name="obj.name" :id="obj.id+index"
                        :disabled="dependentFieldHasData"
                        :value="obj.value"
                        v-model="$store.state.searchCriteria[obj.id]"
                    />
                    <label class="form-check-label" :for="obj.id+index">{{obj.label}}</label>
                </div>
            </template>
        </div>
        <template v-else>
            <!-- independent checkbox or radio field -->
            <label
                class="field-title group-title"
                :class="titleHidden ? 'sr-only' : ''"
                :for="'field-' + id"
                v-if="title && options"
            >
                {{title}}
                <field-tooltip v-if="tooltipText" :text="tooltipText" :urlKeys="urlKeys" />
            </label>
            <div class="search-field input-text" :class="$store.state.searchFieldErrors[id] && $store.state.searchFieldErrors[id].message ? 'field-error' : ''">
                <template v-if="options" v-for="(obj, index) in options" v-bind:key="index">
                    <div class="form-check mb-1" :class="{'custom-radio':  controlType === 'radio', 'form-check':  controlType === 'checkbox'}">
                        <!-- radio/checkbox groups -->
                        <input
                            v-if="controlType === 'checkbox'"
                            class="form-check-input"
                            type="checkbox"
                            :id="obj.id + index"
                            :value="obj.value"
                            v-model="$store.state.searchCriteria[obj.id]"
                            data-testttttt="true"
                        />
                        <!-- :name="obj.name" -->
                        <input
                            v-if="controlType === 'radio'"
                            class="form-check-input"
                            type="radio"
                            :name="obj.name" :id="obj.id+index"
                            :value="obj.value"
                            v-model="$store.state.searchCriteria[id]"
                        />
                        <label class="form-check-label" :for="obj.id+index">{{obj.label}}
                            <field-tooltip v-if="obj.tooltipText" :text="obj.tooltipText" :urlKeys="urlKeys" />
                        </label>
                    </div>
                </template>
                <div v-if="!options" class="form-check form-check-inline">
                    <!-- single checkbox field -->
                    <input
                        class="form-check-input"
                        :type="controlType"
                        :name="id" :id="'field-' + id"
                        v-model="$store.state.searchCriteria[id]"
                    />
                    <label class="field-title form-check-label" :for="'field-' + id">
                        {{ title }}
                        <field-tooltip v-if="tooltipText" :text="tooltipText" :urlKeys="urlKeys" />
                    </label>
                </div>
                <div class="field-help-text" v-if="helpTextDisplay">
                    {{helpTextDisplay}}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FieldTooltip from '@/components/searchFields/FieldTooltip.vue';

export default {
    name: 'ControlList',
    components: {
        FieldTooltip
    },
    props: {
        topParent: {
            required: false
        },
        id: {
            required: true
        },
        title: {
            required: false
        },
        defaultValues: {
            required: false
        },
        maxSelection: {
            required: false
        },
        delimiter: {
            required: false,
            default: ';'
        },
        fields: {
            required: false
        },
        helpText: {
            required: false
        },
        placeholder: {
            required: false
        },
        dependentField: {
            required: false
        },
        select: {
            required: false
        },
        deselect: {
            required: false
        },
        change: {
            required: false
        },
        maxLength: {
            required: false
        },
        matchingStr: {
            required: false
        },
        titleHidden: {
            required: false
        },
        alternetFields: {
            required: false
        },
        groupFields: {
            required: false
        },
        controlType: {
            required: false
        },
        tooltipText: {
            required: false
        },
        options: {
            required: false
        },
        dbValues: {
            required: false
        },
        urlKeys: {
            required: false
        }
    },
    computed: {
        dependentFieldHasData: function () {
            if (this.topParent.dependentField) {
                let dependentFieldId = window.searchFieldSpecs[this.topParent.dependentField].id;

                if (this.topParent.matchingStr) {
                    var matchExp = new RegExp(this.topParent.matchingStr),
                        noAllowedYearFound = false;

                    if (this.$store.state.searchCriteria[dependentFieldId] && this.$store.state.searchCriteria[dependentFieldId].length > 0) {
                        for (let i = 0; i < this.$store.state.searchCriteria[dependentFieldId].length; i++) {
                            if (!matchExp.test(this.$store.state.searchCriteria[dependentFieldId][i].value)) {
                                noAllowedYearFound = true;
                                break;
                            }
                        }
                    } else {
                        noAllowedYearFound = true;
                    }
                }
                return noAllowedYearFound;
            }
            return false;
        }
    },
    data: function () {
        return {
            helpTextDisplay: this.helpText ? this.helpText : null
        };
    },
    mounted () {
        var defaultVal =  this.defaultValues ? this.defaultValues : '';
        if (!this.topParent) {
            if (!this.$store.state.searchCriteria[this.id]) {
                this.$store.state.searchCriteria[this.id] = defaultVal;
            }

            if (!this.$store.state.searchCriteriaDefault[this.id]) {
                this.$store.state.searchCriteriaDefault[this.id] = this.defaultValues ? this.defaultValues : '';
            }

            if (this.$store.state.editSearchCriteria[this.id]) {
                if (this.dbValues && JSON.stringify(this.dbValues.sort()).toLowerCase() == JSON.stringify(this.$store.state.editSearchCriteria[this.id].sort()).toLowerCase()) {
                    this.$store.state.searchCriteria[this.id] = this.defaultValues;
                } else {
                    this.$store.state.searchCriteria[this.id] = this.$store.state.editSearchCriteria[this.id];
                }
            }

            if (!this.$store.state.searchFieldErrors[this.id]) {
                this.$store.state.searchFieldErrors[this.id] = '';
            }
        }

        // this.initFieldValues();

        this.updateSearchCriteria();

        // if (this.topParent) {
        //     console.log(this.$store.state.searchCriteria[this.obj.id]);
        // }
    },
    methods: {
        ...mapActions([
            'setSearchCriteria',
            'editSearchResults',
            'setSearchCriteriaDefault'
        ]),
        initFieldValues () {
            let options,
                optionsLength;

            if (this.topParent && this.topParent.additionalControl && this.topParent.additionalControl.options) {
                options = this.topParent.additionalControl.options,
                optionsLength = options.length;

                if (this.topParent.additionalControl.controlType === 'radio') {

                }
                for (let i = 0; i < optionsLength; i++) {
                    this.$store.state.searchCriteria[options[i].id] = options[i].value;
                }
            } else if (this.options && this.controlType) {
                options = this.options,
                optionsLength = options.length;

                // if (this.type === 'radio') {
                for (let i = 0; i < optionsLength; i++) {
                    this.$store.state.searchCriteria[options[i].id] = options[i].value;
                }
                // }
            }
        },
        updateSearchCriteria () {
            
            if (this.topParent) {
                for (let i = 0; i < this.topParent.additionalControl.options.length; i++) {
                    let opt = this.topParent.additionalControl.options[i];

                    if (this.topParent.additionalControl.controlType === 'checkbox') {
                        if (opt.value !== undefined && opt.value !== null) {
                            if (this.$store.state.editSearchResults) {
                                if (this.$store.state.editSearchCriteria[opt.name] !== undefined) {
                                    this.setSearchCriteria({
                                        field: opt.name,
                                        data: copyData(this.$store.state.editSearchCriteria[opt.name])
                                    });
                                } else {
                                    this.setSearchCriteria({
                                        field: opt.name,
                                        data: false
                                    });
                                }
                            } else if (this.$store.state.searchCriteria[opt.name] !== undefined && this.$store.state.searchCriteria[opt.name] !== null) {
                                this.setSearchCriteria({
                                    field: opt.name,
                                    data: copyData(this.$store.state.searchCriteria[opt.name])
                                });
                            }
                            else {
                                this.setSearchCriteria({
                                    field: opt.name,
                                    data: copyData(opt.value)
                                });
                            }

                            this.setSearchCriteriaDefault({
                                field: opt.name,
                                data: copyData(opt.value)
                            });
                        }
                    } else { // radio buttons
                        if (opt.value !== undefined && opt.value !== null && i === 0) {
                            if (this.$store.state.editSearchResults && (this.$store.state.editSearchCriteria[opt.name] !== undefined && this.$store.state.searchCriteria[opt.name] !== null && this.$store.state.searchCriteria[opt.name] !== '')) {
                                this.setSearchCriteria({
                                    field: opt.name,
                                    data: copyData(this.$store.state.editSearchCriteria[opt.name])
                                });
                            } else {
                                if (this.$store.state.searchCriteria[opt.name] === undefined || this.$store.state.searchCriteria[opt.name] === null || this.$store.state.searchCriteria[opt.name] === '') {
                                        this.setSearchCriteria({
                                        field: opt.name,
                                        data: copyData(this.topParent.additionalControl.defaultValues)
                                    });
                                }
                            }

                            this.setSearchCriteriaDefault({
                                field: opt.name,
                                data: copyData(this.topParent.additionalControl.defaultValues)
                            });
                        }
                    }
                }
            } else {
                if (this.defaultValues) {
                    this.setSearchCriteriaDefault({
                        field: this.id,
                        data: copyData(this.defaultValues)
                    });
                }
            }
        },
        log (msg) {
            console.log(msg);
        }
    }
}
</script>

<style lang="scss" scoped>

.field-title {
    font-size: 18px;
    overflow: visible;
    &.group-title {
        font-size: 14px;
    }
}
.field-help-text {
    margin: 0;
    margin-left: calc(1.45rem + 0.5em);
}

</style>