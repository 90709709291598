<template>
    <div class="search-tile-outer" :class="{'simple-sizing': simpleSizing}" :id="titleId">
        <div class="search-tile" ref="tile" tabindex="0" @click="clickTile()" @keyup.enter="clickTile()">
        <!-- <div class="search-tile" ref="tile" tabindex="0" :class="{'disabled': index !== 0, 'active': active}"> -->
            <div class="search-tile-inner" ref="tileInner" tabindex="-1">
                <div class="field-title" :class="'title-' + tileClass" ref="fieldTitle" tabindex="-1">
                    {{name}}
                </div>
                <div class="tile-content-wrapper" tabindex="-1">
                    <component class="tile-content" tabindex="-1" :class="!isActive ? 'hide-content' : ''" :is="tileComponent" ref="tileContent" :style="maximizedContentStyle"></component>
                    <div v-if="description" class="tile-description" :class="isActive ? 'hide-content' : ''"  ref="tileMinimizedContent" :style="minimizedContentStyle">
                        <div v-html="description"></div>
                        <a :href="refLink" v-if="refLink" class="tile-link" target="_blank" title="Access legacy version here">
                            Legacy {{name}} <i class="fas fa-chevron-right arrow-right"></i>
                        </a>
                        <div v-else class="tile-link">
                            Get Started
							<i class="fas fa-chevron-right arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import AdvancedProjectsSearch from '@/components/home/ProjectsSearch.vue'
import PublicationsSearch from '@/components/home/PublicationsSearch.vue'
import Matchmaker from '@/components/home/Matchmaker.vue'

export default {
    name: 'SearchTile',
    components: {
        AdvancedProjectsSearch,
        PublicationsSearch,
        Matchmaker
    },
    computed: {
        tileComponent () {
            return this.name.replace(/\s/g,'');
        },
        tileClass () {
            return this.name.replace(/\s/g,'-').toLowerCase();
        },
        minimizedContentStyle () {
            return this.$parent.smallTileContentWidth ? 'max-width: ' + this.$parent.smallTileContentWidth + 'px;' : '';
        },
        maximizedContentStyle () {
            return this.$parent.largeTileContentWidth ? 'min-width: ' + this.$parent.largeTileContentWidth + 'px;' : '';
        }
    },
    props: {
        name: {
            required: true
        },
        index: {
            required: true
        },
        description: {
            required: false
        },
        active: {
            required: false
        },
        tileMode: {
            required: true
        },
        refLink: {
            required: false
        },
        path: {
            required: false
        },
        titleId: {
            required: false
        }
    },
    data: function () {
        return {
            simpleSizing: this.$parent.simpleSizing,
            isActive: this.active,
            width: 0,
            height: 0,
            minHeight: 0,
            smallTilePercent: .25,
            largeTilePercent: .75,
            margin: 15,
            padding: 24,
            position: this.$parent.layout[this.index],
            oppositeX: 0,
            newLastActiveY: 0,
            top: 0,
            left: 0,
            expanded: false,
            defaultDuration: 301
        };
    },
    mounted () {
    },
    methods: {
        sizeAndPosition () {
            this.setSize();
            this.setPosition();
        },

        setSize () {
            if (this.isActive) {
                this.growTile();
            } else {
                this.shrinkTile();
            }
        },

        setPosition () {
            var self = this;

            switch (self.position[0]) { // x
                case 0: // left
                    self.left = self.margin;
                    break;
                case 1: // middle
                    self.left = self.$parent.width * self.smallTilePercent;
                    break;
                case 2: // right
                    self.left = self.$parent.width * self.largeTilePercent;
                    break;
            }

            switch (self.position[1]) { // y
                case 0: // top
                    self.top = self.margin * 2;
                    break;
                case 1: // bottom
                    self.top = (self.$parent.height * 0.5) + (self.margin * 2);

                    setTimeout(() => {
                        // get tile above
                        let tileAboveIndex = self.$parent.layoutGrid[self.position[0]][0],
                            tileAbove = self.$parent.$refs['tile' + tileAboveIndex],
                            tileAboveHeight = tileAbove ? tileAbove.getMinimizedContentHeight() : 0;

                        self.top = tileAboveHeight + (self.margin * 4);
                        self.$el.style.top = self.top + 'px';

                    }, 1);
                    break;
            }


            self.$el.style.top = self.top + 'px';
            self.$el.style.left = self.left + 'px';

            self.$parent.layout[self.index] = self.position;
            self.$parent.layoutGrid[self.position[0]][self.position[1]] = self.index;
        },

        animate (duration) {
            if (duration === undefined) {
                duration = this.defaultDuration;
            }

            // if (this.isActive) {
            //     this.$refs.tile.classList.remove('active');
            // }

            this.$el.classList.add('animate');

            setTimeout(() => {
                this.$el.classList.remove('animate');

                // setTimeout(() => {
                //     if (this.isActive) {
                //         this.$refs.tile.classList.add('active');
                //     }
                // }, 1);
            }, duration);
        },

        clickTile () {
            let curPath = copyData(this.path);
            if (this.$parent.activeTile !== this.index && this.tileMode) {
                this.$router.push({ path:'/' + curPath}).then(() => {
                // process the updated route params
            });

                // update which tile is which so we can move them accordingly
                this.$parent.lastActiveTile = this.$parent.activeTile;
                this.$parent.activeTile = this.index;
                this.$parent.inactiveTile = this.$parent.getInactiveTileIndex();

                // expand this tile
                this.animate();
                this.growTile();

                // check this tile position and move
                switch (this.position[0]) {
                    case 0:
                        this.oppositeX = 2;

                        if (this.position[1] !== 0) {
                            this.moveTileAnimated(this.position[0], 0);
                        }

                        break;
                    case 1:
                        this.oppositeX = null;
                        this.moveTileAnimated(2, 0);

                        break;
                    case 2:
                        this.oppositeX = 0;
                        this.moveTileAnimated(1, 0);

                        break;
                }

                // shrink and move last active tile
                this.$parent.$refs['tile'+this.$parent.lastActiveTile].shrinkTileAnimated();

                if (this.$parent.layout[this.$parent.lastActiveTile][0] === 0) {
                    // move last active tile if in top left of grid
                    // switching Y as needed
                    this.$parent.$refs['tile'+this.$parent.lastActiveTile].moveTileAnimated(0, this.position[1]);
                } else if (this.$parent.layout[this.$parent.lastActiveTile][0] === 1) {
                    // move last active tile if in center to the right of grid
                    // switching Y as needed
                    this.$parent.$refs['tile'+this.$parent.lastActiveTile].moveTileAnimated(2, this.position[1]);
                }

                // move inactive tile around
                if (this.position[1] === 0) {
                    // if this tile is on top of grid
                    if (this.$parent.layoutGrid[this.position[0]][1] === this.$parent.inactiveTile) {
                        // if inactive tile is below
                        // slide tile to opposite side of grid
                        this.$parent.$refs['tile'+this.$parent.inactiveTile].moveTileAnimated(this.oppositeX, 1);
                    }
                } else if (this.position[1] === 1) {
                    // if this tile is on bottom of grid
                    if (this.$parent.layoutGrid[this.position[0]][0] === this.$parent.inactiveTile) {
                        // if inactive tile is above
                        // slide tile to opposite side of grid
                        this.$parent.$refs['tile'+this.$parent.inactiveTile].moveTileAnimated(this.oppositeX, 0);
                    }
                }

                // update active status
                this.$parent.$refs['tile'+this.$parent.lastActiveTile].isActive = false;
                this.$parent.$refs['tile'+this.$parent.inactiveTile].isActive = false;
                this.isActive = true;

                gtag('event','Click', {
					'event_category': 'Home',
					'event_label': 'Expand Search Tile - ' + this.name,
					'value': 'Expand Search Tile - ' + this.name
				});	
            }
        },

        shrinkTileAnimated () {
            this.animate();
            this.shrinkTile();
        },

        getMinimizedContentHeight () {
            let titleHeight = window.getAbsoluteHeight(this.$refs.fieldTitle),
                contentHeight = window.getAbsoluteHeight(this.$refs.tileMinimizedContent);

                return titleHeight + contentHeight + (this.padding * 2);
        },

        shrinkTile () {
            this.$nextTick(() => {
                this.width = (this.$parent.width * this.smallTilePercent) - this.margin;
                this.minHeight = 0;

                if (this.$refs && this.$refs.tile) {
                    let minimizedContentHeight = this.getMinimizedContentHeight();

                    this.height = minimizedContentHeight;
                    this.$refs.tile.style.minHeight = minimizedContentHeight + 'px';

                    this.$el.classList.remove('active');
                    this.$refs.tile.classList.remove('active');
                }

                this.updateSize();

                // revert to mini form when tile is collapsed
                if (this.$refs && this.$refs.tileContent && this.$refs.tileContent.showAllFields != undefined) {
                    setTimeout(() => {
                        this.$refs.tileContent.showAllFields = false;
                    }, this.defaultDuration);
                }
            });
        },

        getOtherTiles () {
            var tileIndices = [0, 1, 2],
                thisTileIndex = tileIndices.indexOf(this.index);

            tileIndices.splice(thisTileIndex, 1);

            return tileIndices;
        },

        growTile () {
            this.$nextTick(() => {
                if (this.$refs && this.$refs.tileContent && !this.$refs.tileContent.showAllFields) {
                    this.$parent.minHeight = this.getFormHeight();
                }

                this.width = (this.$parent.width * this.largeTilePercent) - this.margin;
                // this.height = this.$parent.height;
                this.height = this.getFormHeight() + (this.margin * 2);
                // this.minHeight = this.$parent.height - (this.margin * 2);

                // set min-height after tile has finished animating
                setTimeout(() => {
                    if (this.$refs && this.$refs.tile) {
                        this.$refs.tile.style.minHeight = this.minHeight + 'px';

                        // set active class to allow fluid resizing
                        // as content changes
                        this.$el.classList.add('active');
                        this.$refs.tile.classList.add('active');
                    }
                }, this.defaultDuration);

                this.updateSize();
            });
        },

        getFieldTitleHeight () {
            //return text height + margin bottom
            let fieldTitle = this.$refs.fieldTitle,
                fieldTitleMarginProp = fieldTitle ? window.getComputedStyle(fieldTitle).marginBottom : "0",
                // fieldTitleMargin = Number(fieldTitleMarginProp.substring(0, fieldTitleMarginProp.length - 2)),
                fieldTitleMargin = window.pxToNumber(fieldTitleMarginProp),
                fieldTitleFullHeight = (fieldTitle ? (fieldTitle.offsetHeight) : 0) + fieldTitleMargin;

                return fieldTitleFullHeight;
        },

        getTilePadding () {
            let tileStyle = this.$refs.tile ? window.getComputedStyle(this.$refs.tile) : null,
                paddingTop = tileStyle ? tileStyle.paddingTop : "0",
                paddingBottom = tileStyle ? tileStyle.paddingBottom : "0",
                padding = window.pxToNumber(paddingTop) + window.pxToNumber(paddingBottom);

                return padding;
        },

        getFormHeight () {
            var tileContentHeight = 0;

            if (this.$refs.tileContent && this.$refs.tileContent.$e1) {
                tileContentHeight = this.$refs.tileContent.$el.offsetHeight;
            }
            return tileContentHeight + this.getFieldTitleHeight() + this.getTilePadding();
        },

        toggleFullForm () {
            if (this.isActive) {
                this.$el.classList.remove('active');
                if (this.$refs.tile) {
                    this.$refs.tile.classList.remove('active');
                }
            }

            if (this.isActive) {
                setTimeout(() => {
                    this.$el.classList.add('active');
                    this.$refs.tile.classList.add('active');
                }, this.defaultDuration);
            }

            // setTimeout(() => {
            //     this.height = this.getFormHeight();

            //     if (!this.simpleSizing) {
            //         this.updateSize();
            //     }

            //     setTimeout(() => {
            //         this.$refs.tileContent.showAllFields = !this.$refs.tileContent.showAllFields;

            //         // if (this.$refs.tileContent.showAllFields) {
            //         //     this.$parent.minHeight = copyData(this.height);
            //         // } else {
            //         //     this.$parent.minHeight = copyData(this.$parent.minHeightDefault);
            //         // }

            //         this.animate();

            //         setTimeout(() => {
            //             this.height = this.getFormHeight();
            //             if (!this.simpleSizing) {
            //                 this.updateSize();
            //             }

            //             if (this.isActive) {
            //                 setTimeout(() => {
            //                     this.$refs.tile.classList.add('active');
            //                 }, this.defaultDuration);
            //             }
            //         }, 1);
            //     }, 1);
            // }, 1);

        },

        // increaseHeight () {
        //     this.height = this.getFormHeight();

        //     // console.log('new height', this.height);

        //     this.animate();
        //     setTimeout(() => {
        //         this.updateSize();
        //     }, 1);
        // },

        // decreaseHeight () {
        //     this.height = this.getFullFormHeight();

        //     console.log('new height', this.height);

        //     this.animate();
        //     this.updateSize();
        // },



        updateSize () {
            if (this.width !== 0) {
                this.$el.style.width = this.width + 'px';
            }

            // if (this.height < this.minHeight) {
            //     this.height = this.minHeight;
            // }

            this.$el.style.height = this.height + 'px';
            // this.$refs.tile.style.minHeight = this.minHeight + 'px';

            if (this.isActive) {
                if (this.$refs && this.$refs.tile) {
                    let otherTiles = this.getOtherTiles(),
                        heightOfOtherTiles = 0;

                    for (let i = 0; i < otherTiles.length; i++) {
                        heightOfOtherTiles += this.$parent.$refs['tile' + otherTiles[i]].getMinimizedContentHeight();
                    }

                    if (this.height < heightOfOtherTiles) {
                        this.$parent.updateHeight(heightOfOtherTiles + (this.margin * 4));
                    } else {
                        this.$parent.updateHeight(this.height);
                    }

                    if (this.$parent.largeTileContentWidth !== this.$refs.tileContent.$el.offsetWidth) {
                        this.$parent.largeTileContentWidth = this.$refs.tileContent.$el.offsetWidth;
                    }

                    setTimeout(() => {
                        this.$el.style.height = null;
                    }, 301);
                }
            } else {
                if ( this.$refs.tileMinimizedContent) {
                    if (this.$parent.smallTileContentWidth !== this.$refs.tileMinimizedContent.offsetWidth) {
                        this.$parent.smallTileContentWidth = this.$refs.tileMinimizedContent.offsetWidth;
                    }
                }
            }
        },

        clearSize () {
            if (this.width !== 0) {
                this.$el.style.width = '';
            }

            this.$el.style.height = '';
        },

        moveTileAnimated (x, y) {
            this.animate();
            this.moveTile(x, y);
        },

        moveTile (x, y) {
            this.$nextTick(() => {
                this.position[0] = x;
                this.position[1] = y;

                this.setPosition();
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.search-tile-outer {
    position: absolute;
    padding: $tile-spacing;

    &.active {
        position: relative;
        margin: -30px 0 0 -15px;
        @media screen and (max-width: $screen-md-max) {
            margin: 0;
        }
    }

    @media screen and (max-width: $screen-sm) {
        padding-left: 10px;
        padding-right: 10px;
    }

    &.animate {
        transition: 0.3s;

        // &,
        // &.search-tile {
        //     transition: 0.3s;
        //     // transition: 0s;
        // }
    }
    @media screen and (max-width: $screen-md-max) {
        // override fancy positioning on mobile
        position: relative;
        top: auto !important;
        left: auto !important;
        bottom: auto !important;
        right: auto !important;
        width: 100% !important;
        height: auto !important;
    }

    &.simple-sizing {
        position: relative;
        display: block;
        width: 75%;
        float: left;

        ~ .search-tile-outer {
            width: 25%;

            @media screen and (max-width: $screen-md-max) and (min-width: $screen-md-min) {
                // override fancy positioning on mobile
                width: 33.4% !important;
            }
        }
        // .search-tile {
        //     width: 25%;
        //     &.active {
        //         width: 75%;
        //     }
        // }
        .tile-content {
            position: static;
            height: auto;

            &.hide-content {
                position: absolute;
            }
        }
    }
}

.search-tile {
    $tile-padding: 24px;

    padding: $tile-padding * 0.9 $tile-padding;
    background-color: #fff;
    border-radius: $input-radius;
    box-shadow: 0 2px 8px 0px rgba(0,0,0,0.25);
    height: 100%;
    min-height: 194px;
    transition: 0.3s;
    outline: none;
    overflow: hidden;

    @media screen and (max-width: $screen-sm) {
        padding-left: 10px;
        padding-right: 10px;
    }

    &:hover:not(.active),
    &:focus:not(.active) {
        box-shadow: 0 4px 16px 0px rgba(0,0,0,0.45);
    }

    &.active {
        height: auto;
        min-height: 418px;
        transition: 0s;
        overflow: visible;
        // position: relative;

        .tile-content {
            position: relative;
        }

        .tile-description {
            position: absolute;
        }
    }
}

.field-title {
    margin-bottom: 1em;
    color: #212529;
    &:after {
        background-color: $blue;
    }

    &.title-advanced-projects-search {
        &:after {
            background-color: $green;
        }
    }
    &.title-publications-search {
        &:after {
            background-color: $pink;
        }
    }
    &.title-matchmaker {
        &:after {
            background-color: $orange;
        }
    }

    a {
        &:hover, &:active, &:focus {
            text-decoration: none;
        }
    }
}

.tile-content-wrapper {
    position: relative;
    font-size: 16px;
}

.tile-content {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    transition: 0.3s;
}

.tile-description {
    // margin-top: 8px;
    // font-size: 16px;
    font-weight: 400;
}

.hide-content {
    opacity: 0;
    pointer-events: none;
    min-width: 0px !important;
    display: none;
}

</style>