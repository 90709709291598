<template>

    <BDropdown right variant="dark" size="lg" v-if="currentUser != null" class="btnMyReporter">
        <template v-slot:button-content >
            <i class="fas fa-user"></i>
            <span class="ms-3" :title="currentUser.email">{{filters.truncate(currentUser.email, 50)}}</span>
        </template>
        <BDropdownItem to="/dashboard">
            <i class="fas fa-tachometer-alt"></i>
            Dashboard
        </BDropdownItem>
        <BDropdownItem to="/advanced-search">
            <i class="fas fa-search-plus"></i>
            Create New Search
        </BDropdownItem>

        <template v-if="recentSearches.length">
            <BDropdownHeader id="dropdown-header-label">
                Recently Created Saved Searches
            </BDropdownHeader>
            <BDropdownItem @click="bridgeQuery(search)" v-for="search in recentSearches" :key="search.id" :title="search.name">{{filters.truncate(search.title, 25)}}</BDropdownItem>
        </template>

        <BDropdownDivider></BDropdownDivider>

        <BDropdownItem @click="logout">
            <i class="fas fa-sign-out-alt"></i>
            Logout
        </BDropdownItem>
    </BDropdown>

    <BDropdown size="lg" variant="dark" v-else right
         id="user-menu-dd" class="btnMyReporter">
        <template #button-content>
            <span v-b-tooltip.hover.bottom="{title:'To Sign in, please select from the options below', variant:'warning'}">
                <i class="fas fa-sign-out-alt"></i>
                Sign In
            </span>
        </template>
        <BDropdownGroup id="dropdown-logingov" header="Public Users...">
            <BDropdownItem @click.prevent="signIn(urls.logingov)" rel="noopener">
                Sign in with Login.gov
            </BDropdownItem>
         </BDropdownGroup>
        <BDropdownDivider></BDropdownDivider>
        <BDropdownGroup id="dropdown-nih" header="NIH Staff...">
            <BDropdownItem @click.prevent="signIn(urls.nih)" rel="noopener">
                Sign in with NIH Login
            </BDropdownItem>
        </BDropdownGroup>
    </BDropdown>

</template>

<script>

    import { userService } from '@/shared/user.service.js';
    import { mapGetters, mapActions } from 'vuex';
    import queryDataMixin from '@/shared/querydata.mixin.js';
    import bus from '@/shared/event-bus.js';
    import {app} from '@/main';

    export default {

        name: 'UserMenu',
        mixins: [queryDataMixin],
        data: function () {
            return {
                recentSearches: [],
                currentUser: null,
                urls: { //defaults for prod in case of failed appSettings Load...
                    'nih': '',
                    'logingov': ''
                },
                filters: window.filters
            };
        },
        computed: {
            ...mapGetters(['currentQueryDetails', 'externalURLs'])
        },
        watch: {
            currentUser: function (n, o) {
                this.setLoggedInUser(n);
            }
        },
        async mounted() {
            var requestedURLs = [
                'myreporter_era_auth_url',
                'myreporter_login_auth_url',
                'myreporter_logout_auth_url',
                'myreporter_return_url'
            ];

            await this.$store.dispatch('getAppSettingsPropertyByNameAction', requestedURLs);
            await this.getUserWithRecentSearches();

            if(this.externalURLs)    {
                this.urls.nih = this.constructLoginUrl(this.externalURLs['myreporter_era_auth_url']);
                this.urls.logingov = this.constructLoginUrl(this.externalURLs['myreporter_login_auth_url']);
            }
            else {
                console.error("AppSettings was missing in UserMenu at load...");
            }

            bus.$on("update:search", () => {
                this.getUserWithRecentSearches();
            });

        },
        methods: {
            ...mapActions(["setLoggedInUser", 'setSavedSearches']),

            async getUserWithRecentSearches() {

                this.currentUser = null;

                this.currentUser = await userService.getCurrentUser();

                this.recentSearches = [];
                if(this.currentUser != null)    {
                    this.recentSearches = await userService.getSavedSearches();
                    this.setSavedSearches(this.recentSearches);
                    if (this.recentSearches.length > 5) {
                        this.recentSearches = _.slice(this.recentSearches, 0, 5);
                    }
                }
            },
            constructLoginUrl(destination) {
                let suffix = "?TARGET=" + window.location.origin + "/services/MyRePORTER/Authenticate" + "?" + new Date().getTime();
                return destination + suffix;
            },
            signIn (url) {
                localStorage.setItem('current_page', this.$route.fullPath);
                localStorage.setItem('unsaved_changes', JSON.stringify(this.currentQueryDetails));
                window.location.assign(url);
            },
            async logout() {
                //first hit internal endpoint, then redirect to SM logout...
                await userService.logout();
                this.currentUser = null;
                this.setSavedSearches(null);

                var redirectPath = window.location.origin;

                if (window.location.pathname.indexOf('/dashboard') === -1) {
                    redirectPath = window.location.href;
                }
                window.location.assign(this.externalURLs['myreporter_logout_auth_url'] + "?TARGET=" + redirectPath + "&t=" + new Date().getTime());
            },
            bridgeQuery(queryDetails) {
                this.setCurrentQueryDetailsAction(queryDetails);
                const url = '/search/'+queryDetails.search_id+'/bridge';
                this.$router.push(url);
            }

        }
    }
</script>

<style lang="scss" scoped>

</style>