<template>
    <component
        :is="control"
        ref="field"
        :title="title"
        :id="id"
        :idLookup="idLookup"
        :idFreeText="idFreeText"
        :defaultValues="defaultValues"
        :maxSelection="maxSelection"
        :fields="fields"
        :placeholder="placeholder"
        :controlType="controlType"
        :helpText="helpText"
		:urlKeys="urlKeys"
        :dependentField="dependentField"
        :dependingField="dependingField"
        :buildHelpText="buildHelpText"
        :select="select"
        :deselect="deselect"
        :change="change"
        :ui="ui"
        :dynamicLookup="dynamicLookup"
        :allowFreeText="allowFreeText"
        :additionalControl="additionalControl"
        :maxLength="maxLength"
        :matchingStr="matchingStr"
        :titleHidden="titleHidden"
        :alternetFields="alternetFields"
        :groupFields="groupFields"
        :tooltipText="tooltipText"
        :options="options"
        :dbValues="dbValues"
    />
</template>

<script>
import MultiSelect from '@/components/searchFields/MultiSelect.vue';
import InputText from '@/components/searchFields/InputText.vue';
import InputDate from '@/components/searchFields/InputDate.vue';
import ControlList from '@/components/searchFields/ControlList.vue';
import InputRange from '@/components/searchFields/InputRange.vue';

export default {
    name: 'Field',
    components: {
        MultiSelect,
        InputText,
        InputDate,
        ControlList,
        InputRange
    },
    props: {
        name: {
            required: true
        }
    },
    data: function () {
        return {
            control: window.searchFieldSpecs[this.name].control,
            title: window.searchFieldSpecs[this.name].title,
            id: window.searchFieldSpecs[this.name].id,
            idLookup: window.searchFieldSpecs[this.name].idLookup,
            idFreeText: window.searchFieldSpecs[this.name].idFreeText,
            defaultValues: window.searchFieldSpecs[this.name].defaultValues,
            maxSelection: window.searchFieldSpecs[this.name].maxSelection,
            fields: window.searchFieldSpecs[this.name].fields,
            placeholder: window.searchFieldSpecs[this.name].placeholder,
            controlType: window.searchFieldSpecs[this.name].controlType,
            helpText: window.searchFieldSpecs[this.name].helpText,
            dependentField: window.searchFieldSpecs[this.name].dependentField,
            dependingField: window.searchFieldSpecs[this.name].dependingField,
            buildHelpText: window.searchFieldSpecs[this.name].buildHelpText,
			urlKeys: window.searchFieldSpecs[this.name].urlKeys,
            select: window.searchFieldSpecs[this.name].select,
            deselect: window.searchFieldSpecs[this.name].deselect,
            change: window.searchFieldSpecs[this.name].change,
            ui: window.searchFieldSpecs[this.name].ui,
            additionalControl: window.searchFieldSpecs[this.name].additionalControl,
            maxLength: window.searchFieldSpecs[this.name].maxLength,
            matchingStr: window.searchFieldSpecs[this.name].matchingStr,
            dynamicLookup: window.searchFieldSpecs[this.name].dynamicLookup,
            allowFreeText: window.searchFieldSpecs[this.name].allowFreeText,
            titleHidden: window.searchFieldSpecs[this.name].titleHidden,
            alternetFields: window.searchFieldSpecs[this.name].alternetFields,
            groupFields: window.searchFieldSpecs[this.name].groupFields,
            tooltipText: window.searchFieldSpecs[this.name].tooltipText,
            options: window.searchFieldSpecs[this.name].options,
            dbValues: window.searchFieldSpecs[this.name].dbValues
        };
    },
    // mounted () {
    //     console.log(this.name);
    //     console.log(window.searchFieldSpecs);
    //     console.log(window.searchFieldSpecs[this.name]);
    // }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';


</style>