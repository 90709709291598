<template>
    <div class="search-tiles-container">
        <div class="row">
            <div class="search-tiles" :class="{'simple-sizing': simpleSizing}" ref="searchTiles" id="search-tiles">
                <SearchTile
                    name="Advanced Projects Search"
                    :index="0"
                    :active="activeTile === 0"
                    :tileMode="true"
                    description="Search using specific criteria to find <strong>NIH projects</strong> and <strong>funding information</strong>."
                    ref="tile0"
                    path=""
                    titleId="advanced_search"
                />
                <!-- :position="[0, 0]" -->
                <SearchTile
                    name="Publications Search"
                    :index="1"
                    :active="activeTile === 1"
                    :tileMode="true"
                    description="Find publications associated with extramural or intramural funded projects using PubMed IDs (PMID) or PubMed Central IDs (PMC ID)."
                    ref="tile1"
                    path="publications"
                    titleId="publications_search"
                />
                <!-- description="Search using <strong>PubMed IDs</strong> or <strong>PubMed Central IDs</strong>." -->
                <!-- :position="[2, 0]" -->
                <SearchTile
                    name="Matchmaker"
                    :index="2"
                    :active="activeTile === 2"
                    :tileMode="true"
                    description="Find potential Program Officials, ICs, and review panels for your research."
                    ref="tile2"
                    path="matchmaker"
                    titleId="matchmaker_search"
                />
                <!-- refLink='https://projectreporter.nih.gov/reporter_matchmaker.cfm?source=RPCO&new=1' -->
                <!-- description="Search using <strong>abstracts</strong> or other <strong>scientific text</strong>." -->
                <!-- :position="[2, 1]" -->
            </div>
        </div>
    </div>
</template>

<script>
import SearchTile from '@/components/home/SearchTile.vue'

export default {
    name: 'SearchTiles',
    components: {
        SearchTile
    },
    data: function () {
        return {
            simpleSizing: false,
            breakPoint: 1200,
            width: 0,
            height: 0,
            minHeightDefault: 300,
            minHeight: 300,
            activeTile: 0,
            lastActiveTile: 0,
            inactiveTile: 0,
            totalTiles: 3,
            smallTileContentWidth: null,
            largeTileContentWidth: null,
            layout: [
                [0, 0], // Projects Search Position
                [2, 0], // Publications Search Position
                [2, 1]  // Matchmaker Position
            ],
            layoutGrid: [
                [0, null], // x1: [y1, y2]
                [null, null], // x2: [y1, y2]
                [2, 1] // x3: [y1, y2]
            ]

            // layout: [
            //     [2, 0], // Projects Search Position
            //     [2, 1], // Publications Search Position
            //     [0, 0]  // Matchmaker Position
            // ],
            // layoutGrid: [
            //     [2, null], // x1: [y1, y2]
            //     [null, null], // x2: [y1, y2]
            //     [0, 1] // x3: [y1, y2]
            // ]

            // displayName: 'Smart Search'
        };
    },
    created () {
        switch (this.$route.path) {
            case '/matchmaker':
                this.activeTile = 2;
                this.layout = [
                    [2, 0], // Projects Search Position
                    [2, 1], // Publications Search Position
                    [0, 0]  // Matchmaker Position
                ];
                this.layoutGrid = [
                    [2, null], // x1: [y1, y2]
                    [null, null], // x2: [y1, y2]
                    [0, 1] // x3: [y1, y2]
                ];
                break;
            case '/publications':
                this.activeTile = 1;
                this.layout = [
                    [2, 0], // Projects Search Position
                    [0, 0], // Publications Search Position
                    [2, 1]  // Matchmaker Position
                ];
                this.layoutGrid = [
                    [1, null], // x1: [y1, y2]
                    [null, null], // x2: [y1, y2]
                    [0, 2] // x3: [y1, y2]
                ];
                break;
        }
    },
    mounted () {
        if (!this.simpleSizing) {
            window.addEventListener('resize', this.resize);

            // run updateSize twice, as the tile sizing updates parent sizing
            // fix in future if possible to only run once
            this.updateSize();

            setTimeout(() => {
                this.updateSize();
                // this.$parent.$parent.showFooter = true;
            }, 1);
        }

        document.addEventListener('keydown', this.handleInputEvent);

        setTimeout(() => {
            this.$parent.$parent.showFooter = true;
        }, 301);
    },
    destroyed () {
        document.removeEventListener('keydown', this.handleInputEvent);
    },
    methods: {
        resize () {
            this.smallTileContentWidth = null;
            this.largeTileContentWidth = null;
            this.updateSize();
        },
        updateSize () {
            if (this.$refs.searchTiles) {


                this.width = this.$refs.searchTiles.offsetWidth;
                // this.height = this.$refs.searchTiles.offsetWidth * .333;
                this.height = this.$refs.searchTiles.offsetWidth * .25;

                if (this.height < this.minHeight) {
                    this.height = this.minHeight;
                }

                // this.updateHeight();
            }

            // console.log(this.height);
            // console.log(this.minHeight);

            this.$nextTick(() => {
                for (let j = 0; j < this.totalTiles; j++) {
                    if (this.$refs && this.$refs['tile'+j] && this.$refs['tile'+j].sizeAndPosition) {
                        this.$refs['tile'+j].sizeAndPosition();
                    }
                }
            });
        },

        updateHeight (height) {
            if (this.$refs.searchTiles) {
                let paddingTop = parseInt(window.getComputedStyle(this.$refs.searchTiles).paddingTop),
                    paddingBottom = parseInt(window.getComputedStyle(this.$refs.searchTiles).paddingBottom),
                    heightWhenTilesAreStacking = 0,
                    self = this;

                setTimeout(() => {
                    if (self.breakPoint > self.$refs.searchTiles.clientWidth) {
                        let tiles = document.querySelectorAll('.search-tile-outer');
                        for (let i = 0; i < tiles.length; i++) {
                            heightWhenTilesAreStacking += parseInt(tiles[i].offsetHeight);
                        }

                        height = heightWhenTilesAreStacking;
                    }

                    // self.$refs.searchTiles.style.height = (height + paddingTop + paddingBottom) + 'px';
                    self.$refs.searchTiles.style.minHeight = (height + paddingTop + paddingBottom) + 'px';
                }, 301);
            }
        },

        getInactiveTileIndex () {
            let i;

            for (i = 0; i < this.totalTiles; i++) {
                if (this.$refs['tile'+i].index !== this.activeTile && this.$refs['tile'+i].index !== this.lastActiveTile) {
                    return this.$refs['tile'+i].index;
                }
            }
        },
        handleInputEvent (event) {
            let enterOnTile = false;
            if (event.target.classList.value.indexOf('search-tile') !== -1) {
                enterOnTile = true;
            }
            if (event.keyCode === 13 && !enterOnTile) {
                var $this = this;
                // console.log(this.$refs['tile' + this.activeTile].$refs.tileContent);
                setTimeout(() => {
                    if (event.srcElement.id !== 'smartSearch') {
                        if ($this.$refs && $this.$refs['tile' + $this.activeTile] && $this.$refs['tile' + $this.activeTile].$refs.tileContent)
                            $this.$refs['tile' + $this.activeTile].$refs.tileContent.search();
                    }
                }, 601);
            }
        },

        // shrinkActiveTile () {
        //     let i;

        //     for (i = 0; i < this.$children.length; i++) {
        //         if (this.$children[i].index === this.activeTile) {
        //             this.$children[i].animate();
        //             this.$children[i].shrinkTile();
        //             this.$children[i].moveTile();

        //             return false;
        //         }
        //     }
        // },

        // moveInactiveTile () {
        //     // slide tile which is not active, nor was the previous
        //     // active tile, across the grid without changing size
        //     let i;

        //     for (i = 0; i < this.$children.length; i++) {
        //         if (this.$children[i].index !== this.activeTile && this.$children[i].index !== this.lastActiveTile) {
        //             this.$children[i].animate();
        //             this.$children[i].moveTile();

        //             return false;
        //         }
        //     }
        // }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.search-tiles {
    position: relative;
    margin-left: -$tile-spacing;
    margin-right: -$tile-spacing;
    margin-top: -10px;
    margin-bottom: -15px;
    padding: 30px 15px 0px;
    width: calc(100% + (#{$tile-spacing} * 2));
    transition: 0.3s;
    @media screen and (max-width: $screen-sm) {
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>