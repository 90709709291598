window.sharedSpecs = {
    eCharts: {
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        color: [
            '#084081',
            '#22AA3F',
            '#67c1cb',
            '#b4e2ba',
            '#faecaf',
            '#f7bb6c',
            '#f78c55',
            '#C54A53',

            '#084081',
            '#22AA3F',
            '#67c1cb',
            '#b4e2ba',
            '#faecaf',
            '#f7bb6c',
            '#f78c55',
            '#C54A53',

            '#084081',
            '#22AA3F',
            '#67c1cb',
            '#b4e2ba',
            '#faecaf',
            '#f7bb6c',
            '#f78c55',
            '#C54A53',

            '#084081',
            '#22AA3F',
            '#67c1cb',
            '#b4e2ba',
            '#faecaf',
            '#f7bb6c',
            '#f78c55',
            '#C54A53',
        ],
        legend: {
            // type: 'scroll',
            align: 'left',
            right: 6,
            top: 0,
            itemWidth: 14,
            itemHeight: 14
        },
        tooltip: {
            confine: true,
            trigger: 'item',
            // triggerOn: 'click',
            axisPointer: {
                shadowStyle: {
                    color: 'rgba(8, 130, 220, 0.1)'
                },
                type: 'shadow',
                z: '0',
            }
        },
        grid: {
            top: '30',
            left: '35',
            right: '30',
            bottom: '20',
            containLabel: true
        },
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
            normal: {
                borderWidth: 2
            }
        },
        lineStyle: {
            normal: {
                // color: colors[i],
                // shadowColor: 'rgba(0, 0, 0, 0.15)',
                // shadowBlur: 5,
                width: 3
            }
        }
    },
    summaryPresets: [
        {
            name: 'Administering Institute/Center',
            value: 'Agency'
        },
        {
            name: 'Funding Institute/Center',
            value: 'FundingIC'
        }, 
        {
            name: 'Fiscal Year',
            value: 'FiscalYear'
        },
        {
            name: 'Funding Mechanism',
            value: 'fundingMechanism'
        },
        {
            name: 'Activity Code',
            value: 'activityCode'
        },
        {
            name: 'Organization',
            value: 'organization'
        },
        {
            name: 'State',
            value: 'StateCode'
        },
        {
            name: 'Congressional District',
            value: 'congDist'
        },
        {
            name: 'Principal Investigator',
            value: 'PrincipalInvestigator'
        },
        {
            name: 'Country',
            value: 'Country'
        }
    ]
}