import axios, * as others from 'axios';
import { MYREPORTER_API } from './config.js';

const API = MYREPORTER_API;

const getCurrentUser = async function () {

    /*
    expects:

        [
            {
                "key": "SM_USER",
                "value": "USERID"
            },
            {
                "key": "Email",
                "value": "user@nih.gov"
            }
        ]

    returns:

        {
            'name': 'John Doe',
            'email': 'john.doe@email.gov',
            'userId': 'DOEJ'
        }

     */
    try {
        // Added timestamp to avoid caching of service in browser
        const response = await axios.get(`${API}/GetUserInfo?t=` + new Date().getTime());

        const data = parseList(response);
        if(data && data!= null && data.length > 0){
            let user = {};
            user.userId = _.find(data, { "key": "SM_USER" }).value;
            user.email = _.find(data, { "key": "Email" }).value;

            return user;
        }
        return null;
    }
    catch (error) {
        // console.error(error); //look for 401 here and handle
        return null;
    }

};

const getSavedSearches = async function () {
    try {
        // const response = await axios.get(`${API}/savedSearches`);
        // Added timestamp to avoid caching of service in browser
        const response = await axios.get(`${API}/GetUserQueries?t=` + new Date().getTime());

        const data = parseList(response);

        const searches = data.map(h => {

            //sortable fields- this uses a naming convention, so dont change these without caution...
            h.name = h.title; //alias this..
            h.created = new Date(h.created_date); //convert to date...
            h.updated = h.last_updated_date && h.last_update_date != "" ? new Date(h.last_updated_date) : h.created;
            h.type = h.search_type;

            //massage server format to useful array via _ ...
            let tmp = h.alerts;
            h.alerts = [];
            _.forEach(tmp, function (v, k) {
                if (v.toLowerCase() == "y") {
                    h.alerts.push(k)
                }
            })

            return h;
        });

        //default lodash sort by recently updated...
        return _.orderBy(data, 'updated', ['desc']);

    }
    catch (error) {
        console.error(error);
        return [];
    }
};

const getRecentSearches = async function (max) {
    try {


        const data = await this.getSavedSearches();

        const searches = _.orderBy(data, 'updated', ['desc']);

        if (searches.length > max) {
            return _.slice(searches, 0, max);
        }

        return searches;

    } catch (error) {
        console.error(error);
        return [];
    }
}

const deleteSearch = async function (id) {
    try {
        const response = await axios.get(`${API}/DeleteUserQueries?queryIds=${id}`); //this always appears to return 200, so carry on...
        return true;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}

const updateAlert = async function (id, alertType, isChecked) {
    try {
        //make API call here to update via patch....
        /*
             To update Single Alert, Patch expects:
             {
                 "value": "N",
                 "path": "/Alerts/News",
                 "op": "replace"
             },

         */

        //TODO:  Consider throttling or batching here for fast clickers?

        let payload = {
            "value": isChecked ? "Y" : "N",
            "path": `/Alerts/${_.upperFirst(alertType)}`,
            "op": "replace"
        }

        const response = await axios.patch(`${API}/PatchQuery/${id}`, [payload]);

        return true;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}


const logout = async function (id) {
    try {
        // Added timestamp to avoid caching of service in browser
        const response = await axios.get(`${API}/Logout?t=` + new Date().getTime()); //200 or 401, so carry on...
        return true;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}


const createSearch = async function (queryDetails) {

    //qd.search_id must exist must exist...
    if (queryDetails.search_id == "") {
        return null;
    }

    //format the payload for endpoint...
    const payload = {
        user_query_info: {
            search_id: queryDetails.search_id,
            title: queryDetails.title.trim() || "",
            alerts: formatAlertsForSave(queryDetails.alerts),
            notes: queryDetails.notes ? queryDetails.notes.trim() : "",
            search_type: queryDetails.search_type 
        }
    }

    try {
        const response = await axios.post(`${API}/SaveQuery`, payload);
        if (isRequestRejected(response)) {
            throw Error("Invalid Request");
        }
        return response.data;
    }
    catch (error) {

        if (error.response && error.response.data && error.response.data.Title) {
            throw Error(_.first(error.response.data.Title));
        }
        throw error;
        // return null;
    }
}

const updateSearch = async function (queryDetails) {

    //qd.search_id must exist and be >0, qd.search_id must exist...
    if (!_.gt(queryDetails.id, 0) || queryDetails.search_id == "") {
        return false;
    }

    let post = {
        user_query_info: {
            id: queryDetails.id,
            search_id: queryDetails.search_id,
            title: queryDetails.title.trim() || '',
            notes: queryDetails.notes ? queryDetails.notes.trim() : "",
            alerts: formatAlertsForSave(queryDetails.alerts),
        }
    };

    try {
        const response = await axios.post(`${API}/UpdateQuery`, post);
        if (isRequestRejected(response)) {
            throw Error("Invalid Request");
        }
        return true;
    }
    catch (error) {

        if (error.response && error.response.data && error.response.data.Title) {
            throw Error(_.first(error.response.data.Title));
        }

        throw error;
    }

}


//extremely basic data check...
const parseList = response => {
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];

    let list = response.data;
    if (typeof list !== 'object') {
        list = [];
    }
    return list;
};


//DEPRECATED...
const formatAlertsForPatch = function (alerts) {
    //Accepts Json in the Vue friendly format, and re-formats to the format accepted by Patch endpoint...
    //incoming: alerts: [news, publication]....if an item is not in this array, it should get a value of "N"
    if (_.isEmpty(alerts)) return [];

    //to LC...
    alerts = alerts.map(v => v.toLowerCase());

    //default all to "N", and turn specific ones to "Y" (assumes all are lower case)...
    let payload = [];
    _.forEach(['news', 'publication', 'project'], function (v) {
        payload.push({
            "value": _.includes(alerts, v) ? "Y" : "N",
            "path": `/Alerts/${_.upperFirst(v)}`,
            "op": "replace"
        });
    });

    return payload;
}

const formatAlertsForSave = function (alerts) {
    //Accepts Json in the Vue friendly format, and re-formats to the format accepted by Save endpoint...
    if (_.isEmpty(alerts)) return {};


    //default all to "N", and turn specific ones to "Y" (assumes all are lower case)...
    let payload = {};
    _.forEach(['news', 'publication', 'project'], function (v) {
        payload[v] = _.includes(alerts, v) ? "Y" : "N"
    });

    return payload;

}

const isRequestRejected = function (res) {
    //Quick check for WAF Rejecting Requests, but still returning 200...
    if (res && res.status === 200 && _.includes(res.data, "Request Rejected")) {
        return true;
    }
    return false;
}

const getUserPreferences = async function () {
    try {

        const response = await axios.get(`${API}/GetPreferences`);

        let data = parseList(response);

        return data;
    }
    catch (error) {
        return false;
    }
}

const savePreferences = async function (paramName, paramVal) {
    try {
        let post = {
            "preference_name": paramName,
            "preference_value": paramVal
        }

        const response = await axios.post(`${API}/SavePreferences`, post);

        return true;
    }
    catch (error) {
        return false;
    }
}


export const userService = {
    getSavedSearches,
    getRecentSearches,
    deleteSearch,
    getCurrentUser,
    updateAlert,
    logout,
    createSearch,
    updateSearch,
    savePreferences,
    getUserPreferences
}
