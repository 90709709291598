<template>
    <div class="search-field input-text" :class="{'field-error': $store.state.searchFieldErrors[id] && $store.state.searchFieldErrors[id].message}">
        <label
            class="field-title"
            :class="titleHidden ? 'sr-only' : ''"
            :for="'field-' + id"
        >
            <template v-if="title">{{title}}</template>
			<field-tooltip v-if="tooltipText" :text="tooltipText" :urlKeys="urlKeys" />
            <span
                class="field-selected-count"
                :class="maxSelectionExceeded ? 'field-count-exceeded' : ''"
                v-if="title && $store.state.searchCriteria[id] && $store.state.searchCriteria[id].length !== 0 && maxSelection !== 1 && !(ui && ui.hideFieldCount)"
            >
                <template v-if="isCommaSeparated">
                    {{$store.state.searchCriteria[id].match(checkCommaSeparators) ? filters.addCommas($store.state.searchCriteria[id].match(checkCommaSeparators).length + 1)  : '1'}}
                </template>
                <template v-else>
                    {{$store.state.searchCriteria[id].match(checkSemicolonSeparators) ? filters.addCommas($store.state.searchCriteria[id].match(checkSemicolonSeparators).length + 1)  : '1'}}
                </template>
                <button
                    class="multiselect__remove_all"
                    @click.stop="clearAllText()"
                >
                     <i class="fas fa-times svg"></i>
                </button>
            </span>
        </label>
        <div
            class="input-group"
            @click="focusInput()"
        >
            <div class="input-text-highlight" ref="inputHighlight" v-html="inputHighlightText"></div>
            <textarea
                type="text"
                class="form-control form-control-lg input-text__input"
                :class="{'textInput': maxLength, 'free-height': ui && ui.rows}"
                aria-label="Quick Search"
                aria-describedby="button-addon2"
                :id="'field-' + id"
                ref="input"
                v-model="$store.state.searchCriteria[id]"
                :rows="ui && ui.rows ? ui.rows : 1"
                :placeholder="placeholder"
                :maxLength="maxLength"
            ></textarea>
        </div>
        <div v-if="ui && ui.showCount" class="count" :class="{'max-reached': $store.state.searchCriteria[id] && (maxLength - $store.state.searchCriteria[id].length === 0)}"><template v-if="$store.state.searchCriteria[id]">{{filters.addCommas(maxLength - $store.state.searchCriteria[id].length)}}</template><template v-else>{{filters.addCommas(maxLength)}}</template> characters left</div>
        <div class="field-help-text" v-if="helpTextDisplay" v-html="helpTextDisplay">

        </div>
        <field-error
            v-if="$store.state.searchFieldErrors[id]"
            v-show="$store.state.searchFieldErrors[id].message"
            :message="$store.state.searchFieldErrors[id].message"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FieldError from '@/components/searchFields/FieldError.vue'
import FieldTooltip from '@/components/searchFields/FieldTooltip.vue'
import {app} from '@/main';
import bus from '@/shared/event-bus.js';

export default {
    name: 'InputText',
    components: {
        FieldError,
        FieldTooltip
    },
    props: {
        title: {
            required: true
        },
        id: {
            required: true
        },
        default: {
            required: false
        },
        maxSelection: {
            required: false
        },
        delimiter: {
            required: false,
            default: ';'
        },
        fields: {
            required: false
        },
        helpText: {
            required: false
        },
        placeholder: {
            required: false
        },
        dependentField: {
            required: false
        },
        select: {
            required: false
        },
        deselect: {
            required: false
        },
        change: {
            required: false
        },
        maxLength: {
            required: false
        },
        matchingStr: {
            required: false
        },
        titleHidden: {
            required: false
        },
        alternetFields: {
            required: false
        },
        groupFields: {
            groupFields: false
        },
        tooltipText: {
            required: false
        },
        urlKeys: {
            required: false
        },
        ui: {
            required: false
        },
        bus: {
            required: false
        }
        // inputTypes: {
        //     required: false
        // },
        // submitTypes: {
        //     required: false
        // }
    },
    computed: {
        ...mapGetters([
            'searchCriteria',
            'searchFieldErrors',
            'externalURLs'
        ])
    },
    data: function () {
        return {
            hasFocus: false,
            allowedCharacters: /[a-zA-Z0-9;,. ]/,
            checkNumberAndLetters: /^(?=.*[a-zA-Z])(?=.*[0-9])/,
            // checkSemicolonSeparators: /[;][^;$][^; $]/g,
            checkSemicolonSeparators: /(?!;\s*$);/g,
            checkCommaSeparators: /(?!,\s*$),/g,
            // allowedCharacters: /^[a-zA-Z\u00C0-\u00FF0-9]+$/, //matches accented characters
            // error: null,
            // error: 'Please enter only Names or Person IDs',
            inputTypes: null,
            submitTypes: null,
            // inputValue: ''

            // assigned here so that it can be overwritten below
            // otherwise throws error in Vue
            helpTextDisplay: this.helpText ? this.helpText : null,
            inputHighlightText: '',
            maxSelectionExceeded: false,
            isCommaSeparated: false,
            orgInputText: '',
            validationTimer: null,
			urlKeyList: this.urlKeys,
            filters: window.filters
        };
    },
    async mounted () {
        // initialize field 
        if (!this.$store.state.searchCriteria[this.id]) {
            this.$store.state.searchCriteria[this.id] = '';
        }

        if (!this.$store.state.searchCriteriaDefault[this.id]) {
            this.$store.state.searchCriteriaDefault[this.id] = '';
        }

        if (this.$store.state.editSearchCriteria[this.id]) {
            if (this.id === 'search_text') {
                this.$store.state.searchCriteria[this.id] = unescape(this.$store.state.editSearchCriteria[this.id]);
            } else {
                this.$store.state.searchCriteria[this.id] = this.$store.state.editSearchCriteria[this.id];
            }
        }

        if (!this.$store.state.searchFieldErrors[this.id]) {
            this.$store.state.searchFieldErrors[this.id] = '';
        }

        this.updateSearchCriteriaDefault();

        if (this.fields) {
            this.inputTypes = [];
            this.submitTypes = [];

            for (let field in this.fields) {
                this.submitTypes.push(field);
                this.inputTypes.push(this.fields[field]);

                if (!this.$store.state.searchCriteria[field]) {
                    if (this.$store.state.editSearchCriteria[field]) {
                        this.$store.state.searchCriteria[field] = this.$store.state.editSearchCriteria[field];
                    } else {
                        // initialize field in store so that it will be watched
                        this.$store.state.searchCriteria[field] = [];
                    }
                }
            }
            // this.submitTypes = JSON.parse(this.submitTypes.replace(/'/g, '"'));

            // for (let i = 0; i < this.submitTypes.length; i++) {
            //     // initialize field in store so that it will be watched
            //     this.$store.state.searchCriteria, this.submitTypes[i], []);
            // }
        }

        var externalURLKeys = this.urlKeyList;
		if (externalURLKeys && externalURLKeys.length > 0 && this.helpTextDisplay) {
            for (let i = 0; i < externalURLKeys.length; i++) {
                var regex = new RegExp(externalURLKeys[i], "g");
				if (!this.externalURLs[externalURLKeys[i]]) {
                    await this.$store.dispatch('getAppSettingsPropertyByNameAction', externalURLKeys[i]);
                    this.helpTextDisplay = this.helpTextDisplay.replace(regex, this.externalURLs[externalURLKeys[i]]);
                } else {
                    this.helpTextDisplay = this.helpTextDisplay.replace(regex, this.externalURLs[externalURLKeys[i]]);
                }
            }
        }
        // setup keyboard events on input field
        if (this.$refs.input) {
            this.initEvents();
        }
        this.validateInput(false);
        this.updateTextHighlight();
        bus.$on('reset', this.reset);
    },
    methods: {
        ...mapActions([
            'setSearchCriteria',
            'setSearchCriteriaDefault',
            'setSearchFieldErrors'
        ]),

        clearAllText () {
            this.updateSearchCriteria('');
            this.isCommaSeparated = false;

            if (this.alternetFields) {
                for (let i = 0; i < this.alternetFields.length; i++) {
                    if (document.getElementById('field-' + this.alternetFields[i])) {
                        document.getElementById('field-' + this.alternetFields[i]).disabled = false;
                        document.getElementById('field-' + this.alternetFields[i]).closest('.search-field').classList.remove('disabled');
                    }
                }
            }
			gtag('event','Click', {
				'event_category': 'Search Field',
				'event_label': 'Clear Text - ' + this.title,
				'value': this.title
			});
        },

        setSelections (selections) {
            let selected = [];

            for (let i = 0; i < selections.length; i++) {
                let select = this.data.filter((item) => {
                        if (item.value === selections[i]) {
                            return item;
                        }
                    });

                selected = selected.concat(select);
            }

            this.updateSearchCriteria(selected);
        },

        initEvents () {
            this.$refs.input.addEventListener('keydown', this.handleInputEvent);
            this.$refs.input.addEventListener('paste', (event) => {
                this.handleInputEvent(event);
				gtag('event','Keyboard', {
					'event_category': 'Search Field',
					'event_label': 'Paste - ' + this.title,
					'value': 'Paste - ' + this.title
				});	
            });

            this.$refs.input.addEventListener('cut', (event) => {
                this.handleInputEvent(event);
				
				gtag('event','Keyboard', {
					'event_category': 'Search Field',
					'event_label': 'Cut - ' + this.title,
					'value': 'Cut - ' + this.title
				});	
            });

            this.$refs.input.addEventListener('copy', (event) => {
                this.handleInputEvent(event);

                gtag('event','Keyboard', {
					'event_category': 'Search Field',
					'event_label': 'Cut - ' + this.title,
					'value': 'Copy - ' + this.title
				});	
            });

            this.$refs.input.addEventListener('scroll', this.updateTextHighlight);
        },

        updateTextHighlight () {
            if (this.$refs.inputHighlight) {
                this.$refs.inputHighlight.scrollTop = this.$refs.input.scrollTop;
            }
        },

        handleInputEvent (event) {
            var $this = this;
            // this.$refs.input.addEventListener('keydown, paste', (event) => {
            // var key = String.fromCharCode(event.keyCode);

            // if press enter, prevent adding line break
            if (event.keyCode === 13) {
                event.preventDefault();

                //submit search
                // this.$parent.$parent.search();
            }

            // set timeout so that value is udpated before parsing string
            if ($this.validationTimer) {
                clearTimeout($this.validationTimer);
            }
            $this.validationTimer = setTimeout(() => {
                $this.orgInputText = copyData($this.$store.state.searchCriteria[$this.id])
                $this.validateInput(true);

                $this.updateSearchCriteria($this.$store.state.searchCriteria[$this.id]);
                setTimeout(() => {
                    $this.updateTextHighlight();
                }, 1);
            }, 501);
        },

        build () {
            if (this.default) {
                let defaultSelection = JSON.parse(this.default.replace(/'/g, '"'));

                this.setSelections(defaultSelection);
            }
        },

        removeLineBreaks (value) {
            // if (value.match(/\n|\t|\r/g)) {
            // }
            value = value.replace(/\n|\t|\r/g, this.delimiter + ' ')
            if (this.id === 'foa' || this.id === 'project_nums__field_text') {
                value = value.replace(/,/g, this.delimiter);
            }
            value = value.replace(/;;/g, this.delimiter);
            return value;
        },

        removeDuplicates (value) {
            var regEx = new RegExp('\\s*' + this.delimiter + '\\s*', 'ig'),
                inputArr,
                uniqueArr,
                alrernateRegEx,
                alternate_delimiter = '';

            if ((this.id === 'foa1' || this.id === 'project_nums__field_text1') && value.indexOf(',') !== -1) {
                alternate_delimiter = ',';
                alrernateRegEx = new RegExp('\\s*' + alternate_delimiter + '\\s*', 'ig');
            }
            value = value.replace(regEx, this.delimiter);
            if (alternate_delimiter !== '') {



                value = value.replace(/\s*,\s/ig, alternate_delimiter);
                inputArr = value.split((/[,;]+/));
            } else {
                inputArr = value.split(this.delimiter);
            }

            uniqueArr = inputArr.filter((item, pos, self) => {
                return inputArr.indexOf(item) === pos;
            });
            if (value.indexOf(this.delimiter) !== -1) {
                return uniqueArr.join(this.delimiter + ' ');
            } else {
                return uniqueArr.join(alternate_delimiter + ' ');
            }
        },

        validateInput (isKeyPress) {
            var curElement = document.getElementById('field-' + this.id),
                disableStatus  = false;

            if (this.orgInputText.indexOf(',') !== -1 && this.orgInputText.indexOf(';') === -1 && this.id !== 'pi__field_text' && this.id !== 'po_names') {
                this.isCommaSeparated = true;
            }
            // removes linebreaks and tabs, for pasting from excel
            this.$store.state.searchCriteria[this.id] = this.removeDuplicates(this.removeLineBreaks(this.$store.state.searchCriteria[this.id]));

            if (this.maxSelection > 1) {
                this.inputHighlightText = copyData(this.$store.state.searchCriteria[this.id]);

                let semicolonMatches = this.inputHighlightText.match(this.checkSemicolonSeparators);

                this.maxSelectionExceeded = false;
                // this.error = null;

                // reset errors
                this.setSearchFieldErrors({
                    field: this.id
                });

                // if (this.maxSelection) {
                if (semicolonMatches && semicolonMatches.length !== 0) {
                    let indexOfTermExceedingLength = window.getNthIndex(this.inputHighlightText, ';', this.maxSelection) + 1;

                    if (indexOfTermExceedingLength > 0 && this.inputHighlightText.length > indexOfTermExceedingLength) {
                        if (this.inputHighlightText.slice(indexOfTermExceedingLength).trim() !== '') {
                            this.inputHighlightText = '';

                            this.maxSelectionExceeded = true;
                            // this.error = `Please enter no more than ${window.addCommas(this.maxSelection)}`;

                            this.setSearchFieldErrors({
                                field: this.subControls ? thisId : this.id,
                                message: `Please enter no more than ${window.addCommas(this.maxSelection)}`,
                                preventSearch: true
                            });
                        }
                    }
                }
            }

            if (this.maxSelectionExceeded) {
                return;
            }
            if (this.ui && this.ui.checkXSS) {
                let tempDomEle = document.createElement('div'),
                    tempChilds,
                    refinedText = '';

                tempDomEle.innerHTML = copyData(this.$store.state.searchCriteria[this.id].replace("onerror", ""));
                tempChilds = tempDomEle.childNodes;
                for (let i = 0; i <tempChilds.length; i++) {
                    if (tempChilds[i].nodeType !== 1) {
                        refinedText += (tempChilds[i].textContent);
                    }
                }
                this.$store.state.searchCriteria[this.id] = refinedText;
            }
            if (this.matchingStr) {
                let validationExp = (this.matchingStr ? new RegExp(this.matchingStr, 'ig') : '');

                this.inputHighlightText = copyData(this.$store.state.searchCriteria[this.id]);
                this.inputHighlightText = this.inputHighlightText.replace(validationExp, '');

                this.setSearchFieldErrors({
                    field: this.id
                });

                // if (this.maxSelection) {
                if (this.inputHighlightText && this.maxLength) {
                    if (this.inputHighlightText.length > this.maxLength) {
                        this.inputHighlightText = this.inputHighlightText.slice(0, this.maxLength);
                    }
                    setTimeout(() => {
                        if (this.inputHighlightText.length === this.maxLength) {
                            let parentRow = this.getParentRow(curElement);

                            if (parentRow.nextSibling) {
                                parentRow.nextSibling.getElementsByTagName('textarea')[0].focus();
                            }
                        }
                    }, 301);
                }
                this.$store.state.searchCriteria[this.id] = this.inputHighlightText;
            }

            if (this.alternetFields) {
                if (this.$store.state.searchCriteria[this.id]) {
                    disableStatus = true;

                    for (let i = 0; i < this.alternetFields.length; i++) {
                        if (document.getElementById('field-' + this.alternetFields[i])) {
                            this.$store.state.searchCriteria[this.alternetFields[i]] = '';
                            document.getElementById('field-' + this.alternetFields[i]).disabled = disableStatus;

                            document.getElementById('field-' + this.alternetFields[i]).closest('.search-field').classList.add('disabled');
                        }
                    }
                } else {
                    let splitNumberExists = false;

                    if (isKeyPress) {
                        if (this.groupFields) {
                            for (let i = 0; i < this.groupFields.length; i++) {
                                if (this.$store.state.searchCriteria[this.groupFields[i]]) {
                                    splitNumberExists = true;
                                }
                            }
                            if (!splitNumberExists) {
                                if (document.getElementById('field-' + this.alternetFields[0])) {
                                    document.getElementById('field-' + this.alternetFields[0]).disabled = false;

                                    document.getElementById('field-' + this.alternetFields[0]).closest('.search-field').classList.remove('disabled');
                                }
                            }
                        } else {
                            for (let i = 0; i < this.alternetFields.length; i++) {
                                if (document.getElementById('field-' + this.alternetFields[i])) {
                                    this.$store.state.searchCriteria[this.alternetFields[i]] = '';
                                    document.getElementById('field-' + this.alternetFields[i]).disabled = false;

                                    document.getElementById('field-' + this.alternetFields[i]).closest('.search-field').classList.remove('disabled');
                                }
                            }
                        }
                    }
                }
            }
        },

        focusInput () {
            this.$refs.input.focus();
        },

        blurInput () {
            this.$refs.input.blur();
        },

        getParentRow(curElement) {
            if (curElement.parentNode.className.indexOf('lessPadding') !== -1) {
                return curElement.parentNode;
            } else {
                return this.getParentRow(curElement.parentNode);
            }
        },

        updateSearchCriteriaDefault () {
            if (this.default) {
                this.setSearchCriteriaDefault({
                    field: this.id,
                    data: copyData(this.default)
                });
            }
        },

        updateSearchCriteria (data) {
            var dataArray = data.split(';'),
                submitData = {},
                isNumber = /^[0-9]+$/;
            // inputTypes="['string', 'number']"
            // submit="['piNames', piProfileIds]"

            if (this.inputTypes) {
                for (let i = 0; i < this.inputTypes.length; i++) {
                    let type = this.inputTypes[i].controlType;

                    submitData[type] = [];


                    for (let i2 = 0; i2 < dataArray.length; i2++) {
                        let term = dataArray[i2].trim();

                        switch (type) {
                            case 'string':
                                if (!isNumber.test(term) && term.length !== 0) {
                                    submitData[type].push(term);
                                }
                                break;
                            case 'number':
                                if (isNumber.test(term)) {
                                    submitData[type].push(term);
                                }
                                break;
                        }
                    }

                    submitData[type].join(this.delimiter);

                    this.setSearchCriteria({
                        field: this.submitTypes[i],
                        data: submitData[type]
                    });
                }

            //     this.setSearchCriteria({
            //         field: this.id,
            //         data: copyData(data)
            //     });
            // } else {
            //     this.setSearchCriteria({
            //         field: this.id,
            //         // data: copyData(this.inputValue)
            //         data: copyData(data)
            //     });
            }

            //data = this.removeDuplicates(data);
            if (this.isCommaSeparated) {
                data = dataArray.filter(function (el) {
                    return el !== "";
                }).join(',');
                // data = dataArray.join(',');
            }
            this.setSearchCriteria({
                field: this.id,
                data: copyData(data)
            });

        },

        reset () {
            if (this.alternetFields) {
                if (this.alternetFields.length > 1) {
                for (let i = 0; i < this.alternetFields.length; i++) {
                    document.getElementById('field-' + this.alternetFields[i]).disabled = false;
                    document.getElementById('field-' + this.alternetFields[i]).closest('.search-field').classList.remove('disabled');
                }
                } else {
                    if (document.getElementById('field-' + this.alternetFields[0])) {
                        document.getElementById('field-' + this.alternetFields[0]).disabled = false;
                        if ( document.getElementById('field-' + this.alternetFields[0]).closest('.search-field')) {
                            document.getElementById('field-' + this.alternetFields[0]).closest('.search-field').classList.remove('disabled');
                        }
                    }
                }
            } else {
                this.$store.state.searchCriteria[this.id] = '';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

$input-text-spacing: 0.15rem;

textarea.textInput {
    overflow:hidden;
    resize: true;
//     resize: none;
//     height: calc(2.875rem + 2px);
//     padding: 0.75rem 0.5rem;
}

.count {
    margin-top: 2px;
    color: $grey;
}
.input-text {
    position: relative;
}

.field-title {
    margin-bottom: 0.5em;
    font-size: 18px;
    font-weight: 400;
    overflow: visible;
    color: #212529;
}

.input-group {
    // overflow: hidden;
    // box-shadow: none;
    box-shadow: $field-shadow;

    background-color: $grey-lighter;
    border-radius: $input-radius;

    // .input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
    //     border-radius: $input-radius;
    // }
}

.form-control {
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
}


.input-text__input-group {
    // position: absolute;
    // padding: 0.4rem 0.5rem;
    padding: 0.45rem 0.5rem;
    width: 100%;
    min-height: calc(2.875rem + 2px);
    height: auto;
    background-color: $grey-lighter;
    border-radius: $input-radius;
    font-size: 15px;
    transition: 0.3s;

    &.active {
        border-color: #1998fd;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 84, 150, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(1, 84, 150, 0.25);
    }
}

.input-text__tags {
    display: inline;
    margin: 0;
    padding: 0.5rem 0;
    list-style: none;
}

.input-text__tag {
    position: relative;
    display: inline-block;
    padding: 0.1em 1.5em 0.1em 0.5em;
    margin: $input-text-spacing 0.4rem $input-text-spacing 0;
    // margin-right: $input-text-spacing;
    background-color: $blue-lightest;
    border-radius: 12px;
    // font-size: 15px;
    font-size: 14px;
    font-weight: 700;
    color: #333;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    transition: 0.3s;

    &:hover,
    &:focus {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
    }
}

.input-text__tag__remove {
    $iconSize: 1.75em;

    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: none;
    background: none;
    width: $iconSize;
    height: $iconSize;
    text-align: center;
    cursor: pointer;

    svg {
        padding: 0.4em 0.4em 0.5em 0.5em;
        width: $iconSize;
        height: $iconSize;
        fill: rgba(0,0,0,0.25);
    }

    vertical-align: middle;

    &:hover,
    &:focus {
        svg {
            fill: $orange;
        }
    }
}


.input-text-highlight {
    position: absolute;
    color: transparent;
    // color: rgba(255,0,0,0.5);
    white-space: pre-wrap;

    z-index: 1;
    pointer-events: none;

    overflow: hidden;

    + .input-text__input {
        border-radius: $input-radius;
    }
}


.input-text__input {
    display: inline-block;
    // margin: $input-text-spacing;
    margin: 0;
    // padding-left: 0;
    // padding-right: 0;
    // width: 2em;
    width: 100%;
    // height: 1.5em;
    // font-size: 15px;

    overflow: auto;

    // min-height: calc(2.875rem + 2px);
    // height: auto;

    // background-color: $grey-lighter;
    // border-radius: $input-radius;
    font-size: 15px;
    // transition: 0.3s;

    resize: vertical;
    min-height: 42px;
    // height: calc(2.875rem + 2px);
    // padding: 0.75rem 0.5rem;
    // padding: 0.72rem 0.5rem;

    z-index: 2;

    &.active:not(:disabled) {
        border-color: #1998fd;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 84, 150, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(1, 84, 150, 0.25);
    }

    &:focus:not(:disabled) {
        border-color: #1998fd;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 84, 150, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(1, 84, 150, 0.25);
    }

    // &:disabled {
    //     background-color: #fff;
    //     opacity: 0.5;
    // }
    // &.textInput {
    //     overflow: hidden;
    //     padding: 10px 5px;
    // }
}

.project-number-split {
    .input-text__input {
        resize: none;
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
}

.multiselect__remove_all {
    $iconSize: 1.4em;

    // position: absolute;
    // top: 0.45em;
    // right: 0.25em;

    float: right;
    margin-right: -0.2em;
    // margin-left: -0.25em;
    // margin-top: -0.3em;

    padding: 0;
    border: none;
    background: none;
    width: $iconSize;
    height: $iconSize;
    text-align: center;
    cursor: pointer;

    z-index: 10;

    svg {
        position: relative;
        margin-top: -0.2em;
        padding: 0 0.25em;
        width: $iconSize;
        height: $iconSize;
        fill: rgba($blue-dark, 0.4);
    }

    vertical-align: middle;

    &:hover,
    &:focus {
        svg {
            fill: $orange;
        }
    }
}

.input-text__dropdown {
    position: absolute;
    top: 100%;
    // bottom: 0;
    left: 0;
    // width: 100%;
    min-width: 100%;
    max-width: 200%;
    height: 0;
    background-color: #fff;
    box-shadow: 0 2px 8px 0px rgba(0,0,0,0.25);
    border-radius: $input-radius;
    overflow: auto;
    z-index: 10;
    transition: 0.3s;

    &.open {
        height: 200px;
    }
}

.max-reached {
    animation: maxCharacters 0.3s normal forwards ease-in-out;
    animation-iteration-count: 1;
    transform-origin: left;
}

@keyframes maxCharacters {
    from {
        font-weight: 700;
        color: #333;
    }
    50% {
        transform: scale(1.05);
        color: $red;
    }
    to {
        font-weight: 700;
        color: $red;
    }
}

</style>