<template>
    <div :class="loading ? 'loading' : ''">
        <div class="search-form" id="pub_search_form">
            <div class="row">
                <div class="col-md-9 col-sm-12">
                    <field name="PublicationsText" ref="publicationsText"/>
                </div>
                <div class="col-md-3 col-sm-12">
                    <field name="PublicationsField"/>
                </div>
            </div>
             <div class="row">
                <div class="col-md-4 search-buttons offset-md-8">
                    <button
                        type="button"
                        class="btn btn-lg btn-secondary btn-clear"
                        @click.stop="reset()"
                        v-if="!refpage"
                    >
                        Reset
                    </button>
                    <button
                        type="button"
                        class="btn btn-lg btn-secondary btn-clear"
                        @click.stop="cancel()"
                        v-if="refpage"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        class="btn btn-lg btn-primary btn-search"
                        @click.stop="search()"
                        :disabled="loading"
                    >
                        Search
                        <div class="loader" v-if="loading"></div>
                    </button>
                    <field-error
                        v-if="serviceError"
                        :message="serviceErrorMessage"
                        :errorCode="serviceError"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import SmartSearch from '@/components/searchFields/SmartSearch.vue'
import { mapGetters, mapActions } from 'vuex';
import Field from '@/components/searchFields/Field.vue';
import FieldError from '@/components/searchFields/FieldError.vue';
import globalMixins from '@/globalMixins';

export default {
    name: 'PublicationsSearch',
    mixins: [globalMixins],
    components: {
        Field,
        FieldError
        // SmartSearch
    },
    props: {
        refpage: {
            required: false
        }
    },
    data: function () {
        return {
            loading: false,
            serviceErrorMessage: '',
            serviceError: null,
            id: 'publications_text'
        };
    },
    computed: {
        ...mapGetters([
          'searchCriteria',
          'externalURLs'
        ])
    },
    mounted () {

        this.initEvents();
    },
    methods: {
        ...mapActions([
            'setEditSearchResultsFlag',
            'setSearchMeta',
            'setPublicationResults',
            'setSearchFieldErrors'
        ]),
        initEvents () {
            var inputControl = document.querySelectorAll("#pub_search_form textarea")[0];
            inputControl.addEventListener('keydown', this.handleInputEvent);
            inputControl.addEventListener('paste', (event) => {
                this.handleInputEvent(event);
				
				gtag('event','Keyboard', {
					'event_category': 'Search Field',
					'event_label': 'Paste - ' + this.title,
					'value': this.title
				});	
            });
        },
        handleInputEvent (event) {
             var $this = this,
                tempDomEle = document.createElement('div'),
                tempChilds,
                refinedText = '';

            setTimeout(function () {
                tempDomEle.innerHTML = copyData($this.$store.state.searchCriteria[$this.id].replace("onerror", ""));
                tempChilds = tempDomEle.childNodes;
                for (let i = 0; i <tempChilds.length; i++) {
                    if (tempChilds[i].nodeType !== 1) {
                        refinedText += (tempChilds[i].textContent);
                    }
                }
                $this.$store.state.searchCriteria[$this.id] = refinedText;

                // if press enter search!
                if (event.keyCode === 13) {
                    $this.search();
                }
            }, 1);
        },
        formHasErrors () {
            for (let field in this.searchFieldErrors) {
                if (this.searchFieldErrors[field].preventSearch) {
                    return true;
                }
            }

            return false;
        },
        search () {
            let thisID = this.id;

            if (this.searchCriteria['smart_search_text']) {
                this.searchCriteria['smart_search_text'] = '';
            }
            if (this.searchCriteria['matchmaker_text']) {
                this.searchCriteria['matchmaker_text'] = '';
            }
            if (this.searchCriteria['publications_field'] === 'pmc_id') {
                this.$store.state.searchCriteria[thisID] = this.$store.state.searchCriteria[thisID].replace(/PMC/ig,'');
            }

            if ((thisID && this.$store.state.searchCriteria[thisID] && this.$store.state.searchCriteria[thisID].length === 0) || !thisID || !this.$store.state.searchCriteria[thisID]) {
                if (!this.$store.state.searchFieldErrors[thisID]) {
                   this.$store.state.searchFieldErrors[thisID] = '';
                }
                this.setSearchFieldErrors({
                    field: thisID,
                    message: 'Please enter PMID or PMC ID to search',
                    preventSearch: true
                });
            } else if (thisID && this.$store.state.searchCriteria[thisID] && this.$store.state.searchCriteria[thisID].length < 3) {
                this.setSearchFieldErrors({
                    field: thisID,
                    message: 'Please enter at least 3 characters to search',
                    preventSearch: true
                });
            } else {
                var parsedCriteria = window.parseCriteria(this.searchCriteria, {isEditCriteria: false, isSubmitForm: true}),
                    criteria = window.stringifySearchCriteriaFields(parsedCriteria, {});

                if (!this.formHasErrors()) {
					gtag('event', 'Click', {
						'event_category': this.refpage ? 'Search Results' : 'Home',
						'event_label': 'Publications Search - Submit',
						'value': 'Publications Search - Submit'
					});	
					
                    this.loading = true;

                    this.setEditSearchResultsFlag(false);
                    this.setSearchType('Advanced');

                    this.clearSearchResults({resetFacets: true});

                    this.getPublicationSearchResults(false, this.handleSearchResults);
                } else {
                    console.error('form validation error');
                }
            }
        },
        handleSearchResults (data) {
            this.loading = false;

            if (!data || (data && data.status && data.status !== 200)) {
                if (data && data.status === 204) {
                    this.serviceErrorMessage = 'No publications found.';
                    this.serviceError = 'Please try again with different PMIDs or PMC IDs';
                } else if (data && data.status === 400) {
                    this.serviceErrorMessage = 'Too many PMIDs or PMCIDs.';
                    this.serviceError = 'Please try again with up to 1000 PMIDs or PMC IDs';
                }
                else {
                    this.serviceErrorMessage = 'Unknown error.';
                    this.serviceError = this.externalURLs.reporter_project_search_error_message;
                }

                return false;
            } else {
                if (data && data.meta && data.results) {
                    var url = '/publications/';
                    this.setSearchMeta(data.meta);
                    this.setPublicationResults(data);

                    url += data.meta.search_id + '';

                    if (this.$parent.sortField) {
                        url += '&sort_field=' + this.$parent.sortField + '&sort_order=' + this.$parent.sortOrder;
                    }
                    this.$router.push(url);
                }
            }
        },
        reset () {
            this.serviceError = null;
            this.$refs.publicationsText.$refs.field.reset();
            this.$refs.publicationsText.$refs.field.$refs.input.focus();

			gtag('event', 'Click', {
				'event_category': 'Publications Search Home',
				'event_label': 'Publications Search - Reset',
				'value': 'Publications Search - Reset'
			});	
        },
        cancel () {
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import '@/scss/variables.scss';
@import 'node_modules/bootstrap/scss/_mixins';

.loader {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 4em;
    height: 4em;
    top: -2px;
    left: 0;
    margin-left: 0.5em;
    border-width: 2px;
    border-top-color: rgba(255, 255, 255, 0.25);
    border-right-color: rgba(255, 255, 255, 0.25);
    border-bottom-color: rgba(255, 255, 255, 0.25);
    border-left-color: rgba(255, 255, 255, 1);
}

.btn-primary {
    @include button-variant($pink-dark, $pink-dark);
}
</style>
