
<template>
    <!-- Embeded survey -->
    <div class="survey-container w-40 pt-1 " >
        <div id="embed-survey">
        </div>
    </div>
</template>

<script>
export default {
    name: 'qualtricsModule'
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
    .survey-container:has(#embed-survey:not(:empty)) {
        margin-top: 35px;
    }

    .survey-container #embed-survey:not(:empty) {
        position: fixed;
        bottom: 0;
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        right: 0;
        z-index: 1001;
        border-left: 1px solid $blue-charts;
        background: $bg-color;
        min-width: 325px;
        padding: 0 0 2px 10px;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: $blue-charts;
        }
        button {
            border-radius: 15px !important;
            &:focus, &:active, &:hover {
                outline: none;
            }
        }
    }
</style>